import { DropdownOption } from "@/components/fields/DropdownField";

export const convertCamelCaseToReadable = (text: string) => String(text).replace(/([a-z])([A-Z])/g, "$1 $2");

export const mapEnumToDropdown = (enumType: any) => {
  const res: DropdownOption[] = [];

  Object.entries(enumType).forEach(([key, value]) => {
    res.push({
      key,
      // split camel case with space
      text: String(value).replace(/([a-z])([A-Z])/g, "$1 $2"),
    });
  });
  return res;
};
export const mapEnumToDropdownReactGrid = (enumType: any) => {
  const res: {
    value: string;
    label: string;
  }[] = [];

  Object.entries(enumType).forEach(([key, value]) => {
    res.push({
      value: key,
      // split camel case with space
      label: String(value).replace(/([a-z])([A-Z])/g, "$1 $2"),
    });
  });
  return res;
};
export const mapArrayToDropdownReactGrid = (array: string[]) => {
  const res: {
    value: string;
    label: string;
  }[] = [];

  array.forEach((key) => {
    res.push({
      value: key,
      label: String(key),
    });
  });
  return res;
};

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
export const snakeToCamelCase = (str: string): string =>
  str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");

export const formatTestTitle = (title: string) => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9 ]/g, "")
    .replace(/\s+/g, "-");
};

export const formatToScientific = (val: number | string) => {
  if (isNaN(Number(val)) || !String(val).trim() || String(val) === "0") return String(val);
  const isExponent = (Number(val) > 9999 || Number(val) < 0.01) && val !== 0;

  let fixedTotal = String(parseFloat(Number(val).toFixed(3)));
  let decimalBehindNumber = fixedTotal.replace(",", ".");
  let numberBehindDecimal = decimalBehindNumber.split(".")[1];

  if (fixedTotal.length > 6 && numberBehindDecimal) {
    const length = numberBehindDecimal.length - 1;
    fixedTotal = String(parseFloat(Number(val).toFixed(length)));
  }
  const formatted = val === 0 ? String(val) : fixedTotal;

  return isExponent ? `${Number(val).toExponential(2)}` : formatted;
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getRandomColor = () => {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const isDevelopment =
  window.location.href.includes("dev") || window.location.href.includes("uat") || window.location.href.includes("localhost");
