import { ModuleId } from "@/model";
import { TableHeader } from "@/models/Generic";

const loadingProgress = [
  "Calculating, please wait...",
  "Processing your request, this may take a moment...",
  "Hold tight, crunching the numbers...",
  "We're working on it! Your results will be ready shortly...",
  "Performing calculations, just a moment, please...",
  "Sit back and relax while we do the math...",
  "Thanks for your patience. We're processing your data...",
  "Calculating results. This won't take long...",
];

const serverError =
  "We're sorry for the inconvenience, something went wrong on our end and we are currently working to fix this issue. Please try again later.";

const forecast = "Forecast";
const dataTable = "Data table";
const analysis = "Analysis";
const inputGrid = "Input Grid";
const modelDataTable = "Model data table";

const gas = {
  gasRates: "Gas Rate (model)",
  gasRateCleaned: "Gas Rate (clean data)",
  pressure: "Model Flowing Pressure",
  pressureMeasured: "Data Flowing Pressure",
  reservoirPressure: "Reservoir Pressure (model)",
  casingPressure: "Casing Pressure (clean data)",
  tubingPressure: "Tubing Pressure (clean data)",
  gasProduces: "Gas produced (clean data)",
  gasCumulative: "Gas produced (model)",

  gasRatesYAxis: "Gas Rate (MMscf/D)",
  gasRatesYAxisNoDate: "Gas Rate (MMscf)",
  pressureYAxis: "Pressure (psia)",
};

const tahk = {
  total: "Total",
  title: "Tahk CSG Forecast",
  reservoirInput: "Reservoir input",
  numOfLayer: "Number of Layers",
  summary: "Summary",
  geometry: "Geometry",
  layer: "Layer",

  parameter: "Parameter",
  value: "Value",
  units: "Units",

  initialGasZ: "Initial gas Z",
  initialGasViscosity: "Initial gas viscosity",
  initialGasFormationVolumeFactor: "Initial gas formation volume factor",
  initialGasCompressibility: "Initial gas compressibility",
  specificGravity: "Specific gravity",
  initialFormationCompressibility: "Initial formation compressibility",
  formationDensity: "Formation density",
  desorptionPressure: "Desorption pressure",

  initialPressure: "Initial Pressure",
  formationTemperature: "Temperature",
  netPay: "Net Pay",
  porosity: "Porosity",
  formationCompressibility: "Compressibility",
  selectedFormationCompressibilityCorrelation: "Formation ",
  initialWaterSaturation: "Water Saturation",
  reservoirArea: "Area",
  reservoirWidth: "Width",
  reservoirLength: "Length",
  permeabilityXAxis: "Permeability (kx)",
  permeabilityYAxis: "Permeability (ky)",
  permeabilityZAxis: "Permeability (kz)",

  smartFill: "Smart Fill",
  modelComponentsInputs: "Model Components Inputs",
  gasPvt: "Gas PVT",
  nitrogen: "N₂",
  carbonDioxide: "CO₂",
  hydrogenSulphide: "H₂S",
  selectedZCorrelation: "Z factor",
  selectedGasViscosityCorrelation: "Gas Viscosity",

  calculateForecast: "Calculate Forecast",
  pressure: "Pressure",
  compressibilityFactor: "Compressibility Factor",
  viscosity: "Viscosity",
  density: "Density",
  formulationVolumeFactor: "Formation Volume Factor",
  compressibility: "Compressibility",

  waterPvt: "Water PVT",
  salinity: "Salinity",
  selectedWaterCompressibilityCorrelation: "Water Compressibility",
  selectedWaterViscosityCorrelation: "Water Viscosity",

  relativePermeability: "Relative Permeability",
  selectedRelativePermeabilityCorrelation: "Relative Permeability",
  krgMax: "K",
  connateWaterSaturation: "S",
  residualGasSaturation: "S",
  krwMax: "K",
  ng: "n",
  nw: "n",
  lamb: "lambda",
  n: "n",

  gasRelativePermeability: "Krg (mD)",
  waterRelativePermeability: "Krw (mD)",
  waterSaturation: "Sw (dim)",

  verticalWellInput: "Vertical Well Inputs",
  horizontalWellInputs: "Horizontal Well Inputs",

  wellInputs: "Well Inputs",
  wellboreRadius: "Wellbore Radius",
  layerDepth: "Layer Depth",
  selectedWellType: "Well Type",

  skin: "Skin",
  selectedSkinModel: "Skin Model",
  fractureHalfLength: "Fracture Half Length",
  fractureConductivity: "Fracture Conductivity",
  horizontalLength: "Horizontal Length",
  wellPositionXAxis: "Well Center Position (x)",
  wellPositionYAxis: "Well Center Position (y)",
  wellPositionZAxis: "Well Center Position (z)",
  permeability: "Permeability",

  powerLawSection: "Skin-Permeability Model: Power Law",

  wellboreModel: "Wellbore Model",
  flowParametersInputs: "Flow Parameters Input",
  days: "Days",
  flowingPressure: "Flowing Pressure (psia)",
  rate: "Water Contract rate (stb/d)",

  contractRate: "Contract Rate",
  numberOfDays: "Number of days",
  flowPressureType: "Flow Pressure Type",

  pumpDepth: "Pump Depth",
  minPumpHead: "Min Pump Head",
  pumpDownRate: "Pump Down Rate",
  BottomHolePressure: "Bottomhole Pressure",
  TubingHeadPressure: "Tubing Head Pressure",
  langmuirIsotherm: "Langmuir Isotherm",
  selectInputs: "Select Inputs",
  underSaturation: "Gas Content Saturation",
  initialGasContent: "GCI",
  langmuirPressure: "P",
  ogip: "OGIP",
  absorbedGas: "Absorbed Gas",
  freeGas: "Free Gas",
  langmuirVolume: "V",

  geomechanic: "Geomechanic",
  selectedGeomechanicModel: "Geomechanics correlation",
  poissonsRatio: "Poisson's Ratio",
  grainCompressibility: "Grain Compressibility",
  langmuirVolumeStrain: "Langmuir Vol. Strain",
  youngModule: "Young's Module",
  shrinkageExponent: "Shrinkage Exponent",
  shrinkageFactor: "Shrinkage Factor",
  maximumProductivityMultiplier: "Max. Productivity Multiplier",
  permeabilityModulus: "Permeability Modulus",

  rockDensity: "Formation Density",
  gasContent: "Gas Content",
  desorptionCompressibility: "Compressibility",

  initialLiquidLevel: "Initial Liquid Level",
  // DATA VIEW

  options: "Options",
  atmosphericCorrection: "Atmospheric Correction",
  smoothingType: "Smoothing Type",
  movingAvgWindow: "Moving average window",
  smoothingPoints: "Smoothing Points",
  numberOfPoints: "Number of Points",

  gasRate: "Gas Rate",
  waterRate: "Water Rate",
  dates: "Dates",
  gasProduction: "Gas Production",
  waterProduction: "Water Production",
  casingPressure: "Casing Pressure",
  tubingPressure: "Tubing Pressure",
  bottomholePressure: "Bottomhole Pressure",

  // forecast:
  forecastFlowSchedule: "Forecast Flow Schedule",
  rates: "Rates",
  date: "Date",
  cummulativeProduction: "Cummulative Production",
  model: "Model",
  data: "Data",

  cumulativeGasProduction: "Cum. Gas Production",
  cumulativeWaterProduction: "Cum. Water Production",
  flowingPressureChartLegend: "Flowing Pressure",

  historyMatchOption: "History Match Options",
  boundary: "Boundary",
  lower: "Lower",
  upper: "Upper",

  historyMatchBasedOn: "History Match Based on:",
  gasData: "Gas Data",
  waterData: "Water Data",
  optimizationOptions: "User default optimization settings",
  defaultOptimizationOptions: "Default Optimization Options",
  maxIterationNumber: "Maximum Iteration Number",
  populationSize: "Population Size",
  historyMatch: "History Match",

  tableOfFinalParameter: "Table of Final Parameters",
  parameters: "Parameters",
  forecastFlowingSchedule: "Please input flowing schedule table to calculate",
};

const tahkDataTable: TableHeader = {
  dates: {
    text: "Date",
    notation: "-",
  },
  reservoirPressure: {
    text: "P",
    sub: "r",
    link: "",
    notation: "(psia)",
  },
  flowingPressure: {
    text: "P",
    sub: "wf",
    link: "",
    notation: "(psia)",
  },
  pseudoReservoirPressure: {
    text: "Ψ",
    sub: "r",
    link: "",
    notation: "(psia²/cP)",
  },
  pseudoFlowingPressure: {
    text: "Ψ",
    sub: "wf",
    link: "",
    notation: "(psia²/cP)",
  },
  gasRate: {
    text: "q",
    sub: "g",
    link: "",
    notation: "(MMscf/d)",
  },
  cumulativeGasProduction: {
    text: "Q",
    sub: "g",
    link: "",
    notation: "(MMscf)",
  },
  waterRate: {
    text: "q",
    sub: "w",
    link: "",
    notation: "(stb/d)",
  },
  cumulativeWaterProduction: {
    text: "Q",
    sub: "w",
    link: "",
    notation: "(stb)",
  },

  gasFormationVolumeFactor: {
    text: "B",
    sub: "g",
    link: "",
    notation: "-",
  },
  waterFormationVolumeFactor: {
    text: "B",
    sub: "w",
    link: "",
    notation: "-",
  },
  gasViscosity: {
    text: "μ",
    sub: "g",
    link: "",
    notation: "(cP)",
  },
  waterViscosity: {
    text: "μ",
    sub: "w",
    link: "",
    notation: "(cP)",
  },
  waterSaturation: {
    text: "S",
    sub: "w",
    link: "",
    notation: "(%)",
  },
  gasRelativePermeability: {
    text: "K",
    sub: "rg",
    link: "",
    notation: "-",
  },
  waterRelativePermeability: {
    text: "K",
    sub: "rw",
    link: "",
    notation: "-",
  },
  productivityIndexMultiplier: {
    text: "Plm",
    sub: "",
    link: "",
    notation: "-",
  },
  permeabilityRatio: {
    text: "k/k",
    sub: "initial",
    link: "",
    notation: "-",
  },
  zFactor: {
    text: "Z",
    notation: "-",
  },
};

const tahkTotalDataTable: TableHeader = {
  dates: {
    text: "Date",
    notation: "-",
  },
  gasRate: {
    text: "q",
    sub: "g",
    link: "",
    notation: "(MMscf/d)",
  },
  cumulativeGasProduction: {
    text: "Q",
    sub: "g",
    link: "",
    notation: "(MMscf)",
  },
  waterRate: {
    text: "q",
    sub: "w",
    link: "",
    notation: "(stb/d)",
  },
  cumulativeWaterProduction: {
    text: "Q",
    sub: "w",
    link: "",
    notation: "(stb)",
  },
};

const tahkParameterTable: TableHeader = {
  initialPressure: {
    text: "Initial Pressure",
    notation: "(psia)",
    width: 110,
  },
  porosity: {
    text: "Porosity",
    notation: "(%)",
    width: 80,
  },
  langmuirPressure: {
    text: "P",
    notation: "(psia)",
    width: 120,
    sub: "L",
  },
  permeabilityXAxis: { text: "k", sub: "x", notation: "(mD)", width: 60 },
  permeabilityYAxis: { text: "k", sub: "y", notation: "(mD)", width: 60 },
  skin: { text: "Skin", width: 60 },
  ng: { text: "n", sub: "g", suffix: "(Corey)", width: 80 },
  nw: { text: "n", sub: "w", suffix: "(Corey)", width: 80 },
  formationCompressibility: { text: "cf", notation: "(1/psia)", width: 80 },

  langmuirVolume: { text: "Langmuir Volume", notation: "(scf/ton)", width: 130 },
  desorptionPressure: { text: "Desorption Pressure", notation: "(psia)", width: 140 },
  area: { text: "Area", notation: "(Acres)", width: 80 },

  undersaturated: { text: "Undersaturation", notation: "(%)", width: 100 },
  ogip: { text: "OGIP", notation: "(MMscf)", width: 80 },
  initialGasContent: { text: "GC", notation: "(scf/ton)", width: 120, sub: "i" },
  shrinkageFactor: { text: "Shrinkage Factor", width: 120 },
};

const tableUnits: { [key: string]: string } = {
  layer: "#",
  measure: "#",
  initialPressure: "(psia)",
  formationTemperature: "(F)",
  netPay: "(ft)",
  porosity: "(%)",
  formationCompressibility: "(1/psi)",
  selectedFormationCompressibilityCorrelation: "",
  initialWaterSaturation: "(%)",
  reservoirArea: "(acres)",
  reservoirWidth: "(ft)",
  reservoirLength: "(ft)",

  permeabilityXAxis: "(mD)",
  permeabilityYAxis: "(mD)",
  permeabilityZAxis: "(mD)",

  initialGasZ: "dim",
  initialGasViscosity: "cP",
  initialGasFormationVolumeFactor: "ft <sup>3</sup>/scf",
  initialGasCompressibility: "1/psi",
  specificGravity: "(dim)",
  initialFormationCompressibility: "1/psi",
  formationDensity: "(g/cc)",
  desorptionPressure: "(psia)",

  nitrogen: "(%)",
  carbonDioxide: "(%)",
  hydrogenSulphide: "(%)",
  selectedZCorrelation: "Correlation",
  selectedGasViscosityCorrelation: "Correlation",

  pressure: "(psia)",
  compressibilityFactor: "(Dim)",
  viscosity: "(cP)",
  density: "(lbm/ft³)",
  formulationVolumeFactor: "(ft³/scf)",
  compressibility: "(psi-1)",

  salinity: "(ppm)",
  selectedWaterCompressibilityCorrelation: "Correlation",
  selectedWaterViscosityCorrelation: "Correlation",

  selectedRelativePermeabilityCorrelation: "Correlation",
  krgMax: "(dim)",
  connateWaterSaturation: "(%)",
  residualGasSaturation: "(%)",
  krwMax: "(dim)",
  ng: "(dim)",
  nw: "(dim)",
  lamb: "(dim)",
  n: "(dim)",

  wellboreRadius: "(ft)",
  layerDepth: "(ft)",
  selectedWellType: "",

  skin: "(dim)",
  skinValue: "(dim)",
  selectedSkinModel: "",
  fractureHalfLength: "(ft)",
  fractureConductivity: "(mD.ft)",
  horizontalLength: "(ft)",
  wellPositionXaxis: "(ft)",
  wellPositionYaxis: "(ft)",
  wellPositionZaxis: "(ft)",

  permeability: "mD",
  langmuirPressure: "(psia)",
  langmuirVolume: "(sfc/ton)",
  rockDensity: "(g/cc)",

  underSaturation: "(%)",
  initialGasContent: "(sfc/ton)",
  ogip: "(MMscf)",

  selectedGeomechanicModel: "",
  poissonsRatio: "(dim)",
  grainCompressibility: "(1/psia)",
  langmuirVolumeStrain: "(dim)",
  youngModule: "(dim)",
  shrinkageExponent: "(dim)",
  shrinkageFactor: "(dim)",
  maximumProductivityMultiplier: "(dim)",
  permeabilityModulus: "(dim)",
  gasContent: "(sfc/ton)",
  desorptionCompressibility: "(psi⁻¹)",

  gasRate: "(MMscf/d)",
  waterRate: "(stb/d)",

  dates: "",
  casingPressure: "(psia)",
  tubingPressure: "(psia)",
  bottomholePressure: "(psia)",
  gasProduction: "(MMscf)",
  waterProduction: "(stb)",
  contractRate: "(stb/d)",

  absorbedGas: "(MMscf)",
  freeGas: "(MMscf)",
};

const langmuirIsothermVolumetricHeaderUnitMapping = {
  layer: {
    name: tahk.layer,
    unit: tableUnits.layer,
    sub: "",
    link: "",
  },
  desorptionPressure: {
    name: tahk.desorptionPressure,
    unit: tableUnits.desorptionPressure,
    sub: "",
    link: "",
  },
  langmuirPressure: {
    name: "P",
    unit: tableUnits.langmuirPressure,
    sub: "L",
    link: "",
  },
  langmuirVolume: {
    name: "V",
    unit: tableUnits.langmuirVolume,
    sub: "L",
    link: "",
  },
  rockDensity: {
    name: tahk.formationDensity,
    unit: tableUnits.rockDensity,
    sub: "",
    link: "",
  },
};

const langmuirIsothermOGIPHeaderUnitMapping = {
  layer: {
    name: tahk.layer,
    unit: tableUnits.layer,
    sub: "",
    link: "",
  },
  underSaturation: {
    name: tahk.underSaturation,
    unit: tableUnits.underSaturation,
    sub: "",
    link: "",
  },
  initialGasContent: {
    name: tahk.initialGasContent,
    unit: tableUnits.initialGasContent,
    sub: "",
    link: "",
  },
  langmuirPressure: {
    name: "P",
    unit: tableUnits.langmuirPressure,
    sub: "L",
    link: "",
  },
  ogip: {
    name: tahk.ogip,
    unit: tableUnits.ogip,
    sub: "",
    link: "",
  },
};

const gasPvtHeaderUnitMapping = {
  pressure: { name: "Pressure", unit: "(psia)", sub: "" },
  compressibility_factor: { name: "Z", unit: "(Dim)", sub: "" },
  viscosity: { name: "μ", unit: "cP", sub: "g" },
  density: { name: "p", unit: "lbm/ft³", sub: "g" },
  form_vol_factor: { name: "B", unit: "(ft³/scf)", sub: "g" },
  compressibility: { name: "c", unit: "(psi⁻¹)", sub: "g" },
};

const nav: { [key: string]: string } = {
  importWells: "Import Wells",
  importProjects: "Import Projects",
  removeCurrentWell: "Remove Current Well",
  previewWell: "Preview Well",
  import: "Import",
  wells: "Wells",
  projects: "Projects",
  wellNotFound: "Well not found",
  convertSuccess: "Convert Success!",
  tools: "Tools",
};

const wellImport = {
  choose: "Select CSV file",
  submit: "Submit",
  warning: "Upload file will replace all current files",
  failedTitle: "Failed Files",
  successFiles: "Success Files",
  fileExists: "File Exists",
  overrideFile: "Override Files",
  ok: "OK",
  minimumColumnsError: "Require 1 columns minimum, please add more columns.",
  selectFile: "Select file",
};

export const waterPvtHeaderUnitMapping = {
  pressure: { name: "Pressure", unit: "(psia)", sub: "" },
  viscosity: { name: "μ", unit: "cP", sub: "w" },
  density: { name: `p`, unit: "lbm/ft³", sub: "w" },
  form_vol_factor: { name: "B", unit: "(rb/stb)", sub: "w" },
  compressibility: { name: "c", unit: "(psi⁻¹)", sub: "w" },
};

const dataView = {
  title: "Data View",
  chart: "Chart",
  rawData: "Raw Data",
  cleanData: "Clean Data",
  cumulativeChart: "Cumulative Charts",
  combinedRates: "Combined Rates",
  date: "Date",
  clean: "(clean)",
  raw: "(raw)",

  chartOptions: "Chart Options",
  dataOptions: "Data Options",
  axis: "Axis",
  data: "Data",
  dataChart: "Data Chart",
  dataTable: "Data Table",

  tags: "Tags",
  key: "Key",
  value: "Value",
};

const sessionInactiveModal = {
  title: "Session Timeout",
  stayButton: "Stay Logged in",
  logoutButton: "Logout now",
  minutes: "minutes",
  seconds: "seconds",
  description: (countdown: string) => `For your security, we will log you out in ${countdown} due to inactivity.`,
};

const spadDecline: {
  [key: string]: string;
} = {
  declineParameters: "Decline Parameters",
  autoUpdateSmartFit: "Auto update Smart-Fit",
  autoUpdateClusterStartDay: "Auto update cluster start day",
  clusterStartDay: "Cluster start day",
  analysisStartDay: "Cluster start day",

  opsForecast: "Operational Forecast",
  operationalForecast: "Operational Forecast",
  profileForecast: "Profile Forecast",
  low: "Low",
  mid: "Mid",
  high: "High",
  economicCutoff: "Economic Cutoff",
  applyUpdates: "Apply Updates",
  exportMonthly: "Export Monthly",
  forecastForRollup: "Forecast For Rollup",
  selectedForecastRollup: "Forecast For Rollup",
  economicCutoffoilNotation: "STB/D",
  economicCutoffgasNotation: "MMscf/D",
  export: "Export",
  daily: "Daily",
  monthly: "Monthly",
  yearly: "Yearly",
  analysisSummary: "Analysis Summary",
};

const spadDeclineParams: {
  [key: string]: {
    label: string;
    sub?: string;
    notation?: string;
  };
} = {
  head: { label: "" },
  initialRate: { label: "q", sub: "i", notation: "MMscf/D" },
  arpsExponent: {
    label: "b",
  },
  initialDeclineRate: {
    label: "d",
    sub: "i",
    notation: "1/D",
  },
  initialDay: {
    label: "t",
    sub: "i",
    notation: "D",
  },
};

const errorMessage = {
  groupDroppable: "Please drop well into desired group / subgroup",
  droppableDifferentProjectWellMove: "Move well can only be between group in the same project",
  droppableWellMoveHaveSubgroup: "Move well can only be in lowest level subgroup",
  wellNotFound: "Well not found, please delete well.",
  groupHaveDataset: "Please move to project with no dataset",
  moveProjectNotAllowed: "Moving project is not allowed",

  // tahk validation
  initialPressureGreaterThan: "Pi < Casing or Tubing Pressures; Negative Rates May Result",
  droppableHaveDataset: "Group can only be move to project/ group with no well",
  historyMatchSelection: "Please enable at least one tuning parameter to run history match ",
  // koldun csg
  forecastEndDate: "Forecast end date is required for Koldun CSG, please enter forecast end date in project settings.",
  koldunInputInvalid: "Please check error in the input page and make sure the value is correct before calculating.",

  // use for generic error message which occur because of 422
  validationGeneric: "Validation error, please re-check input value! ",

  cancelTask: "Task cancelled",
};

const koldunCsgForecastSimulationTable: TableHeader = {
  dates: {
    text: "Date",
    notation: "-",
  },
  gasRate: {
    text: "q",
    sub: "g",
    link: "",
    notation: "(MMscf/d)",
  },
  waterRate: {
    text: "q",
    sub: "w",
    link: "",
    notation: "(stb/d)",
  },
  cumulativeGasProduction: {
    text: "Q",
    sub: "g",
    link: "",
    notation: "(MMscf)",
  },

  cumulativeWaterProduction: {
    text: "Q",
    sub: "w",
    link: "",
    notation: "(stb)",
  },
};

const rulon = {
  data_set_id: "Well",
  module: "Module",
  progress: "Progress",
  input_status: "Input Validation",
  output_status: "Analysis Completion",
  validateButton: "Validate Selected Modules Input",
  groupRun: "Group Run",
  groupEdit: "Group Edit",

  // rollup
  validation: "Validation",
  rollup: "Rollup",
  saveAndValidate: "Save Selection and Validate",
  summary: "Summary",
  forecast: "Forecast",
  initial_production: "Forecast Start Cumulative",
  eur: "EUR",
  low: "Low",
  mid: "Mid",
  high: "High",
  calculate: "Calculate",
  date: "Date",
  rate: "Rate",
  cumulativeProduction: "Cumulative Production",
  export: "Export",
  koldun_mcsim_csg: "Koldun CSG",
  tahk_forecast_csg: "Tahk CSG ",

  successGroupRun: "Group run successfully executed",
  successGroupEdit: "Group Edit successfully executed",
};

const rulonTableHeader = {
  data_set_id: rulon.data_set_id,
  module: rulon.module,
  input_status: rulon.input_status,
  output_calculation_time: rulon.output_status,
};

const koldunCsg: {
  [key: string]: string;
} = {
  forecastEventRow: "Forecast event",
  skinPowerLawTableError: "Minimum values must be greater than 0",
  simulation: "Simulation",
  seleectSimulation: "Select Simulation",
  title: "Koldun: CSG Monte-Carlo ",
  modelComponentInput: "Model Component Inputs",
  monteCarloInputs: "Montecarlo Inputs",
  numberOfSimulation: "Number of Simulation",
  specifyInput: "Specify Input",
  measure: "Measure",
  numberOfCoalMeasures: "Number of Coal Measures",
  numberOfLayers: "Number of Layers",
  measureDepth: "Measure Depth",
  area: "Area",
  temperature: "Temperature",
  formationCompressibility: "Formation Compressibility",
  reservoirInput: "Reservoir input",
  gasPvt: "Gas Pvt",
  waterPvt: "Water Pvt",
  relativePermeability: "Relative Permeability",
  wellInput: "Well Input",
  geomechanics: "Geomechanics",
  fixed: "Fixed",
  low: "Low",
  high: "High",
  mode: "Mode",
  mean: "Mean",
  std: "Std",
  lorenz: "Lorenz",
  netCoalThickness: "Net Coal Thickness",
  lorenzFactor: "Lorenz Factor",
  application: "Application",
  permeability_horizontal_distribution: "Permeability (Kh) distribution",
  net_pay_distribution: "Net Pay Distribution",
  initial_water_saturation_distribution: "Sw Distribution",
  initial_pressure_distribution: "Initial Pressure Distribution",
  porosity_distribution: "Porosity Distribution",
  langmuir_pressure_distribution: "Langmuir Pressure Distribution",

  permeability_vertical: "Permeability Vertical (Kv) ",
  // volumetric
  langmuir_volume_distribution: "Langmuir Volume Distribution",
  desorption_pressure_distribution: "Desorption Pressure Distribution",
  rock_density_distribution: "Coal Density Distribution",

  // ogip
  ogip_distribution: "OGIP Distribution",
  gas_content_saturation_distribution: "Gas Content Saturation Distribution",
  initial_gas_content_distribution: "GCI Distribution",

  // dependency matrix table
  permeability_horizontal: "Permeability (Kh)",
  net_pay: "Net Pay ",
  initial_water_saturation: "Sw ",
  porosity: "Porosity ",
  initial_pressure: "Initial Pressure",
  langmuir_pressure: "Langmuir Pressure",
  langmuir_volume: "Langmuir Volume",
  desorption_pressure: "Desorption Pressure",
  rock_density: "Coal Density",
  ogip: "Ogip",
  gas_content_saturation: "Gas Content Saturation",
  initial_gas_content: "Gci",

  // forecast option
  forecastOption: "Forecast Options",

  contractRateMode: "Contract Rate",
  forecastEndDate: "Forecast End Date",
  selectedFlowPressureType: "Flowing Pressure",
  extraPressureDrop: "Extra Pressure Drop",
  pumpDepth: "Pump Depth",
  minimumPumpHead: "Minimum Pump Head",
  initialLiquidLevel: "Initial Liquid Level",
  liquidDrawDownRate: "Liquid DrawDown Rate",
  forecastFlowSchedule: "Forecast Flow Schedule",

  selectSimulation: "Select Simulation",
  date: "Date",
  flowingPressure: "Flowing Pressure (psia)",
  contractRate: "Contract Rate",
  calculateForecast: "Calculate Forecast",

  // chart section
  rates: "Rates",
  cumulativeProduction: "Cumulative Production",
  dataTable: "Data Table",

  wellboreModel: "Wellbore Model",

  // contract rate enum
  WaterContractRate: "Water Contract Rate (stb/d)",
  GasContractRate: "Gas Contract Rate (MMscf/d)",
  // flowing pressure enum
  TubingHeadPressure: "Tubing Pressure (psia)",
  BottomHolePressure: "BottomHole Pressure (psia)",
  CasingPressure: "Casing Pressure (psia)",
  production_start_date: "Forecast Start Date",

  low_results: "Low",
  mid_results: "Mid",
  high_results: "High",
  mean_results: "Mean",

  cumulativeGas: "Cumulative Gas (MMscf)",
  cumulativeWater: "Cumulative Water (stb)",
};

const koldunUnits: { [key: string]: string } = {
  measure: "#",
  number_of_layers: "#",
  layer_depth: "(ft)",
  reservoir_area: "(acres)",
  formation_temperature: "(F)",
  selected_formation_compressibility_correlation: "Correlation",
  formation_compressibility: "(1/psia)",
  permeability_vertical: "(mD)",
};

const autoRta = {
  calculateAnalysis: "Calculate Analysis",
  averagePressure: "Average Reservoir Pressure",
  pressure: "Pressure",
  oilRate: "Oil Rate",
  gasCumulative: "Gas cumulative",
  gasRate: "Gas Rate",
  recombinedGasCumulative: "Recombined Gas cumulative",
  recombinedGasRate: "Recombined Gas Rate",
  oilCumulative: "Oil Cumulative",
  calculateForecastToGetResult: "Calculate forecast to get result",
  gasTitle: "GAZ: Gas Auto RTA",
  oilTitle: "Maslo: Oil Auto RTA",
  modelled: "Model",
  measured: "Data",
  measuredDataTable: "Measured data table",
  analysisInputError: "Please check error input on analysis or input page to proceed.",
};

const genericChart = {
  date: "Date",
};

const userPreference = {
  unitPreference: "Unit Preference",
  fluidType: "Fluid Type",
  save: "Save",
  export: "export",
  import: "import",
  userPreference: "User Preference",
  language: "Language",
  mode: "Mode",
};

const settings = {
  about: "About",
  preference: "Preferences",
  releaseNotes: "Release Notes",
  terms: "Terms of Service",
};

const genericButton = {
  cancel: "Cancel",
  ok: "Ok",
  applyUpdates: "Apply Updates",
};

const machineLearning = {
  title: "Machine Learning",
};

const moduleName = {
  [ModuleId.SPAD_DECLINE_GAS]: "SPAD: Gas Decline",
  [ModuleId.SPAD_DECLINE_OIL]: "SPAD: Oil Decline",
};
const dictionary = {
  machineLearning,
  settings,
  userPreference,
  loadingProgress,
  rulonTableHeader,
  serverError,
  forecast,
  dataTable,
  gas,
  analysis,
  inputGrid,
  dataView,
  modelDataTable,
  tahk,
  tableUnits,
  gasPvtHeaderUnitMapping,
  nav,
  wellImport,
  waterPvtHeaderUnitMapping,
  langmuirIsothermVolumetricHeaderUnitMapping,
  langmuirIsothermOGIPHeaderUnitMapping,
  tahkDataTable,
  tahkParameterTable,
  sessionInactiveModal,
  spadDecline,
  spadDeclineParams,
  errorMessage,
  rulon,
  koldunCsg,
  koldunUnits,
  autoRta,
  koldunCsgForecastSimulationTable,
  genericChart,
  tahkTotalDataTable,
  genericButton,
  moduleName,
};

export default dictionary;
