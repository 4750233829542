import React, { useMemo } from "react";

import InputField from "@/components/fields/InputField";
import FossilyticsChart from "@/components/FossilyticsChart";
import CustomDataTable from "@/components/CustomTable";
import Tabs from "@/components/Tabs";

import "../../ModuleAutoRTA.css";

import useAutoRtaPvtDataTable from "../../hooks/rtaInputGrid/useAutoRtaPvtDataTable";
import useAutoRtaLangmuirIsothermCharts from "../../hooks/rtaInputGrid/useAutoRtaLangmuirIsothermCharts";
import { ModelComponentsParamProps } from "../../hooks/AutoRtaContext";

const LangmuirIsothermContainer = ({ value, calculateValue, isLoading, updateInputGridValue, validationError }: ModelComponentsParamProps) => {
  const { langmuirIsothermGasContent, langmuirIsothermcd } = useAutoRtaLangmuirIsothermCharts({ calculateValue, value });
  const { langmuirIsothermDataTableCols, langmuirIsothermDataTableRows } = useAutoRtaPvtDataTable(calculateValue);

  const tabList = useMemo(() => {
    return [
      {
        label: <span>Gas Content</span>,
        key: "Gas Content",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="langmuir_isotherm_gas_content"
              isLoading={isLoading}
              xAxes={langmuirIsothermGasContent.xAxes}
              yAxes={langmuirIsothermGasContent.yAxes}
              series={langmuirIsothermGasContent.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            c<sub>{"d"}</sub>
          </span>
        ),
        key: "Cd",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="langmuir_isotherm_cg"
              isLoading={false}
              xAxes={langmuirIsothermcd.xAxes}
              yAxes={langmuirIsothermcd.yAxes}
              series={langmuirIsothermcd.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: <span>Data Table</span>,
        key: "Langmuir Data table",
        content: (
          <div className="chartWrapper">
            <CustomDataTable rows={langmuirIsothermDataTableRows} columns={langmuirIsothermDataTableCols} isLoading={isLoading} />
          </div>
        ),
      },
    ];
  }, [
    isLoading,
    langmuirIsothermDataTableCols,
    langmuirIsothermDataTableRows,
    langmuirIsothermGasContent.series,
    langmuirIsothermGasContent.xAxes,
    langmuirIsothermGasContent.yAxes,
    langmuirIsothermcd.series,
    langmuirIsothermcd.xAxes,
    langmuirIsothermcd.yAxes,
  ]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 2.8fr",
      }}
    >
      <div
        style={{
          minWidth: 180,
          maxWidth: 297,
        }}
      >
        <InputField
          label="Desorption Pressure"
          suffix="psia"
          debounceDelay={1000}
          value={value.langmuir_isotherm.desorption_pressure}
          onChange={(v) => updateInputGridValue(v, "desorption_pressure", "langmuir_isotherm")}
          disabled={isLoading}
          errors={validationError}
          keyField={"inputs.langmuir_isotherm.desorption_pressure"}
        />

        <InputField
          label="Vₗ"
          suffix="scf/ton"
          debounceDelay={1000}
          value={value.langmuir_isotherm.langmuir_volume}
          onChange={(v) => updateInputGridValue(v, "langmuir_volume", "langmuir_isotherm")}
          disabled={isLoading}
          errors={validationError}
          keyField={"inputs.langmuir_isotherm.langmuir_volume"}
        />

        <InputField
          label="Pₗ"
          suffix="psia"
          debounceDelay={1000}
          value={value.langmuir_isotherm.langmuir_pressure}
          onChange={(v) => updateInputGridValue(v, "langmuir_pressure", "langmuir_isotherm")}
          disabled={isLoading}
          errors={validationError}
          keyField={"inputs.langmuir_isotherm.langmuir_pressure"}
        />

        <InputField
          label="Rock Density"
          suffix="g/cc"
          debounceDelay={1000}
          value={value.langmuir_isotherm.rock_density}
          onChange={(v) => updateInputGridValue(v, "rock_density", "langmuir_isotherm")}
          disabled={isLoading}
          errors={validationError}
          keyField={"inputs.langmuir_isotherm.rock_density"}
        />
      </div>
      <Tabs tabList={tabList} centered />
    </div>
  );
};

export default LangmuirIsothermContainer;
