import { tableHeaderStyle } from "@/components/CustomTable";
import { FluidType, PvtTableHeaderMap } from "@/models/Generic";
import { DataViewData } from "@/models/gaz/autoRta";
import { Column } from "@silevis/reactgrid";

export const forecastEventColumn = [
  { columnId: "dates", width: 90 },
  { columnId: "flowing_rate", width: 80 },
  { columnId: "flowing_pressure", width: 100 },
  { columnId: "bound_condition_is_rate", width: 120 },
] as Column[];

export const autoRtaForecastEventHeader = {
  rowId: "header",
  height: 50,
  cells: [
    {
      type: "header",
      text: "Date",
      style: tableHeaderStyle,
    },
    {
      type: "header",
      text: "Flowing rate",
      style: tableHeaderStyle,
    },
    {
      type: "header",
      text: "Flowing Pressure",
      style: tableHeaderStyle,
    },
    {
      type: "header",
      text: "Constraint",
      style: tableHeaderStyle,
    },
  ],
};

export const autoRtaForecastEventNotation = (fluidType: FluidType) => ({
  rowId: "notation",
  height: 30,
  cells: [
    {
      type: "header",
      text: "",
      style: tableHeaderStyle,
    },
    {
      type: "header",
      text: fluidType === FluidType.gas ? "MMscf/D" : "STB/D",
      style: tableHeaderStyle,
    },
    {
      type: "header",
      text: "psia",
      style: tableHeaderStyle,
    },
    {
      type: "header",
      text: "",
      style: tableHeaderStyle,
    },
  ],
});

export const forecastGasDataTableHeader = [
  "dates",
  "flowing_pressure",
  "dry_gas_rate",
  "dry_cumulative_gas",
  "recombined_gas_rate",
  "recombined_cumulative_gas",
  "pseudo_time",
  "average_pseudo_pressure",
  "average_pressure",
  "average_viscosity",
  "average_total_compressibility",
];

export const forecastGasDataTableNotation = ["", "psia", "MMscf/D", "MMscf", "MMscf/D", "MMscf", "", "psia", "psia", "cP", "psi⁻¹"];
export const forecastOilDataTableHeader = ["dates", "flowing_pressure", "oil_rate", "cumulative_oil"];
export const forecastOilDataTableNotation = ["", "psia", "bbl/D", "bbl"];

export const fieldGasDataTableHeader = [
  "dates",
  "flowing_pressure",
  "dry_gas_rate",
  "dry_cumulative_gas",
  "recombined_gas_rate",
  "recombined_cumulative_gas",
];
export const fieldGasDataTableNotation = ["", "psia", "MMscf/D", "MMscf", "MMscf/D", "MMscf"];

export const fieldOilDataTableHeader = ["dates", "flowing_pressure", "oil_rate", "cumulative_oil"];
export const fieldOilDataTableNotation = ["", "psia", "bbl/D", "bbl"];

export const oilPvtDataTableColumns = [
  { columnId: "pressure", width: 80 },
  { columnId: "viscosity", width: 80 },
  { columnId: "density", width: 80 },
  { columnId: "form_vol_factor", width: 80 },
  { columnId: "compressibility", width: 100 },
  { columnId: "solution_gas_oil_ratio", width: 80 },
  { columnId: "superficial_tension", width: 80 },
] as Column[];

export const waterPvtHeaderUnitMapping: { [key: string]: PvtTableHeaderMap } = {
  pressure: { name: "Pressure", unit: "(psia)" },
  viscosity: { name: "μ", unit: "cP", sub: "w" },
  density: { name: `p`, unit: "lbm/ft³", sub: "w" },
  form_vol_factor: { name: "B", unit: "(rb/stb)", sub: "w" },
  compressibility: { name: "c", unit: "(psi⁻¹)", sub: "w" },
};

export const normalizedPressureXaxisOptions = [
  { key: "wellbore_radius_normalized_time", text: "tDrw" },
  { key: "area_normalized_time", text: "tDA" },
  { key: "decline_time", text: "tDd" },
];

export const analysisDataColumns = [
  { columnId: "wellbore_radius_normalized_time" },
  { columnId: "area_normalized_time" },
  { columnId: "decline_time" },
  { columnId: "beta_derivative" },
  { columnId: "bourdet_derivative" },
  { columnId: "chow_pressure_group" },
  { columnId: "normalized_pressure" },
  { columnId: "normalized_pressure_integral" },
  { columnId: "normalized_pressure_integral_derivative" },
  { columnId: "decline_rate" },
  { columnId: "decline_rate_integral" },
  { columnId: "decline_rate_integral_derivative" },
  { columnId: "normalized_rate" },
] as Column[];

export const analysisTableHeader: Record<string, string> = {
  wellbore_radius_normalized_time: "tDrw",
  area_normalized_time: "tDA",
  decline_time: "tDd",
  beta_derivative: "pβd",
  bourdet_derivative: "pD'",
  chow_pressure_group: "CPG",
  normalized_pressure: "pD",
  normalized_pressure_integral: "pDi",
  normalized_pressure_integral_derivative: "pDid",
  decline_rate: "qDd",
  decline_rate_integral: "qDdi",
  decline_rate_integral_derivative: "qDdid",
  normalized_rate: "qD",
};

export const dataTableHeaderStyles = {
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  fontWeight: "bold",
  zIndex: 1,
};

export const autoRtaDefaultState = {
  autoRtaState: null,
  setAutoRtaState: () => {},
  isLoading: false,
  setApiError: () => {},
  setValidationError: () => {},
  fluidType: FluidType.gas,
  validationError: [],

  setIsLoading: () => {},
  setProgress: () => {},
  setPollStatus: () => {},
};

export const gasPvtDataTableCols = [
  { columnId: "pressure", width: 80 },
  { columnId: "compressibility_factor", width: 80, justifyContent: "center" },
  { columnId: "viscosity", width: 80, justifyContent: "center" },
  { columnId: "density", width: 80, justifyContent: "center" },
  { columnId: "form_vol_factor", width: 80, justifyContent: "center" },
  { columnId: "compressibility", width: 80, justifyContent: "center" },
] as Column[];

export const gasPvtHeaderUnitMapping: { [key: string]: PvtTableHeaderMap } = {
  pressure: { name: "Pressure", unit: "(psia)" },
  compressibility_factor: { name: "Z", unit: "(Dim)" },
  viscosity: { name: "μ", unit: "cP", sub: "g" },
  density: { name: "p", unit: "lbm/ft³", sub: "g" },
  form_vol_factor: { name: "B", unit: "(ft³/scf)", sub: "g" },
  compressibility: { name: "c", unit: "(psi⁻¹)", sub: "g" },
};

export const waterPvtDataTableCols = [
  { columnId: "pressure", width: 80 },
  { columnId: "compressibility", width: 80, justifyContent: "center" },
  { columnId: "density", width: 80, justifyContent: "center" },
  { columnId: "form_vol_factor", width: 80, justifyContent: "center" },
  { columnId: "viscosity", width: 80, justifyContent: "center" },
] as Column[];

export const oilPvtHeaderUnitMapping: { [key: string]: PvtTableHeaderMap } = {
  pressure: { name: "Pressure", unit: "(psia)" },
  viscosity: { name: "μ", unit: "(cP)", sub: "o" },
  density: { name: "p", unit: "(lbm/ft³)", sub: "o" },
  form_vol_factor: { name: "B", unit: "(rb/stb)", sub: "o" },
  compressibility: { name: "c", unit: "(psi⁻¹)", sub: "o" },
  solution_gas_oil_ratio: { name: "R", unit: "(scf/stb)", sub: "s" },
  superficial_tension: { name: "σ", unit: "(dynes/cm)", sub: "o" },
};

export const langmuirIsothermHeaderUnitMapping: { [key: string]: PvtTableHeaderMap } = {
  pressure: { name: "Pressure", unit: "(psia)" },
  gas_content: { name: "gas content", unit: "(scf/ton)" },
  desorption_compressibility: { name: "c", unit: "(psi⁻¹)", sub: "d" },
};

export const langmuirIsothermDataTableCols = [
  { columnId: "pressure", width: 120 },
  { columnId: "gas_content", width: 120, justifyContent: "center" },
  { columnId: "desorption_compressibility", width: 120, justifyContent: "center" },
] as Column[];

export const headerDataTableUnitStyles = { background: "#fff", display: "flex", justifyContent: "center", zIndex: 1 };

export const dataTableColumns = [
  { key: "parameter", label: "Parameter" },
  { key: "value", label: "Value" },
  { key: "units", label: "Units" },
];

export const cleaningSmoothingTypeOptions = [
  { key: "interp", text: "Interpolation" },
  { key: "movavg", text: "Moving average" },
];

export const dataViewRawDataHeaderMapping: Record<string, { property: keyof DataViewData; name: string; unit: string }> = {
  dates: { property: "dates", name: "Dates", unit: "" },
  gas_rate: { property: "gas_rate", name: "Gas rate", unit: "(MMscf/D)" },
  casing_pressure: { property: "casing_pressure", name: "Casing pressure", unit: "(psia)" },
  tubing_pressure: { property: "tubing_pressure", name: "Tubing Pressure", unit: "(psia)" },
  gas_production: { property: "gas_production", name: "Gas Produced", unit: "(MMscf)" },
};
