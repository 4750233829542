import { object, number, string, boolean, z } from "zod";

const forecastEventScheme = object({
  date: string().date().optional(),
  flowing_pressure: number().optional(),
});

export type ForecastEvent = z.infer<typeof forecastEventScheme>;

const autoRtaGasForecastCalculationDataScheme = object({
  dates: string().array(),
  flowing_pressure: number().array(),
  dry_gas_rate: number().array(),
  dry_cumulative_gas: number().array(),
  recombined_gas_rate: number().array(),
  recombined_cumulative_gas: number().array(),
  pseudo_time: number().array(),
  average_pseudo_pressure: number().array(),
  average_pressure: number().array(),
  average_viscosity: number().array(),
  average_total_compressibility: number().array(),
});

const autoRtaOilForecastCalculationDataScheme = object({
  dates: string().array(),
  flowing_pressure: number().array(),
  oil_rate: number().array(),
  cumulative_oil: number().array(),
});

const autoRtaForecastCalculationChartDataScheme = object({
  flowing_pressures: number().array(),
  gas_rates: number().array(),
  cumulative_gas: number().array(),
  dates: string().array(),
  reservoir_pressures: number().array(),
});

export type AutoRtaForecastCalculationChartData = z.infer<typeof autoRtaForecastCalculationChartDataScheme>;

const autoRtaForecastCalculationSummaryCardScheme = object({
  parameter: string(),
  value: number(),
  unit: string(),
});

export const autoRtaForecastScheme = object({
  economic_cutoff: number(),
  forecast_events: forecastEventScheme.array(),
  smart_fill: boolean(),
});

export type AutoRtaForecast = z.infer<typeof autoRtaForecastScheme>;

const autoRtaForecastCalculationScheme = object({
  data: autoRtaForecastCalculationChartDataScheme,
  summary_card: autoRtaForecastCalculationSummaryCardScheme.array(),
});

export type AutoRtaForecastCalculation = z.infer<typeof autoRtaForecastCalculationScheme>;

const autoRtaForecastCalculationSchemeV2 = object({
  data: autoRtaGasForecastCalculationDataScheme.or(autoRtaOilForecastCalculationDataScheme),
  summary_card: autoRtaForecastCalculationSummaryCardScheme.array(),
});

export type AutoRtaForecastCalculationV2 = z.infer<typeof autoRtaForecastCalculationSchemeV2>;

const forecastEventSchemeV2 = object({
  dates: string().date().array(),
  flowing_pressure: number().array(),
  flowing_rate: number().array(),
  bound_condition_is_rate: boolean().array(),
});

export const autoRtaForecastSchemeV2 = object({
  economic_cutoff: number(),
  forecast_events: forecastEventSchemeV2,
  smart_fill: boolean(),
});

export type AutoRtaForecastV2 = z.infer<typeof autoRtaForecastSchemeV2>;

const autoRtaForecastFieldDataOil = object({
  dates: string().date().array(),
  flowing_pressure: number().array(),
  oil_rate: number().array(),
  cumulative_oil: number().array(),
});

const autoRtaForecastFieldDataGas = object({
  dates: string().date().array(),
  flowing_pressure: number().array(),
  dry_gas_rate: number().array(),
  dry_cumulative_gas: number().array(),
  recombined_gas_rate: number().array(),
  recombined_cumulative_gas: number().array(),
});

const autoRtaFieldData = autoRtaForecastFieldDataGas.or(autoRtaForecastFieldDataOil);

export const autoRtaForecastResScheme = object({
  forecast_inputs: autoRtaForecastSchemeV2,
  forecast_result: autoRtaForecastCalculationSchemeV2,
  field_data: autoRtaFieldData,
});

export type AutoRtaFieldData = z.infer<typeof autoRtaFieldData>;

export type AutoRtaForecastRes = z.infer<typeof autoRtaForecastResScheme>;
