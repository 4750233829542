import React, { useCallback, useMemo, useState } from "react";

import { useTheme } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import IconButton from "@mui/material/IconButton";

import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningIcon from "@mui/icons-material/ReportGmailerrorred";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeblurIcon from "@mui/icons-material/Deblur";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import GrainOutlinedIcon from "@mui/icons-material/GrainOutlined";

import styled from "@emotion/styled";
import { DataSet } from "@/model";
import { Menu, MenuItem } from "@mui/material";
import { DialogEnum } from "../types";
import dictionary from "@/constants/dictionary";
import { AFA_WELL_TYPE } from "../constants";

export const DataSetItem = styled.div<{ isDragging?: boolean }>`
  width: 90%;
  opacity: ${(props) => (props.isDragging ? 0.4 : 1)};
  margin-right: 5px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #edebe9;
  }

  .dataset-name {
    padding-left: 10px;
  }

  .MuiRadio-sizeSmall {
    padding: 5px;
  }

  span {
    visibility: hidden;
  }

  span input[type="radio"]:checked + span {
    visibility: visible;
  }

  &:hover span {
    visibility: visible;
  }

  svg {
    width: 14px;
    height: 14px;
  }

  .radio-well {
    margin-right: 12px;
    padding: 0px;

    svg {
      width: 16px;

      height: 16px;
    }
  }
`;

export const DataSetMoreButton = styled.div`
  position: relative;
  display: flex;
  margin-left: auto;

  button {
    padding-right: 5px;
  }
  .more-icon {
    width: 20px;
    height: 20px;
  }
`;

type DataSetRadioItemProps = {
  dataSet: string;
  currDataSet?: DataSet;
  setActiveDialog: (val: DialogEnum | undefined) => void;
  onClick: () => void;
  checked: boolean;
  isDragging?: boolean;
  disableMenu?: boolean;
  [key: string]: any;
  dataTestId?: string;
  onClickPreview: (dataSetId: string, isOutsideList: boolean) => void;
};

type WellItemProps = {
  name: string;
  tags?: string[];
};

export const WellItem = ({ name, tags }: WellItemProps) => {
  const { palette } = useTheme();

  const wellTypes = useMemo(() => {
    if (!tags) return "";
    return tags.reduce((res, tag) => {
      if (tag.includes(AFA_WELL_TYPE)) {
        res.push(tag.split(":")?.[1]);
      }
      return res;
    }, [] as string[]);
  }, [tags]);

  const renderIcon = useCallback(() => {
    // combination well type
    if (wellTypes.length > 1) {
      return <DeblurIcon fontSize="small" sx={{ color: palette.customColor.neutralSecondary }} />;
    }
    // no dataset  removed, etc
    if (wellTypes.length === 0) {
      return <WarningIcon fontSize="small" sx={{ color: palette.error.main }} />;
    }
    const firstWellType = wellTypes?.[0] ?? "";
    switch (firstWellType) {
      case "oil":
        return <CircleIcon fontSize="small" sx={{ color: palette.success.main }} />;

      case "gas":
        return <WbSunnyOutlinedIcon fontSize="small" sx={{ color: palette.secondary.main }} />;

      case "water":
        return <WaterDropIcon fontSize="small" sx={{ color: palette.info.main }} />;

      // unknown type
      default:
        return <GrainOutlinedIcon fontSize="small" sx={{ color: palette.customColor.redDark }} />;
    }
  }, [
    palette.customColor.neutralSecondary,
    palette.customColor.redDark,
    palette.error.main,
    palette.info.main,
    palette.secondary.main,
    palette.success.main,
    wellTypes,
  ]);

  return (
    <>
      {renderIcon()}
      <div className="dataset-name noselect">{name}</div>
    </>
  );
};
const DataSetRadioItem = ({
  onClick,
  dataSet,
  setActiveDialog,
  currDataSet,
  checked,
  disableMenu = false,
  dataTestId,
  onClickPreview,
  ...rest
}: DataSetRadioItemProps) => {
  const { palette } = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  return (
    <DataSetItem onKeyDown={(event) => event.key === "Enter" && onClick()} key={dataSet} data-testid={dataTestId} {...rest}>
      <div
        onClick={() => {
          onClick();
        }}
        onKeyDown={() => {
          /* no-op*/
        }}
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Radio
          style={{
            visibility: "visible",
          }}
          checked={checked}
          onClick={() => {
            onClick();
          }}
          size="small"
          className="radio-well "
        />
        <WellItem tags={currDataSet?.tags} name={dataSet} />
      </div>
      <DataSetMoreButton>
        <IconButton
          aria-haspopup="true"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          aria-label="more"
        >
          <MoreHorizIcon className="more-icon" fontSize="medium" sx={{ color: palette.primary.main }} />
        </IconButton>
        <div>
          <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
            <MenuItem
              data-testid="preview-well"
              onClick={() => {
                onClickPreview(currDataSet?.id ?? "", disableMenu);
                if (!checked) onClick();
                setAnchorEl(null);
              }}
              key={dictionary.nav.removeCurrentWell}
            >
              <RemoveRedEyeIcon sx={{ color: palette.primary.main }} />
              <div
                style={{
                  marginLeft: 5,
                }}
              >
                {dictionary.nav.previewWell}
              </div>
            </MenuItem>
            {!disableMenu && (
              <MenuItem
                data-testid="remove-well"
                onClick={() => {
                  setActiveDialog(DialogEnum.REMOVE_DATASET);
                  if (!checked) onClick();
                  setAnchorEl(null);
                }}
                key={dictionary.nav.removeCurrentWell}
              >
                <DeleteIcon sx={{ color: palette.primary.main }} />
                <div
                  style={{
                    marginLeft: 5,
                  }}
                >
                  {dictionary.nav.removeCurrentWell}
                </div>
              </MenuItem>
            )}
          </Menu>
        </div>
      </DataSetMoreButton>
    </DataSetItem>
  );
};

export default DataSetRadioItem;
