/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { ModuleGazFmbPvtWellboreOptions } from "./model";
import { OilDensityMethod, SolGasMethod, WaterViscosityMethod } from "../../../model";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import InputField from "@/components/fields/InputField";
import DropdownField from "@/components/fields/DropdownField";

interface ModuleGazFmbWellboreInputGridProps {
  value: ModuleGazFmbPvtWellboreOptions | undefined;
  onChange: (val: ModuleGazFmbPvtWellboreOptions | undefined) => void;
  enabled: boolean;
  setEnabled: (val: boolean) => void;
  handleInputNotChanged: () => void;
}

const oilDensityMethodOptions = [{ key: OilDensityMethod.EOS, text: "EOS" }];

const solGasMethodOptions = [
  { key: SolGasMethod.VASQUEZ_BEGGS, text: "Vasquez-Beggs" },
  { key: SolGasMethod.STANDING, text: "Standing" },
  { key: SolGasMethod.GLASO, text: "Glaso" },
  { key: SolGasMethod.LASATER, text: "Lasater" },
  { key: SolGasMethod.PETROSKY, text: "Petrosky" },
];

const wtrViscMethodOptions = [
  { key: WaterViscosityMethod.MCCAIN, text: "McCain" },
  { key: WaterViscosityMethod.LOUISIANA_STATE_UNI, text: "Louisiana State University" },
  { key: WaterViscosityMethod.BRILL_BEGGS, text: "Brill-Beggs" },
];

const ModuleGazFmbWellboreInputGrid = ({ value, onChange, enabled, setEnabled, handleInputNotChanged }: ModuleGazFmbWellboreInputGridProps) => {
  // Reset defaults when not selected
  useEffect(() => {
    if (value?.include_oil || !value) return;
    onChange({ ...value, oil_api_psia: 30, oil_pb_psia: 1000 } as ModuleGazFmbPvtWellboreOptions);
  }, [onChange, value?.include_oil]);

  useEffect(() => {
    if (value?.include_water || !value) return;
    onChange({ ...value, water_salinity_ppm: 0 } as ModuleGazFmbPvtWellboreOptions);
  }, [onChange, value?.include_water]);

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e) => {
              handleInputNotChanged();
              return setEnabled(!!e.target.checked);
            }}
            checked={enabled}
            size="small"
          />
        }
        label="Wellbore model"
      />
      {enabled && value ? (
        <>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridGap: 20,
            }}
          >
            <div>
              <FormControlLabel
                control={
                  value ? (
                    <Checkbox onChange={(e) => onChange({ ...value, include_oil: !!e.target.checked })} checked={value.include_oil} size="small" />
                  ) : (
                    <></>
                  )
                }
                label="Include Oil PVT"
              />
            </div>

            <div>
              <InputField
                label="Oil API"
                suffix="psia"
                disabled={!value.include_oil}
                value={value.oil_api_psia}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        oil_api_psia: Number(v),
                      })
                    : null
                }
              />
              <InputField
                label="Oil Pb"
                suffix="psia"
                disabled={!value.include_oil}
                value={value.oil_pb_psia}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        oil_pb_psia: Number(v),
                      })
                    : null
                }
              />
            </div>

            <div>
              <DropdownField
                style={{
                  marginTop: 0,
                }}
                label="Oil density method"
                options={oilDensityMethodOptions}
                selectedKey={value.oil_density_method}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        oil_density_method: v as OilDensityMethod,
                      })
                    : null
                }
              />
              <DropdownField
                label="Sol. gas method"
                options={solGasMethodOptions}
                selectedKey={value.sol_gas_method}
                onChange={(v) => {
                  onChange({
                    ...value,
                    sol_gas_method: v as SolGasMethod,
                  });
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridGap: 20,
            }}
          >
            <div>
              <FormControlLabel
                control={
                  value ? (
                    <Checkbox
                      onChange={(e) => onChange({ ...value, include_water: !!e.target.checked })}
                      checked={value.include_water}
                      size="small"
                    />
                  ) : (
                    <></>
                  )
                }
                label="Include Water PVT"
              />
            </div>

            <div>
              <InputField
                label="Water salinity"
                suffix="ppm"
                disabled={!value.include_water}
                value={value.water_salinity_ppm}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        water_salinity_ppm: Number(v),
                      })
                    : null
                }
              />
            </div>

            <div>
              <DropdownField
                style={{
                  marginTop: 0,
                }}
                label="Water viscosity method"
                options={wtrViscMethodOptions}
                selectedKey={value.water_viscosity_method}
                onChange={(v) => {
                  onChange({
                    ...value,
                    water_viscosity_method: v as WaterViscosityMethod,
                  });
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridGap: 20,
            }}
          >
            <div>
              <InputField
                label="Tubing diameter"
                suffix="in"
                value={value.tubing_diameter_in}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        tubing_diameter_in: Number(v),
                      })
                    : null
                }
              />
              <InputField
                label="Tubing roughness"
                suffix="in"
                value={value.tubing_roughness_in}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        tubing_roughness_in: Number(v),
                      })
                    : null
                }
              />
              <InputField
                label="Tubing depth"
                suffix="ft"
                value={value.tubing_depth_ft}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        tubing_depth_ft: Number(v),
                      })
                    : null
                }
              />
            </div>

            <div>
              <InputField
                label="Wellhead temperature"
                suffix="°F"
                value={value.wellhead_temp_of}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        wellhead_temp_of: Number(v),
                      })
                    : null
                }
              />
              <InputField
                label="Well angle"
                suffix="°"
                value={value.well_angle_deg}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        well_angle_deg: Number(v),
                      })
                    : null
                }
              />
              <InputField
                type="int"
                label="Number of increments"
                value={value.num_inc}
                onChange={(v) =>
                  v !== undefined
                    ? onChange({
                        ...value,
                        num_inc: Number(v),
                      })
                    : null
                }
              />
            </div>
          </div>
        </>
      ) : undefined}
    </div>
  );
};

export default ModuleGazFmbWellboreInputGrid;
