import React, { useCallback, useMemo } from "react";
import _ from "lodash";

import { DistributionApplicationEnum, DistributionTypeEnum, KoldunCsgApiState, KoldunMeasure, StandardDistributionScheme } from "@/models/koldunV2";
import DropdownField from "@/components/fields/DropdownField";
import { mapEnumToDropdown } from "@/utils/general";
import { defaultValueMap, suffixMap } from "../../../constants";
import InputField from "@/components/fields/InputField";
import dictionary from "@/constants/dictionary";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

type StandardizedMonteCarloInputFieldProps = {
  setKoldunCsgState: React.Dispatch<React.SetStateAction<KoldunCsgApiState | undefined>>;
  // for custom distribution options
  customOptions?: { key: string; text: string }[];
  // this will determine which key to select
  currentItemKey: string;
  measures: KoldunMeasure[];
  selectedLayer: number;

  errorValidation?: ErrorValidationDetail[];
  modelType: string;
  setValidationError: React.Dispatch<React.SetStateAction<ErrorValidationDetail[]>>;
};

const standardDistributionOption = mapEnumToDropdown(DistributionTypeEnum).filter((item) => item.key !== DistributionTypeEnum.Lorenz);

const StandardizedMonteCarloInputField = ({
  customOptions,
  setKoldunCsgState,
  currentItemKey,
  measures,
  selectedLayer,
  errorValidation,
  modelType,
  setValidationError,
}: StandardizedMonteCarloInputFieldProps) => {
  const measure = useMemo(() => {
    return measures[selectedLayer];
  }, [measures, selectedLayer]);

  const currentItem = useMemo(() => {
    return measure?.[currentItemKey as keyof KoldunMeasure] as StandardDistributionScheme;
  }, [measure, currentItemKey]);

  const generateDistributionParameter = useCallback(
    (newType: DistributionTypeEnum, newApp: DistributionApplicationEnum) => {
      const changedDistribution = _.cloneDeep(currentItem);

      let totalLayer = 1;
      changedDistribution.distribution_type = newType;
      changedDistribution.distribution_application = newApp;

      if (changedDistribution.distribution_application === DistributionApplicationEnum.PerLayer) {
        totalLayer = measure.number_of_layers;
      } else {
        changedDistribution.distribution_parameters = [changedDistribution.distribution_parameters[0]];
      }

      if (newType === DistributionTypeEnum.Lorenz) {
        changedDistribution.distribution_application = DistributionApplicationEnum.PerMeasure;
        totalLayer = 1;
      }

      if (!changedDistribution.distribution_parameters || newType !== currentItem.distribution_type) {
        changedDistribution.distribution_parameters = [];
      }

      for (let index = changedDistribution.distribution_parameters.length; index < totalLayer; index++) {
        changedDistribution.distribution_parameters.push(_.cloneDeep(defaultValueMap[currentItemKey][newType]));
      }

      return changedDistribution;
    },
    [currentItem, measure, currentItemKey]
  );

  const onChangeDistribution = useCallback(
    (val: DistributionTypeEnum) => {
      const newMeasures = _.cloneDeep(measures);
      const newDistribution = generateDistributionParameter(val, currentItem.distribution_application);
      newMeasures[selectedLayer] = { ...newMeasures[selectedLayer], [currentItemKey]: newDistribution };

      setKoldunCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            measures: newMeasures,
          },
        };
      });
    },
    [generateDistributionParameter, currentItem, setKoldunCsgState, currentItemKey, measures, selectedLayer]
  );

  const onChangeDistributionApplication = useCallback(
    (newApp: DistributionApplicationEnum) => {
      const newMeasures = _.cloneDeep(measures);
      const newDistribution = generateDistributionParameter(currentItem.distribution_type, newApp);
      newMeasures[selectedLayer] = { ...newMeasures[selectedLayer], [currentItemKey]: newDistribution };

      setKoldunCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            measures: newMeasures,
          },
        };
      });
    },
    [generateDistributionParameter, currentItem, setKoldunCsgState, currentItemKey, measures, selectedLayer]
  );

  const onChangeInputField = useCallback(
    (index: number, newValue: any) => {
      if (errorValidation) {
        // make sure remove all the related error from this item
        const filtered = errorValidation?.filter((error) => !error.loc.includes(currentItemKey));
        setValidationError(filtered);
      }

      const newMeasures = _.cloneDeep(measures);
      const changedDistribution = _.cloneDeep(currentItem);
      changedDistribution.distribution_parameters[index] = newValue;

      newMeasures[selectedLayer] = { ...newMeasures[selectedLayer], [currentItemKey]: changedDistribution };

      setKoldunCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            measures: newMeasures,
          },
        };
      });
    },
    [setKoldunCsgState, setValidationError, errorValidation, currentItem, currentItemKey, measures, selectedLayer]
  );

  // accept the distribution parameter
  // using any because overlap item type that will create typescript error
  const generateInputField = useCallback(
    (item: any, index: number) => {
      if (!item) return;
      const layerText = currentItem.distribution_parameters.length > 1 ? `Layer ${index + 1} - ` : "";
      switch (currentItem.distribution_type) {
        case DistributionTypeEnum.Fixed:
          return (
            <div
              key={index + currentItem.distribution_type}
              style={{
                display: "grid",
                gridGap: 20,
                gridTemplateColumns: "1fr 1fr 1fr",
              }}
            >
              <InputField
                styles={{
                  width: "100%",
                }}
                type="float"
                value={item.value}
                required={true}
                dataTestId={`${currentItemKey}-fixed-input`}
                label={layerText + dictionary.koldunCsg.fixed}
                suffix={suffixMap[currentItemKey]}
                debounceDelay={100}
                onChange={(v) => {
                  onChangeInputField(index, {
                    ...item,
                    value: Number(v),
                  });
                }}
                errors={errorValidation}
                keyField={`options.measures.${selectedLayer}.${modelType}.${currentItemKey}`}
              />
              <div />
              <div />
            </div>
          );
        case DistributionTypeEnum.Uniform:
          return (
            <div
              key={index + currentItem.distribution_type}
              style={{
                display: "grid",
                gridGap: 20,
                gridTemplateColumns: "1fr 1fr 1fr",
              }}
            >
              <InputField
                type="float"
                value={item.low_value}
                required={true}
                dataTestId={`${currentItemKey}-low_value`}
                label={layerText + dictionary.koldunCsg.low}
                suffix={suffixMap[currentItemKey]}
                debounceDelay={100}
                onChange={(v) => {
                  onChangeInputField(index, {
                    ...item,
                    low_value: Number(v),
                  });
                }}
                styles={{
                  width: "100%",
                }}
                errors={errorValidation}
                keyField={`options.measures.${selectedLayer}.${modelType}.${currentItemKey}`}
              />
              <InputField
                styles={{
                  width: "100%",
                }}
                type="float"
                value={item.high_value}
                required={true}
                dataTestId={`${currentItemKey}-high_value`}
                label={layerText + dictionary.koldunCsg.high}
                suffix={suffixMap[currentItemKey]}
                debounceDelay={100}
                onChange={(v) => {
                  onChangeInputField(index, {
                    ...item,
                    high_value: Number(v),
                  });
                }}
                errors={errorValidation}
              />
              <div />
            </div>
          );
        case DistributionTypeEnum.Lorenz:
          return (
            <div
              key={index + currentItem.distribution_type}
              style={{
                display: "grid",
                gridGap: 20,
                gridTemplateColumns: "1fr 1fr 1fr",
              }}
            >
              <InputField
                styles={{
                  width: "100%",
                }}
                type="float"
                value={item.value}
                required={true}
                dataTestId={`${currentItemKey}-net_coal_thickness`}
                label={layerText + dictionary.koldunCsg.netCoalThickness}
                suffix={suffixMap[currentItemKey]}
                debounceDelay={100}
                onChange={(v) => {
                  onChangeInputField(index, {
                    ...item,
                    value: Number(v),
                  });
                }}
                errors={errorValidation}
                keyField={`options.measures.${selectedLayer}.${modelType}.${currentItemKey}`}
              />
              <InputField
                styles={{
                  width: "100%",
                }}
                type="float"
                value={item.lorenz_factor}
                required={true}
                dataTestId={`${currentItemKey}-lorenz_factor`}
                label={layerText + dictionary.koldunCsg.lorenzFactor}
                suffix={suffixMap[currentItemKey]}
                debounceDelay={100}
                onChange={(v) => {
                  onChangeInputField(index, {
                    ...item,
                    lorenz_factor: Number(v),
                  });
                }}
                errors={errorValidation}
              />
              <div />
            </div>
          );
        case DistributionTypeEnum.Triangular:
          return (
            <div
              key={index + currentItem.distribution_type}
              style={{
                display: "grid",
                gridGap: 20,
                gridTemplateColumns: "1fr 1fr 1fr",
              }}
            >
              <InputField
                styles={{
                  width: "100%",
                }}
                type="float"
                value={item.low_value}
                required={true}
                dataTestId={`${currentItemKey}-low_value`}
                label={layerText + dictionary.koldunCsg.low}
                suffix={suffixMap[currentItemKey]}
                debounceDelay={100}
                onChange={(v) => {
                  onChangeInputField(index, {
                    ...item,
                    low_value: Number(v),
                  });
                }}
                errors={errorValidation}
                keyField={`options.measures.${selectedLayer}.${modelType}.${currentItemKey}`}
              />
              <InputField
                styles={{
                  width: "100%",
                }}
                type="float"
                value={item.mode_value}
                required={true}
                dataTestId={`${currentItemKey}-mode_value`}
                label={layerText + dictionary.koldunCsg.mode}
                suffix={suffixMap[currentItemKey]}
                debounceDelay={100}
                onChange={(v) => {
                  onChangeInputField(index, {
                    ...item,
                    mode_value: Number(v),
                  });
                }}
                errors={errorValidation}
              />
              <InputField
                styles={{
                  width: "100%",
                }}
                type="float"
                value={item.high_value}
                required={true}
                dataTestId={`${currentItemKey}-high_value`}
                label={layerText + dictionary.koldunCsg.high}
                suffix={suffixMap[currentItemKey]}
                debounceDelay={100}
                onChange={(v) => {
                  onChangeInputField(index, {
                    ...item,
                    high_value: Number(v),
                  });
                }}
                errors={errorValidation}
              />
            </div>
          );
        default:
          return (
            <div
              key={index + currentItem.distribution_type}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridGap: 20,
              }}
            >
              <InputField
                type="float"
                value={item.mean_value}
                required={true}
                label={layerText + dictionary.koldunCsg.mean}
                dataTestId={`${currentItemKey}-mean_value`}
                suffix={suffixMap[currentItemKey]}
                debounceDelay={100}
                onChange={(v) => {
                  onChangeInputField(index, {
                    ...item,
                    mean_value: Number(v),
                  });
                }}
                styles={{
                  width: "100%",
                }}
                errors={errorValidation}
                keyField={`options.measures.${selectedLayer}.${modelType}.${currentItemKey}`}
              />
              <InputField
                type="float"
                value={item.standard_deviation}
                required={true}
                label={layerText + dictionary.koldunCsg.std}
                dataTestId={`${currentItemKey}-std_deviation`}
                suffix={suffixMap[currentItemKey]}
                debounceDelay={100}
                onChange={(v) => {
                  onChangeInputField(index, {
                    ...item,
                    standard_deviation: Number(v),
                  });
                }}
                styles={{
                  width: "100%",
                }}
                errors={errorValidation}
              />
              <div />
            </div>
          );
      }
    },
    [
      currentItem?.distribution_parameters.length,
      currentItem?.distribution_type,
      currentItemKey,
      errorValidation,
      modelType,
      onChangeInputField,
      selectedLayer,
    ]
  );

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 3fr",
        gridGap: 20,
        alignItems: "flex-start",
      }}
    >
      <DropdownField
        label={dictionary.koldunCsg[currentItemKey]}
        dataTestId={dictionary.koldunCsg[currentItemKey]}
        options={customOptions ?? standardDistributionOption}
        selectedKey={currentItem?.distribution_type}
        onChange={(val) => onChangeDistribution(val as DistributionTypeEnum)}
      />
      <DropdownField
        disabled={currentItem?.distribution_type === DistributionTypeEnum.Lorenz}
        label={dictionary.koldunCsg.application}
        dataTestId={dictionary.koldunCsg.application}
        options={mapEnumToDropdown(DistributionApplicationEnum)}
        selectedKey={currentItem?.distribution_application}
        onChange={(val) => onChangeDistributionApplication(val as DistributionApplicationEnum)}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 10,
          width: "100%",
        }}
      >
        {currentItem?.distribution_parameters.map((param, index) => {
          return generateInputField(param, index);
        })}
      </div>
    </div>
  );
};

export default StandardizedMonteCarloInputField;
