import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Row } from "@silevis/reactgrid";
import _ from "lodash";

import { ModuleIdentity } from "@/models/Generic";
import {
  LayerItem,
  TahkCsgLangmuirIsothermEnum,
  TahkCsgStateResponse,
  TahkCsgInputState,
  TahkCsgAnalysisCalculateResponse,
  TahkAnalysisChartData,
  TahkCsgAnalysisCalculateRequestPayload,
  TahkCsgAnalysisValidationRequestPayload,
  TahkCsgValidationState,
  TahkCsgAnalysisCalculateResponseScheme,
} from "@/models/tahk";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

import dictionary from "@/constants/dictionary";
import { FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { camelToSnakeCase, formatToScientific } from "@/utils/general";

import { dataTableNotation, getDataTableHeader } from "../../utils";
import { cumulativeSeriesKey, dataSeriesKey, dataTableHeaderStyles, seriesKey } from "../../constants/grid";
import { CsvData } from "@/features/app/app.types";
import { transformRowToString } from "@/utils/csvProcessing";
import { calculateTahkCsgAnalysis, historyMatchTahkCsgAnalysis } from "@/constants/apiUrl";
import { PollHelper } from "../../context/TahkCsgContext";
import { usePolling } from "@/utils/apiFetcher";
import { parseErrorThrown } from "@/utils/errorHandling";
import useInterval from "@/utils/useInterval";
import { postValidateAnalysisTahkCsg } from "@/models/tahk/apiFetcher/analysis";

type UseTahkCsgAnalysisProps = {
  isLoading: boolean;
  tabIndex: number;
  analysisIdentity?: ModuleIdentity;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  tahkCsgState?: TahkCsgStateResponse | null;
  setApiError: ({ message }: { message: string }) => void;
  setCsvData: (csvData?: CsvData[]) => void;
  setLoadingDependency: React.Dispatch<React.SetStateAction<boolean>>;
} & PollHelper;

const safeTahkDictionary: {
  [key: string]: string;
} = dictionary.tahk;

const useTahkCsgAnalysis = ({
  setCsvData,
  setApiError,
  isLoading,
  setTahkCsgState,
  tahkCsgState,
  analysisIdentity,
  tabIndex,
  setIsLoading,
  setPollStatus,
  setProgress,
  apiError,
  setLoadingBlocker,
  setLoadingDependency,
}: UseTahkCsgAnalysisProps) => {
  const { palette } = useTheme();

  const [selectedLayer, setSelectedLayer] = useState(0);
  const [selectedDataTableLayer, setSelectedDataTableLayer] = useState<string>("total");

  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);

  const [calculation, setCalculation] = useState<TahkCsgAnalysisCalculateResponse | null>(null);
  const [loadingState, setLoadingState] = useState(false);

  const lastCalculationReq = useRef<any>(null);
  const isLoadingValidation = useRef<boolean>(false);

  const latestState = useRef<any>(null);

  const lastestTabIndex = useRef<number>(0);
  const latestValidationPayload = useRef<any>(null);

  const haveInitializeValidate = useRef(false);

  const { createPoll, canCancelPoll, onCancelPoll } = usePolling({
    setApiError,
    setErrorValidation: setErrorInputValidation,
    setLoadingState: (val) => {
      setIsLoading(val);
    },
    setProgressStatus: (val) => {
      setProgress(val.progress ?? null);
      setPollStatus(val.pollStatus);
    },
    setLoadingBlocker: (val) => {
      setLoadingBlocker?.(val?.isBlocking ?? false);
    },
    apiError,
  });

  const analysis = useMemo(() => {
    if (!tahkCsgState?.analysis) return null;
    return tahkCsgState.analysis;
  }, [tahkCsgState?.analysis]);

  const layers = useMemo(() => {
    if (!analysis) return [];
    return analysis.layers;
  }, [analysis]);

  const layerOption = useMemo(() => {
    return Array.from(Array(layers?.length).keys()).map((_, index) => {
      return {
        key: index,
        text: `${dictionary.tahk.layer} ${index + 1}`,
      };
    });
  }, [layers?.length]);

  const dataTableLayerOption = useMemo(() => {
    const layerLength = calculation?.data_table?.length ?? 1;
    // manually append total from all layer
    return [
      {
        key: "total",
        text: dictionary.tahk.total,
      },
      ...Array.from(Array(layerLength - 1).keys()).map((_, index) => {
        return {
          key: String(index),
          text: `${dictionary.tahk.layer} ${index + 1}`,
        };
      }),
    ];
  }, [calculation?.data_table?.length]);

  const currentLayer = useMemo(() => {
    if (!tahkCsgState || !analysis) return null;
    // check if volumetric or ogip
    try {
      const layerInputType = tahkCsgState.inputs?.layers?.[selectedLayer]?.selected_langmuir_inputs;

      if (layerInputType === TahkCsgLangmuirIsothermEnum.Volumetric) {
        return {
          labelOrder: [
            "initialPressure",
            "langmuirPressure",
            "langmuirVolume",
            "desorptionPressure",
            "porosity",
            "area",
            "permeabilityXAxis",
            "permeabilityYAxis",
            "ng",
            "nw",
            "skin",
            "formationCompressibility",
          ],
          data: analysis.layers[selectedLayer] as {
            [key: string]: LayerItem;
          },
        };
      }
      return {
        labelOrder: [
          "initialPressure",
          "langmuirPressure",
          "ogip",
          "undersaturated",
          "initialGasContent",
          "porosity",
          "permeabilityXAxis",
          "permeabilityYAxis",
          "ng",
          "nw",
          "skin",
          "formationCompressibility",
          "shrinkageFactor",
        ],
        data: analysis.layers[selectedLayer] as {
          [key: string]: LayerItem;
        },
      };
    } catch (error) {
      return null;
    }
  }, [analysis, selectedLayer, tahkCsgState]);

  const onCalculateAnalysis = useCallback(
    async (input: TahkCsgInputState) => {
      if (!analysisIdentity) return;
      setLoadingState(true);
      try {
        const payload = {
          data_options: {
            analysis_identity: {
              ...analysisIdentity,
            },
          },
          options: input,
        };
        lastCalculationReq.current = JSON.parse(JSON.stringify(payload));
        const response = await createPoll<TahkCsgAnalysisCalculateResponse, TahkCsgAnalysisCalculateRequestPayload>({
          path: calculateTahkCsgAnalysis(payload.data_options.analysis_identity.project_id),
          body: payload,
          type: "post",
          withTaskInfo: true,
          isBlocking: true,
        });
        if (response.task_result) {
          const parsed = TahkCsgAnalysisCalculateResponseScheme.parse(response.task_result);
          setCalculation(parsed);
        }
      } catch (error: any) {
        console.log(error);
        parseErrorThrown({
          error,
          setApiError,
        });
      } finally {
        setLoadingState(false);
        setLoadingBlocker?.(false);
      }
    },
    [analysisIdentity, createPoll, setLoadingBlocker, setApiError]
  );

  const onValidateAnalysis = useCallback(
    async (state?: TahkCsgStateResponse, runCalculate?: boolean) => {
      haveInitializeValidate.current = true;
      const latestState = state ?? tahkCsgState;
      const latestAnalysis = latestState?.analysis;

      // make sure don't call other api when polling
      if (isLoading) return;
      if (analysisIdentity && latestAnalysis && latestState?.inputs) {
        setLoadingDependency(true);

        try {
          isLoadingValidation.current = true;
          const payload = {
            data_options: {
              analysis_identity: analysisIdentity,
            },
            options: {
              analysis_options: latestAnalysis,
              input_options: latestState?.inputs,
            },
          };

          // don't need to recall api if user doesn't change anything
          if (_.isEqual(latestValidationPayload.current, payload)) return;

          latestValidationPayload.current = _.cloneDeep(payload);
          const validationResponse = await postValidateAnalysisTahkCsg(payload);

          if (!validationResponse?.data) return;
          const inputEqual = _.isEqual(latestState.inputs, validationResponse.data.input_options);
          const analysisEqual = _.isEqual(latestState.analysis, validationResponse.data.analysis_options);
          if (!analysisEqual || !inputEqual) {
            setTahkCsgState((prev) => {
              if (!prev || !validationResponse?.data) return prev;
              const newState = { ...prev };
              if (!analysisEqual) {
                newState.analysis = validationResponse.data.analysis_options ?? prev?.analysis;
              }
              if (!inputEqual) {
                newState.inputs = validationResponse.data.input_options ?? prev?.inputs;
              }
              return newState;
            });
          }
          if (runCalculate) onCalculateAnalysis(validationResponse.data.input_options);
        } catch (error: any) {
          console.log(error);
          parseErrorThrown({
            error,
            setApiError,
            setValidationError: setErrorInputValidation,
          });
        } finally {
          isLoadingValidation.current = false;
          setLoadingDependency(false);
        }
      }
    },
    [tahkCsgState, isLoading, analysisIdentity, setLoadingDependency, onCalculateAnalysis, setTahkCsgState, setApiError]
  );

  const onClickHistoryMatch = useCallback(async () => {
    if (analysisIdentity && analysis && tahkCsgState?.inputs) {
      try {
        setLoadingState(true);
        const payload = {
          data_options: {
            analysis_identity: analysisIdentity,
          },
          options: {
            analysis_options: analysis,
            input_options: tahkCsgState?.inputs,
          },
        };
        const validationResponse = await createPoll<TahkCsgValidationState, TahkCsgAnalysisValidationRequestPayload>({
          path: historyMatchTahkCsgAnalysis(payload.data_options.analysis_identity.project_id),
          body: payload,
          type: "post",
          withTaskInfo: true,
          isBlocking: true,
        });

        if (validationResponse.task_result) {
          const inputEqual = _.isEqual(tahkCsgState.inputs, validationResponse.task_result.input_options);
          const analysisEqual = _.isEqual(tahkCsgState.analysis, validationResponse.task_result.analysis_options);
          if (!analysisEqual || !inputEqual) {
            setTahkCsgState((prev) => {
              if (!prev) return prev;
              const newState = { ...prev };
              if (!analysisEqual) {
                newState.analysis = validationResponse?.task_result?.analysis_options ?? prev?.analysis;
              }
              if (!inputEqual) {
                newState.inputs = validationResponse?.task_result?.input_options ?? prev?.inputs;
              }
              return newState;
            });
          }
          onCalculateAnalysis(validationResponse.task_result.input_options);
        } else {
          setLoadingState(false);
          setLoadingBlocker?.(false);
          setApiError({
            message: dictionary.serverError,
          });
        }
      } catch (error: any) {
        setLoadingState(false);
        setLoadingBlocker?.(false);
        console.log(error);
      }
    }
  }, [
    analysisIdentity,
    analysis,
    tahkCsgState?.inputs,
    tahkCsgState?.analysis,
    createPoll,
    onCalculateAnalysis,
    setTahkCsgState,
    setApiError,
    setLoadingBlocker,
  ]);

  const onChangeAnalysisInput = useCallback(
    (val: any, key: string) => {
      setTahkCsgState((prev) => {
        if (!prev) return prev;

        return {
          ...prev,
          analysis: {
            ...prev.analysis,
            [key]: val,
          },
        };
      });
    },
    [setTahkCsgState]
  );

  const onChangeAnalysisInputLayer = useCallback(
    (val: any, key: string, option: keyof LayerItem) => {
      setTahkCsgState((prev) => {
        if (!prev) return prev;
        const newLayers = [...prev.analysis.layers];

        const currLayer: { [key: string]: any } = newLayers[selectedLayer];
        if (currLayer[key]) {
          currLayer[key] = {
            ...currLayer[key],
            [option]: val,
          };
        }

        return {
          ...prev,
          analysis: {
            ...prev.analysis,
            layers: newLayers,
          },
        };
      });
    },
    [selectedLayer, setTahkCsgState]
  );

  const chartXAxes = useMemo(() => {
    return [{ name: dictionary.dataView.date, type: "time", color: palette.grey[900] }];
  }, [palette.grey]);

  const chartYAxes = useMemo(() => {
    return [
      {
        name: `${dictionary.tahk.pressure} ${dictionary.tableUnits.pressure}`,
        type: "value",
        color: palette.grey[900],
        min: 0,
        position: "left",
        offset: 75,
        nameGap: 30,
      },
      {
        name: `${dictionary.tahk.gasRate} ${dictionary.tableUnits.gasRate}`,
        type: "value",
        color: palette.error.main,
        min: 0,
        position: "left",
        offset: 0,
        nameGap: 30,
      },
      {
        name: `${dictionary.tahk.waterRate} ${dictionary.tableUnits.waterRate}`,
        type: "value",
        color: palette.info.main,
        min: 0,
        position: "right",
        offset: 5,
        nameGap: 45,
      },
    ];
  }, [palette.error.main, palette.grey, palette.info.main]);

  const getSeriesColor = useCallback(
    (key: string) => {
      if (key.toLowerCase().includes("gas")) return palette.error.main;
      if (key.toLowerCase().includes("water")) return palette.info.main;

      return palette.common.black;
    },
    [palette]
  );

  const getYAxisIndex = (key: string) => {
    if (key.toLowerCase().includes("gas")) return 1;
    if (key.toLowerCase().includes("water")) return 2;
    return 0;
  };

  const chartSeries = useMemo(() => {
    if (!calculation) return [];
    const curveModel: FossilyticsChartSeries[] = [];
    seriesKey.forEach((key) => {
      if (calculation.tahk_analysis_curve_model[camelToSnakeCase(key) as keyof TahkAnalysisChartData]) {
        curveModel.push({
          name: `${dictionary.tahk.model} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: calculation.tahk_analysis_curve_model
            ? calculation.tahk_analysis_curve_model.dates.map((d, i) => [
                d,
                calculation.tahk_analysis_curve_model?.[camelToSnakeCase(key) as keyof TahkAnalysisChartData]?.[i],
              ])
            : [],
          yAxisIndex: getYAxisIndex(key),
          hideSymbol: true,
          lineWidth: 2,
        });
      }
    });
    const curveData: FossilyticsChartSeries[] = [];
    dataSeriesKey.forEach((key) => {
      if (calculation.tahk_analysis_curve_data[camelToSnakeCase(key) as keyof TahkAnalysisChartData]) {
        curveModel.push({
          name: `${dictionary.tahk.data} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: calculation.tahk_analysis_curve_data
            ? calculation.tahk_analysis_curve_data.dates.map((d, i) => [
                d,
                calculation.tahk_analysis_curve_data?.[camelToSnakeCase(key) as keyof TahkAnalysisChartData]?.[i],
              ])
            : [],
          yAxisIndex: getYAxisIndex(key),
        });
      }
    });

    return [...curveModel, ...curveData];
  }, [calculation, getSeriesColor]);

  const generateRows = useCallback((dataTable: TahkCsgAnalysisCalculateResponse["data_table"], indexData: number, isTotal?: boolean) => {
    return [
      ...dataTable[indexData].dates.map((_, index) => {
        const safeParam = dataTable[indexData] as { [key: string]: any };
        return {
          rowId: index,
          cells: Object.keys(isTotal ? dictionary.tahkTotalDataTable : dictionary.tahkDataTable).map((key) => {
            const val = safeParam?.[camelToSnakeCase(key)][index] ?? "-";
            return {
              type: "text",
              text: formatToScientific(val),

              style: { display: "flex", justifyContent: "center", zIndex: 0 },
            };
          }),
        };
      }),
    ];
  }, []);

  const dataTableRows: Row<any>[] = useMemo(() => {
    if (!calculation) return [];
    if (selectedDataTableLayer === "total") {
      return [
        getDataTableHeader(dictionary.tahkTotalDataTable),
        dataTableNotation(dictionary.tahkTotalDataTable),
        ...generateRows(calculation.data_table, calculation.data_table.length - 1, true),
      ];
    }
    const indexData = Number(selectedDataTableLayer);
    return [
      getDataTableHeader(dictionary.tahkDataTable),
      dataTableNotation(dictionary.tahkDataTable),
      ...generateRows(calculation.data_table, indexData),
    ];
  }, [calculation, generateRows, selectedDataTableLayer]);

  const parameterTableRows: Row<any>[] = useMemo(() => {
    if (!calculation) return [];
    return [
      {
        rowId: "header",
        cells: [
          {
            type: "header",
            text: dictionary.tahk.parameters,
            style: {
              background: "#fff",
              display: "flex",
              justifyContent: "flex-start",
              fontWeight: "bold",
              zIndex: 1,
            },
          },
          ...Array.from(Array(calculation?.table_of_final_parameters?.length ?? 0).keys()).map((_, index) => {
            return {
              type: "header",
              text: `${dictionary.tahk.layer} ${index + 1}`,
              style: dataTableHeaderStyles,
            };
          }),
        ],
      },
      ...Object.keys(dictionary.tahkParameterTable).reduce((array: any[], param: any) => {
        const calTable: { [key: string]: any } = calculation.table_of_final_parameters?.[0];

        if (String(calTable[camelToSnakeCase(param)]) !== "null" && String(calTable[camelToSnakeCase(param)]) !== "undefined") {
          array.push({
            rowId: param,
            editable: false,
            cells: [
              {
                type: "custom",
                text: dictionary.tahkParameterTable[param].text,
                sub: dictionary.tahkParameterTable[param].sub,
                style: { display: "flex", justifyContent: "flex-start" },
                editable: false,
              },
              ...Array.from(Array(calculation?.table_of_final_parameters?.length ?? 0).keys()).map((_, index) => {
                const calTable: { [key: string]: any } = calculation.table_of_final_parameters?.[index];
                const val = calTable?.[camelToSnakeCase(param)] ?? "-";
                return {
                  type: "custom",
                  text: formatToScientific(val),
                  style: { display: "flex", justifyContent: "center" },
                  editable: false,
                };
              }),
            ],
          });
        }

        return array;
      }, []),
    ];
  }, [calculation]);

  const parameterTableColumn = useMemo(() => {
    if (!calculation) return [];
    return [
      {
        columnId: "parameterKey",
        width: 180,
        justifyContent: "flex-start",
      },
      ...Array.from(Array(calculation?.table_of_final_parameters?.length ?? 0).keys()).map((_, index) => {
        return {
          columnId: index,
          width: 100,
          justifyContent: "center",
        };
      }),
    ];
  }, [calculation]);

  const cumulativeYAxes = useMemo(() => {
    return [
      {
        name: `${dictionary.tahk.gasRate} ${dictionary.tableUnits.gasRate}`,
        type: "value",
        color: palette.error.main,
        min: 0,
        position: "left",
        offset: 0,
        nameGap: 30,
      },
      {
        name: `${dictionary.tahk.waterRate} ${dictionary.tableUnits.waterRate}`,
        type: "value",
        color: palette.info.main,
        min: 0,
        position: "right",
        offset: 5,
        nameGap: 45,
      },
    ];
  }, [palette.error.main, palette.info.main]);

  const cumulativeChartSeries = useMemo(() => {
    if (!calculation) return [];
    const curveModel: FossilyticsChartSeries[] = [];
    cumulativeSeriesKey.forEach((key) => {
      if (calculation.tahk_analysis_curve_model[camelToSnakeCase(key) as keyof TahkAnalysisChartData]) {
        curveModel.push({
          name: `${dictionary.tahk.model} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: calculation.tahk_analysis_curve_model
            ? calculation.tahk_analysis_curve_model.dates.map((d, i) => [
                d,
                calculation.tahk_analysis_curve_model?.[camelToSnakeCase(key) as keyof TahkAnalysisChartData]?.[i] ?? 0,
              ])
            : [],
          yAxisIndex: getYAxisIndex(key) - 1,
          hideSymbol: true,
          lineWidth: 2,
        });
      }
    });
    const curveData: FossilyticsChartSeries[] = [];
    cumulativeSeriesKey.forEach((key) => {
      if (calculation.tahk_analysis_curve_data[camelToSnakeCase(key) as keyof TahkAnalysisChartData]) {
        curveModel.push({
          name: `${dictionary.tahk.data} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: calculation.tahk_analysis_curve_data
            ? calculation.tahk_analysis_curve_data.dates.map((d, i) => [
                d,
                calculation.tahk_analysis_curve_data?.[camelToSnakeCase(key) as keyof TahkAnalysisChartData]?.[i] ?? 0,
              ])
            : [],
          yAxisIndex: getYAxisIndex(key) - 1,
        });
      }
    });

    return [...curveModel, ...curveData];
  }, [calculation, getSeriesColor]);

  useEffect(() => {
    const payload = {
      data_options: {
        analysis_identity: analysisIdentity,
      },
      options: tahkCsgState?.inputs,
    };

    // force calculate only if validation api have run to ensure everythign is up to date
    if (tabIndex === 2 && !isLoading && tahkCsgState?.inputs && !_.isEqual(payload, lastCalculationReq.current) && haveInitializeValidate.current)
      onCalculateAnalysis(tahkCsgState?.inputs);
  }, [analysisIdentity, calculation, isLoading, onCalculateAnalysis, tabIndex, tahkCsgState?.inputs]);

  const loadingStateAll = isLoading || loadingState;

  const transformDataTableToCsv = useCallback(() => {
    if (!analysisIdentity) return;
    const { data_set_ids } = analysisIdentity;

    const tableCsv = {
      fileName: `${data_set_ids.join(", ")} Analysis Tahk Table.csv`,
      data: transformRowToString(dataTableRows),
      isTableCsv: true,
    };
    setCsvData([tableCsv]);
  }, [analysisIdentity, dataTableRows, setCsvData]);

  useEffect(() => {
    if (analysisIdentity && !loadingStateAll && dataTableRows.length > 0) {
      transformDataTableToCsv();
    }
  }, [analysisIdentity, dataTableRows.length, loadingStateAll, transformDataTableToCsv]);

  const canClickHistoryMatch = useMemo(() => {
    if (!currentLayer?.data) return false;

    return (
      Object.values(currentLayer?.data)
        .map((data) => data?.selected_parameter)
        .filter((enabled) => !!enabled).length > 0
    );
  }, [currentLayer]);

  // validate + save every 30 seconds
  useInterval(async () => {
    if (!isLoadingValidation.current && tabIndex === 2) {
      onValidateAnalysis(latestState.current);
    }
  }, 30000);

  useEffect(() => {
    latestState.current = tahkCsgState;
  }, [tahkCsgState]);

  useEffect(() => {
    return () => {
      onValidateAnalysis(latestState.current);
    };
    // Disable this lint because this is unmounting hooks,
    // when unmount take the state from ref if its from the state, it disappear already
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // make sure to call validate to save changes before navigating to the other tab
  useEffect(() => {
    if (lastestTabIndex.current === 2 && tabIndex !== 2) {
      onValidateAnalysis(latestState.current);
    } else if (tabIndex === 2 && lastestTabIndex.current !== 2) {
      // make sure to validate when user go into the page too, update with the latest input data

      onValidateAnalysis(undefined, true);
    }
    lastestTabIndex.current = tabIndex;
  }, [onValidateAnalysis, tabIndex]);

  useEffect(() => {
    haveInitializeValidate.current = false;
  }, [tabIndex]);

  return {
    onClickHistoryMatch,
    layerOption,
    loadingState: loadingStateAll,
    selectedLayer,
    setSelectedLayer,
    currentLayer,
    onChangeAnalysisInput,
    analysis,
    onChangeAnalysisInputLayer,
    errorInputValidation,
    dataTableRows,
    chartXAxes,
    chartYAxes,
    chartSeries,
    calculation,
    parameterTableColumn,
    parameterTableRows,
    dataTableLayerOption,
    selectedDataTableLayer,
    setSelectedDataTableLayer,
    cumulativeYAxes,
    cumulativeChartSeries,
    canClickHistoryMatch,
    canCancelPoll,
    onCancelPoll,
  };
};

export default useTahkCsgAnalysis;
