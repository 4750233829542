import { useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import DialogMui from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";

import { useManualSettingState, useSettings } from "@/SettingsState";
import { convertDateToUtcTimeZoneIsoString } from "@/utils/dateTime";
import { DialogEnum } from "../types";
import { getIdFromActiveKey } from "../utils";
import { postSaveProjectSetting } from "@/models/settings";
import routeUrlMap from "@/constants/routeUrl";
import dictionary from "@/constants/dictionary";
import useThemeStyling from "@/utils/useThemeStyling";
import InputField from "@/components/fields/InputField";

interface ProjectSettingsDialogProps {
  setActiveDialog: (show: DialogEnum | undefined) => void;
  isLoading: boolean;
  selectedKey?: string;
}

const ProjectSettingsDialog = ({ setActiveDialog, isLoading, selectedKey }: ProjectSettingsDialogProps) => {
  const { projectId } = getIdFromActiveKey(selectedKey ?? "");
  const { palette } = useThemeStyling();

  const { setSetting } = useSettings();
  const [forecastStartDate] = useManualSettingState<string>({ key: "forecast_start_date", global: true, opts: {}, projectId });
  const [forecastEndDate] = useManualSettingState<string>({ key: "forecast_end_date", global: true, opts: {}, projectId });
  const [editForecastDate, setEditForecastDate] = useState(forecastStartDate);
  const [editForecastEndDate, setEditForecastEndDate] = useState(forecastEndDate);

  const isKoldunCSg = window.location.pathname === `/modules/${routeUrlMap.csgV2}`;

  useEffect(() => {
    setEditForecastDate(forecastStartDate);
    setEditForecastEndDate(forecastEndDate);
  }, [forecastStartDate, forecastEndDate]);

  const handleStartDateChange = (v: Date | undefined) => {
    setEditForecastDate(convertDateToUtcTimeZoneIsoString(v));
  };

  const handleEndDateChange = (v: Date | undefined) => {
    setEditForecastEndDate(convertDateToUtcTimeZoneIsoString(v));
  };

  const handleSave = async () => {
    try {
      await postSaveProjectSetting({
        project_id: projectId,
        data: {
          forecast_start_date: editForecastDate ?? null,
          forecast_end_date: editForecastEndDate ?? null,
        },
      });
      setActiveDialog(undefined);
      setSetting({ projectId, key: "forecast_start_date", value: editForecastDate ? new Date(editForecastDate) : "" });
      setSetting({ projectId, key: "forecast_end_date", value: editForecastEndDate ? new Date(editForecastEndDate) : "" });
    } catch (error) {
      console.error("Error occurred while saving:", error);
    }
  };

  const onCancelDialog = () => {
    if (isKoldunCSg) return;
    setActiveDialog(undefined);
    setEditForecastDate(forecastStartDate);
    setEditForecastEndDate(forecastEndDate);
  };
  const errorList = useMemo(() => {
    const list = [];

    if (editForecastDate && editForecastEndDate) {
      const d1 = DateTime.fromISO(editForecastDate);
      const d2 = DateTime.fromISO(editForecastEndDate);

      if (d1 > d2)
        list.push({
          msg: "Forecast start date must be before end date",
          loc: ["body", "startDate"],
          type: "validation",
          ctx: "",
        });
    }
    return list;
  }, [editForecastDate, editForecastEndDate]);

  const saveButtonDisabled = useMemo(() => {
    if (isKoldunCSg) {
      return isLoading || !editForecastEndDate;
    }

    return isLoading;
  }, [isLoading, editForecastEndDate, isKoldunCSg]);

  return (
    <DialogMui open keepMounted onClose={onCancelDialog}>
      <DialogTitle className="primaryColor">{"Project Settings"}</DialogTitle>
      <DialogContent
        style={{
          maxWidth: 350,
        }}
      >
        <DialogContentText>Edit project settings below. Click save when you are done.</DialogContentText>
        {isKoldunCSg && !editForecastEndDate && (
          <div
            style={{
              color: palette.error.main,
            }}
          >
            {dictionary.errorMessage.forecastEndDate}
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexFlow: "row",
            alignItems: "end",
          }}
        >
          <div style={{ flexGrow: 1, display: "flex" }}>
            <InputField
              dataTestId="forecast-start-date"
              outerStyle={{
                width: "100%",
              }}
              type="date"
              value={editForecastDate}
              label="Forecast start date"
              debounceDelay={100}
              onChange={(val) => handleStartDateChange(new Date(String(val)))}
              errors={errorList}
              keyField="startDate"
            />
          </div>
          <IconButton
            onClick={() => {
              handleStartDateChange(undefined);
            }}
            style={{ marginBottom: 6 }}
            aria-label="delete"
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div
          style={{
            display: "flex",
            flexFlow: "row",
            alignItems: "end",
          }}
        >
          <div style={{ flexGrow: 1, display: "flex" }}>
            <InputField
              dataTestId="forecast-end-date"
              outerStyle={{
                width: "100%",
              }}
              type="date"
              value={editForecastEndDate}
              label="Forecast end date"
              debounceDelay={100}
              onChange={(val) => handleEndDateChange(new Date(String(val)))}
            />
          </div>
          <IconButton
            onClick={() => {
              handleEndDateChange(undefined);
            }}
            style={{ marginBottom: 6 }}
            aria-label="delete"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="save-project-settings"
          style={{ color: "white", marginTop: 10, marginBottom: 10 }}
          variant="contained"
          disabled={saveButtonDisabled || errorList.length > 0}
          onClick={() => void handleSave()}
        >
          Save
        </Button>

        <Button disabled={isLoading || isKoldunCSg} onClick={onCancelDialog} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </DialogMui>
  );
};

export default ProjectSettingsDialog;
