import "@fontsource/baloo-2";
import "@silevis/reactgrid/styles.css";
import "./index.css";

import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { StyledEngineProvider } from "@mui/material";

import reportWebVitals from "./reportWebVitals";

import App from "./App";
import ErrorBoundaryWrapper from "./components/ErrorBoundary";
import { APIName } from "@/constants/apiUrl";
import UserPreferenceProvider from "./features/settings/UserPreference/context";

Amplify.configure({
  API: {
    endpoints: [
      {
        name: APIName,
        endpoint: process.env.REACT_APP_API_URL,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` };
        },
      },
    ],
  },
});

// Create React app
const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(
  // TODO: Add React.StrictMode when Fluent UI receives update for v18!
  <StyledEngineProvider injectFirst>
    <UserPreferenceProvider>
      <ErrorBoundaryWrapper>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ErrorBoundaryWrapper>
    </UserPreferenceProvider>
  </StyledEngineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
