import { ModuleId } from "@/model";

export type HelpLinks = {
  [key: string]: HelpLink;
};

export type HelpLink = {
  name: string;
  code: string;
};

export const userManualDom = "https://predico.scrollhelp.site/afaum/";

const input: { [key: string]: string } = {
  wellboreInput: `${userManualDom}pump-model-wellbore`,
  geomechanics: `${userManualDom}geomechanics`,
  isotherm: `${userManualDom}isotherm`,
  formationCompressibility: `${userManualDom}rock-formation-compressibility`,
  porosity: `${userManualDom}porosity`,
  netPay: `${userManualDom}net-pay`,
  permeability: `${userManualDom}csg-permeability-and-permeability-anisotropy`,
  gasPvt: `${userManualDom}gas-pvt-correlations`,
  permeabilityXAxis: `${userManualDom}relative-permeability`,
  permeabilityYAxis: `${userManualDom}relative-permeability`,
  permeabilityZAxis: `${userManualDom}relative-permeability`,
  coalDensity: `${userManualDom}coal-density`,
  underSaturation: `${userManualDom}undersaturation-dewatering`,

  specificGravity: `${userManualDom}gas-specific-gravity`,
  selectedZCorrelation: `${userManualDom}gas-deviation-factor`,
  selectedGasViscosityCorrelation: `${userManualDom}gas-viscosity`,
  langmuirPressure: `${userManualDom}isotherm`,
  langmuirVolume: `${userManualDom}isotherm`,
  rockDensity: `${userManualDom}coal-density`,
  populationSize: `${userManualDom}population-size`,
  maxIterationNumber: `${userManualDom}maximum-iteration-number`,
};

const helpLinkUrl = {
  input,
  wellImport: `${userManualDom}import-wells-from-csv`,
  projectImport: `${userManualDom}import-projects`,
  errorCode: `${userManualDom}error-messages#`,
  machineLearning: `${userManualDom}tsar-machine-learning-m-l-capability`,
};

export const MODULE_SPECIFIC_LINKS: ModuleId[] = [ModuleId.KOLDUN_MCSIM_CSG];

export const OPERATIONAL_HELP_LINKS: HelpLinks = {
  index: {
    name: "Operational Manual",
    code: "user-manual",
  },
  [ModuleId.KOLDUN_MCSIM_CSG]: {
    name: "KOLDUN: CSG Monte-Carlo",
    code: "koldun-csg-monte-carlo",
  },
};

export const THEORETICAL_HELP_LINKS: HelpLinks = {
  index: {
    name: "Theory & Reference Manual",
    code: "theory-reference-manual",
  },
  pssModels: {
    name: "PSS Models",
    code: "pss-models",
  },
  gasDeviationFactor: {
    name: "Gas Deviation Factor",
    code: "gas-deviation-factor",
  },
  gasViscosity: {
    name: "Gas Viscosity",
    code: "gas-viscosity",
  },
  gasPvtCorrelations: {
    name: "Gas PVT Correlations",
    code: "gas-pvt-correlations",
  },
  csgWellSpacingAndCompletions: {
    name: "CSG Well Spacing & Completions",
    code: "csg-well-spacing-completions",
  },
  relativePermeability: {
    name: "Relative Permeability",
    code: "relative-permeability",
  },
  isotherm: {
    name: "Isotherm",
    code: "isotherm",
  },
  coalDensity: {
    name: "Coal Density",
    code: "coal-density",
  },
  cleatPorosity: {
    name: "Cleat Porosity",
    code: "cleat-porosity",
  },
  wellboreSkinAndFormationDamage: {
    name: "Wellbore Skin and Formation Damage",
    code: "wellbore-skin-and-formation-damage",
  },
};

export default helpLinkUrl;
