import { GasViscosityMethod, WaterCompressibility, ZMethod } from "@/model";
import InputField from "@/components/fields/InputField";
import DropdownField from "@/components/fields/DropdownField";

import { gasViscMethodOptions } from "@/modules/koldun/mcsim/gasoil/ModuleKoldunMcsimGasOilContent/Components/ModuleKoldunMcsimGasOilInputGridTab/Components/model";

import { ModuleGazFmbPvtOptions } from "./model";
import { formationCompressibilityOptions } from "./constants";
import { THEORETICAL_HELP_LINKS } from "@/constants/helpLinkUrl";
import { generateWikiLink } from "@/features/help-link/help-link.utils";

interface ModuleGazFmbInputGridProps {
  value: ModuleGazFmbPvtOptions | undefined;
  onChange: (value: ModuleGazFmbPvtOptions) => void;
  handleInputNotChanged: () => void;
}

const waterCompressibilityOptions = [{ key: WaterCompressibility.BEGGS_BRILL, text: "Beggs/Brill" }];

const zMethodOptions = [
  { key: ZMethod.DPR, text: "DPR (default)" },
  { key: ZMethod.BEGGS_BRILL, text: "Beggs/Brill" },
  { key: ZMethod.PAPAY, text: "Papay (simple)" },
  { key: ZMethod.DRANCHUCK_ABU_KASSEM, text: "Dranchuck/Abu-Kassem" },
];

function ModuleGazFmbInputGrid({ value, onChange, handleInputNotChanged }: Readonly<ModuleGazFmbInputGridProps>) {
  if (!value) {
    return <></>;
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridGap: 25,
      }}
    >
      <div
        className="equal-size"
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <InputField
          label="Initial pressure"
          suffix="psia"
          value={value.initial_pressure_psia}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  initial_pressure_psia: Number(v),
                })
              : null;
          }}
        />
        <InputField
          label="Formation temperature"
          suffix="°F"
          value={value.formation_temp_of}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  formation_temp_of: Number(v),
                })
              : null;
          }}
        />
        <InputField
          label="Net pay"
          suffix="ft"
          value={value.net_pay_ft}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  net_pay_ft: Number(v),
                })
              : null;
          }}
        />
        <InputField
          label="Wellbore radius"
          suffix="ft"
          value={value.wellbore_rad_ft}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  wellbore_rad_ft: Number(v),
                })
              : null;
          }}
        />
      </div>

      <div
        className="equal-size"
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <InputField
          label="Gas gravity"
          suffix="g"
          value={value.gas_gravity}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  gas_gravity: Number(v),
                })
              : null;
          }}
        />
        <InputField
          label="N₂"
          suffix="%"
          min={0}
          max={100}
          value={value.n2_perc}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  n2_perc: Number(v),
                })
              : null;
          }}
        />
        <InputField
          label="CO₂"
          suffix="%"
          min={0}
          max={100}
          value={value.co2_perc}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  co2_perc: Number(v),
                })
              : null;
          }}
        />
        <InputField
          label="H₂S"
          suffix="%"
          min={0}
          max={100}
          value={value.h2s_perc}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  h2s_perc: Number(v),
                })
              : null;
          }}
        />
      </div>

      <div
        className="equal-size"
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
        }}
      >
        <InputField
          label="Water saturation"
          suffix="%"
          min={0}
          max={100}
          value={value.water_sat_perc}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  water_sat_perc: Number(v),
                })
              : null;
          }}
        />
        <InputField
          label="Porosity"
          suffix="%"
          min={0}
          max={100}
          value={value.porosity_perc}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  porosity_perc: Number(v),
                })
              : null;
          }}
        />

        <DropdownField
          style={{
            marginTop: 0,
          }}
          label="Formation compressibility (psi⁻¹)"
          options={formationCompressibilityOptions}
          selectedKey={`${value.formation_compressibility_psi1}-${value.formation_rock_type}`}
          onChange={(v) => {
            handleInputNotChanged();
            const data = formationCompressibilityOptions.filter((key) => key.key === v);
            if (data?.[0]?.data) {
              onChange({
                ...value,
                ...data[0].data,
              });
            }
          }}
        />
        <DropdownField
          label="Water compressibility (psi⁻¹)"
          options={waterCompressibilityOptions}
          selectedKey={value.water_compressibility_psi1}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  water_compressibility_psi1: v as WaterCompressibility,
                })
              : null;
          }}
        />
        <DropdownField
          label="Z Method"
          helpUrl={generateWikiLink(THEORETICAL_HELP_LINKS.gasDeviationFactor)}
          options={zMethodOptions}
          selectedKey={value.z_method}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  z_method: v as ZMethod,
                })
              : null;
          }}
        />
        <DropdownField
          label="Gas viscosity method"
          helpUrl={generateWikiLink(THEORETICAL_HELP_LINKS.gasViscosity)}
          options={gasViscMethodOptions}
          selectedKey={value.gas_viscosity_method}
          onChange={(v) => {
            handleInputNotChanged();
            return v !== undefined
              ? onChange({
                  ...value,
                  gas_viscosity_method: v as GasViscosityMethod,
                })
              : null;
          }}
        />
      </div>
    </div>
  );
}

export default ModuleGazFmbInputGrid;
