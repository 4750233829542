import React, { useMemo, useState } from "react";
import chroma from "chroma-js";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { ModuleGazFmbPvt } from "./model";

import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { DataResponse, DataSet } from "@/model";

import useThemeStyling from "@/utils/useThemeStyling";
import Tabs from "@/components/Tabs";

interface ModuleGazFmbInputGridPlotsProps {
  isLoading: boolean;
  dataSet: DataSet;
  cleaned: DataResponse | undefined;
  pvt: ModuleGazFmbPvt | undefined;
}

function ModuleGazFmbInputGridPlots({ isLoading, dataSet, cleaned, pvt }: ModuleGazFmbInputGridPlotsProps) {
  const [enabled, setEnabled] = useState(false);
  const { palette } = useThemeStyling();

  const xAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: "Pressure (psia)", type: "value", color: palette.customColor.black }],
    [palette.customColor.black]
  );

  const zGasYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: "Z Gas", type: "value", color: palette.customColor.green }],
    [palette.customColor.green]
  );

  const zGasSeries = useMemo<FossilyticsChartSeries[]>(
    () => [{ name: "Z Gas", type: "line", hideSymbol: true, color: palette.customColor.green, data: pvt ? pvt.z_table : [] }],
    [palette.customColor.green, pvt]
  );

  const gasDensYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: "Density (g/cm³)", type: "value", color: palette.customColor.black }],
    [palette.customColor.black]
  );

  const gasDensSeries = useMemo<FossilyticsChartSeries[]>(() => {
    let data: number[][] = [];
    if (pvt?.wellbore) {
      data = pvt.wellbore.pressures
        .map((v, i) => [i, v])
        .sort((a, b) => b[1] - a[1])
        .map(([i, v]) => [v, pvt.wellbore!.gas_densities[i]]);
    }
    return [
      {
        name: "Density",
        type: "line",
        hideSymbol: true,
        color: palette.customColor.black,
        data: data,
      },
    ];
  }, [pvt?.wellbore, palette.customColor.black]);

  const gasViscYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: "Viscosity (cp)", type: "value", color: palette.customColor.black }],
    [palette.customColor.black]
  );

  const gasViscSeries = useMemo<FossilyticsChartSeries[]>(() => {
    let data: number[][] = [];
    if (pvt?.wellbore) {
      data = pvt.wellbore.pressures
        .map((v, i) => [i, v])
        .sort((a, b) => b[1] - a[1])
        .map(([i, v]) => [v, pvt.wellbore!.gas_viscosities[i]]);
    }
    return [
      {
        name: "Viscosity",
        type: "line",
        hideSymbol: true,
        color: palette.customColor.black,
        data: data,
      },
    ];
  }, [pvt?.wellbore, palette.customColor.black]);

  const gasFvfYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: "Gas formation value factor", type: "value", color: palette.customColor.black }],
    [palette.customColor.black]
  );
  const gasFvfSeries = useMemo<FossilyticsChartSeries[]>(() => {
    let data: number[][] = [];
    if (pvt?.wellbore) {
      data = pvt.wellbore.pressures
        .map((v, i) => [i, v])
        .sort((a, b) => b[1] - a[1])
        .map(([i, v]) => [v, pvt.wellbore!.gas_fvfs[i]]);
    }
    return [
      {
        name: "Gas formation value factor",
        type: "line",
        hideSymbol: true,
        color: palette.customColor.black,
        data: data,
      },
    ];
  }, [pvt, palette]);

  const cmpXAxes = useMemo<FossilyticsChartAxis[]>(() => [{ name: "Date", type: "time", color: palette.customColor.black }], [palette]);
  const cmpYAxes = useMemo<FossilyticsChartAxis[]>(() => [{ name: "Pressure (psia)", type: "value", color: palette.customColor.black }], [palette]);
  const cmpSeries = useMemo<FossilyticsChartSeries[]>(
    () => [
      {
        name: "Sandface pressures",
        type: "scatter",
        color: palette.customColor.black,
        data: pvt?.wellbore
          ? Array.from(Array(pvt.wellbore.pressures.length).keys()).map((i) => [pvt.wellbore!.date_times[i] as any, pvt.wellbore!.pressures[i]])
          : [],
      },
      {
        name: "Wellhead pressures",
        type: "scatter",
        color: chroma(palette.customColor.black).alpha(0.3).hex(),
        data: pvt?.wellbore
          ? Array.from(Array(pvt.wellbore.pressures.length).keys()).map((i) => [pvt.wellbore!.date_times[i] as any, pvt.wellbore!.pressures_init[i]])
          : [],
      },
    ],
    [palette, pvt]
  );

  const tabList = useMemo(() => {
    return [
      {
        label: <span>Z Gas </span>,
        content: (
          <div style={{ height: 400 }}>
            <FossilyticsChart id="gaz_fmb_z_gas" isLoading={isLoading} blockSizing xAxes={xAxes} yAxes={zGasYAxes} series={zGasSeries} />
          </div>
        ),
        key: "Z Gas",
      },
      {
        label: <span>Gas density</span>,
        content: (
          <div style={{ height: 400 }}>
            <FossilyticsChart id="gaz_fmb_gas_dens" isLoading={isLoading} blockSizing xAxes={xAxes} yAxes={gasDensYAxes} series={gasDensSeries} />
          </div>
        ),
        key: "Gas density",
      },
      {
        label: <span>Gas viscosity</span>,
        content: (
          <div style={{ height: 400 }}>
            <FossilyticsChart id="gaz_fmb_gas_vis" isLoading={isLoading} blockSizing xAxes={xAxes} yAxes={gasViscYAxes} series={gasViscSeries} />
          </div>
        ),
        key: "Gas viscosity",
      },
      {
        label: <span>Gas formation volume factor</span>,
        content: (
          <div style={{ height: 400 }}>
            <FossilyticsChart id="gaz_fmb_gas_form" isLoading={isLoading} blockSizing xAxes={xAxes} yAxes={gasFvfYAxes} series={gasFvfSeries} />
          </div>
        ),
        key: "Gas formation volume factor",
      },
      {
        label: <span>Sandface vs wellhead pressures</span>,
        content: (
          <div style={{ height: 400 }}>
            <FossilyticsChart id="gaz_fmb_sand_well" isLoading={isLoading} blockSizing xAxes={cmpXAxes} yAxes={cmpYAxes} series={cmpSeries} />
          </div>
        ),
        key: "Sandface vs wellhead pressures",
      },
    ];
  }, [
    cmpSeries,
    cmpXAxes,
    cmpYAxes,
    gasDensSeries,
    gasDensYAxes,
    gasFvfSeries,
    gasFvfYAxes,
    gasViscSeries,
    gasViscYAxes,
    isLoading,
    xAxes,
    zGasSeries,
    zGasYAxes,
  ]);

  return (
    <div>
      <FormControlLabel
        control={<Checkbox onChange={(e) => setEnabled(!!e.target.checked)} checked={enabled} size="small" />}
        label="PVT & Wellbore plots"
      />
      {enabled && <Tabs centered tabList={tabList} />}
    </div>
  );
}

export default ModuleGazFmbInputGridPlots;
