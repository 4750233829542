import dictionary from "./dictionary";

export const gasPvtBase = {
  specificGravity: dictionary.tahk.specificGravity,
  nitrogen: dictionary.tahk.nitrogen,
  carbonDioxide: dictionary.tahk.carbonDioxide,
  hydrogenSulphide: dictionary.tahk.hydrogenSulphide,
  selectedZCorrelation: dictionary.tahk.selectedZCorrelation,
  selectedGasViscosityCorrelation: dictionary.tahk.selectedGasViscosityCorrelation,
};

export const gasPvtWidthBase = {
  specificGravity: 150,
  nitrogen: 60,
  carbonDioxide: 60,
  hydrogenSulphide: 60,
  selectedZCorrelation: 180,
  selectedGasViscosityCorrelation: 180,
};

export const waterPvtBase = {
  salinity: dictionary.tahk.salinity,
  selectedWaterCompressibilityCorrelation: dictionary.tahk.selectedWaterCompressibilityCorrelation,
  selectedWaterViscosityCorrelation: dictionary.tahk.selectedWaterViscosityCorrelation,
};

export const relativePermeabilityBase = {
  selectedRelativePermeabilityCorrelation: {
    label: dictionary.tahk.selectedRelativePermeabilityCorrelation,
  },
  krgMax: {
    label: dictionary.tahk.krgMax,
    sub: "rg | Max",
  },
  krwMax: {
    label: dictionary.tahk.krwMax,
    sub: "rw | Max",
  },
  ng: {
    label: dictionary.tahk.ng,
    sub: "g",
  },
  nw: {
    label: dictionary.tahk.nw,
    sub: "w",
  },
  lamb: {
    label: dictionary.tahk.lamb,
  },
  n: {
    label: dictionary.tahk.n,
  },
  connateWaterSaturation: {
    label: dictionary.tahk.connateWaterSaturation,
    sub: "w,irr",
  },
  residualGasSaturation: {
    label: dictionary.tahk.residualGasSaturation,
    sub: "g,r",
  },
};

export const relativePermeabilityBaseWidth = {
  selectedRelativePermeabilityCorrelation: 150,
  krgMax: 80,
  krwMax: 80,
  ng: 80,
  nw: 80,
  lamb: 80,
  n: 80,
  connateWaterSaturation: 80,
  residualGasSaturation: 80,
};

export const wellInputAreaBase = {
  wellboreRadius: dictionary.tahk.wellboreRadius,
  selectedWellType: dictionary.tahk.selectedWellType,
  fractureHalfLength: dictionary.tahk.fractureHalfLength,
  fractureConductivity: dictionary.tahk.fractureConductivity,
  horizontalLength: dictionary.tahk.horizontalLength,
  selectedSkinModel: dictionary.tahk.selectedSkinModel,
};

export const geomechanicBase = {
  selectedGeomechanicModel: dictionary.tahk.selectedGeomechanicModel,
  poissonsRatio: dictionary.tahk.poissonsRatio,
  grainCompressibility: dictionary.tahk.grainCompressibility,
  langmuirVolumeStrain: dictionary.tahk.langmuirVolumeStrain,
  youngModule: dictionary.tahk.youngModule,
  shrinkageExponent: dictionary.tahk.shrinkageExponent,
  shrinkageFactor: dictionary.tahk.shrinkageFactor,
  maximumProductivityMultiplier: dictionary.tahk.maximumProductivityMultiplier,
  permeabilityModulus: dictionary.tahk.permeabilityModulus,
};

export const geomechanicHeaderWithLink = ["selectedGeomechanicModel", "poissonsRatio", "langmuirVolumeStrain", "youngModule"];
