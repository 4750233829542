import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { calculateRullon } from "@/constants/apiUrl";
import dictionary from "@/constants/dictionary";

import { ModuleId, Project } from "@/model";
import { ApiError } from "@/models/APIGeneric";
import { BatchTask, BatchWell, BatchWellProgress } from "@/models/tasks";
import { getBatchTasks } from "@/models/tasks/apiFetcher.ts/getTasks";

import { usePolling } from "@/utils/apiFetcher";
import { parseErrorThrown } from "@/utils/errorHandling";
import { Row } from "@silevis/reactgrid";
import { tableCellStyle, tableHeaderStyle } from "@/components/CustomTable";

type UseBatchQueueProps = {
  setApiError: (error?: ApiError) => void;
  apiError?: ApiError;
  setIsLoading: (isLoading: boolean) => void;
  setProgress: (progress: number | null) => void;
  setPollStatus: (status?: string) => void;
  project?: Project;
};

const dataTableCol = [
  { columnId: "data_set_id", width: 275 },
  { columnId: "module", width: 350 },
  { columnId: "progress", width: 300 },
];

const safeDic = dictionary.rulon as { [key: string]: string };

const useBatchQueue = ({ setApiError, apiError, setIsLoading, setProgress, setPollStatus, project }: UseBatchQueueProps) => {
  const [groupRunQueue, setGroupRunQueue] = useState<BatchWellProgress>();
  const [groupRunFinished, setGroupRunFinished] = useState(false);

  const [showGroupRun, setShowGroupRun] = useState(false);

  const [canCancel, setCanCancel] = useState(false);

  const lastProjectId = useRef<string>("");

  const { continuePollRequest, onCancelPoll } = usePolling({
    setApiError,
    setLoadingState: setIsLoading,
    setProgressStatus: (val) => {
      setProgress(val.progress ?? null);
      setPollStatus(val.pollStatus);
    },
    apiError,
    setInfoContinuous: (value: { task_status: string; task_info: BatchWellProgress }) => {
      setGroupRunQueue(value.task_info);
      setCanCancel(value.task_status === "STARTED");
    },
  });

  const continueGroupRunPoll = useCallback(
    async (task: BatchTask) => {
      try {
        // reset all state
        setCanCancel(false);
        setGroupRunFinished(false);
        setGroupRunQueue(undefined);
        setShowGroupRun(true);
        const res = await continuePollRequest({
          path: calculateRullon(task.project_id),
          taskId: task.task_id,
          withTaskInfo: true,
        });
        if (res.task_status === "SUCCESS") {
          setGroupRunFinished(true);

          setCanCancel(false);
          setShowGroupRun(false);

          setApiError({
            severity: "success",
            code: 0,
            message: dictionary.rulon.successGroupRun,
          });

          setTimeout(() => {
            setApiError();
          }, 5000);
        }
      } catch (error) {
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    [apiError, continuePollRequest, setApiError]
  );

  // will trigger everytime user select project / group
  const callGetBatchQueue = useCallback(async () => {
    try {
      setShowGroupRun(false);
      const res = await getBatchTasks();
      if (res.data && project?.id) {
        // find the batch with the project id && module is group run
        const batch = res.data?.find((task) => task.project_id === project?.id && task.module === ModuleId.RULON);

        // only continue poll for group run if the project id match
        if (batch) {
          continueGroupRunPoll(batch);
        }
      }
    } catch (error) {
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
    }
  }, [apiError, continueGroupRunPoll, project?.id, setApiError]);

  useEffect(() => {
    if (lastProjectId.current === project?.id || !project?.id) return;
    callGetBatchQueue();
    lastProjectId.current = project?.id;
  }, [callGetBatchQueue, project?.id]);

  const dataTableRows: Row<any>[] = useMemo(() => {
    const headerRow = {
      rowId: "header",
      cells: dataTableCol.map((key) => {
        return {
          type: "text",
          text: safeDic[String(key.columnId)],
          style: tableHeaderStyle,
        };
      }),
    };
    if (!groupRunQueue?.wells) return [headerRow];

    const resultRow = groupRunQueue.wells.reduce((res: Row<any>[], curr: BatchWell) => {
      curr.modules.forEach((moduleKey) => {
        res.push({
          rowId: `${curr.data_set_id}-${moduleKey}`,
          cells: [
            {
              type: "text",
              text: curr.data_set_id,
              style: tableCellStyle,
            },
            {
              type: "text",
              text: moduleKey.replaceAll("_", " "),
              style: tableCellStyle,
            },
            {
              type: "text",
              text: `${curr.module_progress?.[moduleKey] ?? "0"} %`,
              style: tableCellStyle,
            },
          ],
        });
      });
      return res;
    }, [] as Row<any>[]);

    return [headerRow, ...resultRow];
  }, [groupRunQueue?.wells]);

  const onClickOk = useCallback(() => {
    setGroupRunQueue(undefined);
    setShowGroupRun(false);
  }, []);

  return {
    onCancelPoll,
    canCancel,
    groupRunFinished,
    dataTableCol,
    dataTableRows,
    groupRunQueue,
    onClickOk,
    showGroupRun,
  };
};

export default useBatchQueue;
