import { useState, useCallback, useMemo } from "react";
import { CellChange, DateCell, DropdownCell, Row } from "@silevis/reactgrid";
import _ from "lodash";

import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";

// constant

import dictionary from "@/constants/dictionary";
import { autoRtaForecast } from "@/constants/apiUrl";
import {
  autoRtaForecastEventHeader,
  autoRtaForecastEventNotation,
  forecastGasDataTableHeader,
  forecastOilDataTableHeader,
  forecastGasDataTableNotation,
  forecastOilDataTableNotation,
  fieldGasDataTableHeader,
  fieldGasDataTableNotation,
  fieldOilDataTableHeader,
  fieldOilDataTableNotation,
} from "../constants";

import { AutoRtaFieldData, AutoRtaForecastCalculationV2, AutoRtaForecastRes, autoRtaForecastResScheme } from "@/models/gaz/autoRta";
import { AutoRtaForecastPayload } from "@/models/gaz/autoRta/State";

// utils
import useThemeStyling from "@/utils/useThemeStyling";
import { usePolling } from "@/utils/apiFetcher";

import { useAutoRtaState } from "./AutoRtaContext";
import { tableCellStyle, tableHeaderStyle } from "@/components/CustomTable";
import { capitalizeFirstLetter, formatToScientific } from "@/utils/general";
import { FluidType } from "@/models/Generic";
import { convertDateToUtcTimeZoneIsoString } from "@/utils/dateTime";

export type AutoRtaForecastProps = {
  currentTab: number;
  isLoading: boolean;
};

const dropdownOption = [
  {
    value: "Rate",
    label: "Rate",
  },
  {
    value: "Pressure",
    label: "Pressure",
  },
];

const useAutoRtaForecast = () => {
  const {
    autoRtaState,
    setAutoRtaState,
    apiError,
    dataSet,
    project,
    isLoading,
    setApiError,
    setValidationError,
    setIsLoading,
    setPollStatus,
    setProgress,
    fluidType,
    validationError,
  } = useAutoRtaState();
  const [isDropdownOpened, setIsDropdownOpened] = useState<number>(0);
  const [autoRtaForecastCalculation, setAutoRtaForecastCalculation] = useState<AutoRtaForecastCalculationV2>();
  const [autoRtaFieldData, setAutoRtaFieldData] = useState<AutoRtaFieldData>();

  const { palette } = useThemeStyling();

  const { createPoll, canCancelPoll, onCancelPoll } = usePolling({
    setApiError,
    setErrorValidation: setValidationError,
    setLoadingState: setIsLoading,
    setProgressStatus: (val) => {
      setProgress(val.progress ?? null);
      setPollStatus(val.pollStatus);
    },
    apiError,
  });

  const seriesAxisColor = useMemo(() => {
    if (fluidType === FluidType.gas) return palette.customColor.red;
    return palette.customColor.green;
  }, [fluidType, palette.customColor.green, palette.customColor.red]);

  // forecase input and setting part
  const forecastEventsRow = useMemo(() => {
    if (!autoRtaState?.forecast?.forecast_events) return [];

    const style = {
      backgroundColor: autoRtaState?.forecast.smart_fill ? "rgba(128, 128, 128, 0.1)" : "white",
      cursor: autoRtaState?.forecast.smart_fill ? "not-allowed" : "auto",
    };

    // combine date array from backend with the frontend empty array
    const combinedDateArray = [...autoRtaState.forecast.forecast_events.dates, ...Array(100).fill(undefined)];
    const constantInputsRows = [
      autoRtaForecastEventHeader,
      autoRtaForecastEventNotation(fluidType),
      ...(combinedDateArray.map((date, i) => {
        const isRate = autoRtaState.forecast.forecast_events.bound_condition_is_rate?.[i];

        let rateSelectedVal: any = isRate ? "Rate" : "Pressure";
        if (String(isRate) === "undefined") rateSelectedVal = undefined;
        return {
          rowId: i + 1,
          cells: [
            {
              type: "date",
              date: date ? new Date(date) : undefined,
              hideZero: true,
              nonEditable: autoRtaState?.forecast.smart_fill || isLoading,
              style,
            },
            {
              type: "number",
              value: autoRtaState.forecast.forecast_events.flowing_rate?.[i] ?? NaN,
              nonEditable: autoRtaState?.forecast.smart_fill || isLoading,
              style,
            },
            {
              type: "number",
              value: autoRtaState.forecast.forecast_events.flowing_pressure?.[i] ?? NaN,
              nonEditable: autoRtaState?.forecast.smart_fill || isLoading,
              style,
            },
            {
              type: "dropdown",
              selectedValue: rateSelectedVal,
              values: dropdownOption,
              isOpen: isDropdownOpened === i + 1,
              nonEditable: autoRtaState?.forecast.smart_fill || isLoading,

              style: {
                ...style,
                color: isRate ? palette.success.main : palette.warning.main,
              },
            },
          ],
        };
      }) ?? []),
    ] as Row[];

    return constantInputsRows;
  }, [
    autoRtaState?.forecast.forecast_events,
    isDropdownOpened,
    autoRtaState?.forecast.smart_fill,
    isLoading,
    palette.success.main,
    palette.warning.main,
    fluidType,
  ]);

  const onChangeForecastInput = (updatedInput: { [key: string]: any }) => {
    setValidationError([]);
    setAutoRtaState((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        forecast: {
          ...prev.forecast,
          ...updatedInput,
        },
      };
    });
  };

  const onChangeForecastInputTable = useCallback(
    (changes: CellChange[]) => {
      setValidationError([]);
      let haveChange = false;
      // Clone the original data to avoid mutation
      const newForecastFlowingEvent: any = _.cloneDeep(autoRtaState?.forecast.forecast_events);

      // Apply the changes to the cloned data
      for (const element of changes) {
        let { rowId, columnId, newCell, type, previousCell } = element as CellChange<any>;
        rowId = rowId as number;
        columnId = columnId as string;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        if (type === "dropdown") {
          const newDropdown = dropDownNewCell.isOpen ? rowId : 0;
          if (prevCell.isOpen !== dropDownNewCell.isOpen && newDropdown !== isDropdownOpened) {
            setIsDropdownOpened(newDropdown);
          } else {
            setIsDropdownOpened(0);
          }
          if (dropDownNewCell.isOpen) return;
          if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
            haveChange = true;
            newForecastFlowingEvent.bound_condition_is_rate[rowId - 1] = dropDownNewCell.selectedValue === "Rate";
          }
        } else {
          let val = newCell.value ?? newCell.text ?? 0;
          if (columnId === "dates") {
            const dateCell = newCell as DateCell;
            val = convertDateToUtcTimeZoneIsoString(new Date(dateCell.date ?? 0)) ?? "";
          }
          newForecastFlowingEvent[columnId][rowId - 1] = val;
          haveChange = true;
        }
      }

      if (haveChange) {
        setAutoRtaState((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            forecast: {
              ...prev.forecast,
              forecast_events: newForecastFlowingEvent,
            },
          };
        });
      }
    },
    [autoRtaState?.forecast.forecast_events, isDropdownOpened, setAutoRtaState, setValidationError]
  );

  const onClickCalculateForecast = useCallback(async () => {
    if (!project?.id || !dataSet || !autoRtaState?.analysis || !autoRtaState?.inputs || !autoRtaState?.forecast) return;
    try {
      const response = await createPoll<AutoRtaForecastRes, AutoRtaForecastPayload>({
        path: autoRtaForecast(project?.id, fluidType),
        body: {
          data_set_ids: dataSet.map((data) => data.id),
          analysis: autoRtaState.analysis,
          inputs: autoRtaState.inputs,
          forecast: autoRtaState.forecast,
        },
        withTaskInfo: true,
        type: "post",
      });
      if (response.task_result) {
        const parsed = autoRtaForecastResScheme.parse(response.task_result);
        setAutoRtaForecastCalculation(parsed.forecast_result);
        setAutoRtaFieldData(parsed.field_data);
        if (!_.isEqual(parsed.forecast_inputs, autoRtaState?.forecast)) {
          setAutoRtaState((prev) => {
            if (!prev) return prev;
            return {
              ...prev,
              forecast: parsed.forecast_inputs,
            };
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [autoRtaState, createPoll, dataSet, fluidType, project?.id, setAutoRtaState]);

  const forecastCalculationDataTableRow = useMemo(() => {
    if (!autoRtaForecastCalculation?.data) return [];
    const headerList = fluidType === FluidType.gas ? forecastGasDataTableHeader : forecastOilDataTableHeader;
    const notationList = fluidType === FluidType.gas ? forecastGasDataTableNotation : forecastOilDataTableNotation;

    const safeCalc: any = autoRtaForecastCalculation.data;
    const constantInputsRows = [
      {
        rowId: "header",
        height: 60,
        cells: headerList.map((head: string) => {
          return {
            type: "header",
            text: capitalizeFirstLetter(head.replaceAll("_", " ")),
            style: tableHeaderStyle,
          };
        }),
      },
      {
        rowId: "notation",
        cells: notationList.map((head: string) => {
          return {
            type: "header",
            style: tableCellStyle,
            text: head,
          };
        }),
      },
      ...autoRtaForecastCalculation.data.dates.map((_, index) => {
        return {
          key: index,
          rowId: index + 1,
          cells: headerList.map((head) => {
            let text = safeCalc[head][index];
            if (head === "dates") {
              text = `${new Intl.DateTimeFormat("en-US").format(new Date(text))}`;
            } else {
              text = formatToScientific(text);
            }
            return {
              type: "text",
              text,
              nonEditable: true,
              style: tableCellStyle,
            };
          }),
        };
      }),
    ] as Row[];
    return constantInputsRows;
  }, [autoRtaForecastCalculation?.data, fluidType]);

  const forecastCalculationDataTableCol = useMemo(() => {
    const headerList = fluidType === FluidType.gas ? forecastGasDataTableHeader : forecastOilDataTableHeader;

    return headerList.map((header) => {
      return {
        columnId: header,
      };
    });
  }, [fluidType]);

  const fieldDataRow = useMemo(() => {
    if (!autoRtaFieldData) return [];
    const headerList = fluidType === FluidType.gas ? fieldGasDataTableHeader : fieldOilDataTableHeader;
    const notationList = fluidType === FluidType.gas ? fieldGasDataTableNotation : fieldOilDataTableNotation;

    const safeCalc: any = autoRtaFieldData;

    const constantInputsRows = [
      {
        rowId: "header",
        height: 60,
        cells: headerList.map((head: string) => {
          return {
            type: "header",
            text: capitalizeFirstLetter(head.replaceAll("_", " ")),
            style: tableHeaderStyle,
          };
        }),
      },
      {
        rowId: "notation",
        cells: notationList.map((head: string) => {
          return {
            type: "header",
            style: tableCellStyle,
            text: head,
          };
        }),
      },
      ...autoRtaFieldData.dates.map((_, index) => {
        return {
          key: index,
          rowId: index + 1,
          cells: headerList.map((head) => {
            let text = safeCalc[head][index];
            if (head === "dates") {
              text = `${new Intl.DateTimeFormat("en-US").format(new Date(text))}`;
            } else {
              text = formatToScientific(text);
            }
            return {
              type: "text",
              text,
              nonEditable: true,
              style: tableCellStyle,
            };
          }),
        };
      }),
    ] as Row[];
    return constantInputsRows;
  }, [autoRtaFieldData, fluidType]);

  const fieldDataCol = useMemo(() => {
    const headerList = fluidType === FluidType.gas ? fieldGasDataTableHeader : fieldOilDataTableHeader;

    return headerList.map((header) => {
      return {
        columnId: header,
      };
    });
  }, [fluidType]);

  const forecastXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: "Date", type: "time", color: palette.customColor.black }],
    [palette.customColor.black]
  );

  const forecastYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      { name: dictionary.autoRta[`${fluidType}Rate`], type: "value", color: seriesAxisColor },
      { name: dictionary.autoRta.pressure, type: "value", color: palette.customColor.black },
    ],
    [fluidType, palette.customColor.black, seriesAxisColor]
  );

  const forecastCalcSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (autoRtaForecastCalculation?.data && autoRtaFieldData?.dates) {
      const safeData: { [key: string]: any } = autoRtaForecastCalculation?.data;
      const safeFieldData: { [key: string]: any } = autoRtaFieldData;

      const pressure = {
        name: dictionary.gas.pressure,
        type: "line",
        color: palette.customColor.black,
        yAxisIndex: 1,
        data: safeData.dates.map((x: string, i: number) => [x, safeData.flowing_pressure[i]]) ?? [],
        hideSymbol: true,
        lineWidth: 2,
      };

      const pressureMeasured = {
        name: `${dictionary.gas.pressureMeasured}`,
        type: "scatter",
        color: palette.customColor.black,
        yAxisIndex: 1,
        data: safeFieldData.dates.map((x: string, i: number) => [x, safeFieldData[`flowing_pressure`][i]]) ?? [],
        hideSymbol: true,
        lineWidth: 2,
      };

      if (fluidType === FluidType.gas) {
        const averagePressure = {
          name: `${dictionary.autoRta.averagePressure}`,
          type: "line",
          color: palette.customColor.black,
          yAxisIndex: 1,
          data: safeData.dates.map((x: string, i: number) => [x, safeData[`average_pressure`][i]]) ?? [],
          hideSymbol: true,
          lineWidth: 2,
        };
        return [
          {
            name: `${dictionary.autoRta.modelled} ${dictionary.autoRta.gasRate}`,
            type: "line",
            color: palette.customColor.red,
            yAxisIndex: 0,
            data: safeData.dates.map((x: string, i: number) => [x, safeData[`dry_gas_rate`][i]]) ?? [],
            hideSymbol: true,
            lineWidth: 2,
          },
          {
            name: `${dictionary.autoRta.modelled} ${dictionary.autoRta.recombinedGasRate}`,
            type: "line",
            color: palette.customColor.red,
            yAxisIndex: 0,
            data: safeData.dates.map((x: string, i: number) => [x, safeData[`recombined_gas_rate`][i]]) ?? [],
            hideSymbol: true,
            lineWidth: 2,
          },
          {
            name: `${dictionary.autoRta.measured} ${dictionary.autoRta.gasRate}`,
            type: "scatter",
            color: palette.customColor.red,
            yAxisIndex: 0,
            data: autoRtaFieldData.dates.map((x: string, i: number) => [x, safeFieldData.dry_gas_rate?.[i]]) ?? [],
            hideSymbol: true,
            lineWidth: 2,
          },
          pressure,
          pressureMeasured,
          averagePressure,
        ];
      }
      return [
        {
          name: `${dictionary.autoRta.modelled} ${dictionary.autoRta.oilRate}`,
          type: "line",
          color: palette.customColor.green,
          yAxisIndex: 0,
          data: safeData.dates.map((x: string, i: number) => [x, safeData[`oil_rate`][i]]) ?? [],
          hideSymbol: true,
          lineWidth: 2,
        },
        {
          name: `${dictionary.autoRta.measured} ${dictionary.autoRta.oilRate}`,
          type: "scatter",
          color: palette.customColor.green,
          yAxisIndex: 0,
          data: safeFieldData.dates.map((x: string, i: number) => [x, safeFieldData[`oil_rate`][i]]) ?? [],
          hideSymbol: true,
          lineWidth: 2,
        },
        pressure,
        pressureMeasured,
      ];
    }
    return [];
  }, [autoRtaFieldData, autoRtaForecastCalculation?.data, fluidType, palette.customColor.black, palette.customColor.green, palette.customColor.red]);

  const cumulativeGasChartSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!autoRtaForecastCalculation?.data || !autoRtaFieldData?.dates) return [];
    const safeData: { [key: string]: any } = autoRtaForecastCalculation.data;
    const safeFieldData: { [key: string]: any } = autoRtaFieldData;

    if (fluidType === FluidType.oil) {
      return [
        {
          name: `${dictionary.autoRta.modelled} ${dictionary.autoRta.oilCumulative}`,
          type: "line",
          color: palette.customColor.green,
          yAxisIndex: 0,
          data: safeData.dates.map((x: string, i: number) => [x, safeData[`cumulative_${fluidType}`][i]]) ?? [],
          hideSymbol: true,
          lineWidth: 3,
        },
        {
          name: `${dictionary.autoRta.measured} ${dictionary.autoRta.oilCumulative}`,
          type: "scatter",
          color: palette.customColor.green,
          yAxisIndex: 0,
          data: safeFieldData.dates.map((x: string, i: number) => [x, safeFieldData[`cumulative_${fluidType}`][i]]) ?? [],
          hideSymbol: true,
          lineWidth: 3,
        },
      ];
    }

    return [
      {
        name: `${dictionary.autoRta.modelled} ${dictionary.autoRta.gasCumulative}`,
        type: "line",
        color: palette.customColor.red,
        yAxisIndex: 0,
        data: safeData.dates.map((x: string, i: number) => [x, safeData.dry_cumulative_gas[i]]) ?? [],
        hideSymbol: true,
        lineWidth: 3,
      },
      {
        name: `${dictionary.autoRta.measured} ${dictionary.autoRta.gasCumulative}`,
        type: "scatter",
        color: palette.customColor.red,
        yAxisIndex: 0,
        data: safeFieldData.dates.map((x: string, i: number) => [x, safeFieldData.dry_cumulative_gas[i]]) ?? [],
        hideSymbol: true,
        lineWidth: 3,
      },
      {
        name: `${dictionary.autoRta.modelled} ${dictionary.autoRta.recombinedGasCumulative}`,
        type: "line",
        color: palette.customColor.red,
        yAxisIndex: 0,
        data: safeData.dates.map((x: string, i: number) => [x, safeData.recombined_cumulative_gas[i]]) ?? [],
        hideSymbol: true,
        lineWidth: 3,
      },
    ];
  }, [autoRtaFieldData, autoRtaForecastCalculation?.data, fluidType, palette.customColor.green, palette.customColor.red]);

  const cumulativeGasYAxes = useMemo<FossilyticsChartAxis[]>(() => {
    return [
      {
        name: dictionary.autoRta[`${fluidType}Rate`],
        type: "value",
        color: seriesAxisColor,
        nameGap: 45,
      },
    ];
  }, [fluidType, seriesAxisColor]);

  return {
    forecastInputState: autoRtaState?.forecast,
    onChangeForecastInput,
    onChangeForecastInputTable,
    forecastEventsRow,
    forecastCalculationDataTableRow,
    forecastXAxes,
    forecastYAxes,
    forecastCalcSeries,
    cumulativeGasChartSeries,
    cumulativeGasYAxes,
    loadForecast: isLoading,
    onClickCalculateForecast,
    errorInputValidation: validationError,
    autoRtaForecastParameter: autoRtaForecastCalculation?.summary_card,
    forecastCalculationDataTableCol,
    fieldDataRow,
    fieldDataCol,
    fluidType,
    canCancelPoll,
    onCancelPoll,
  };
};

export default useAutoRtaForecast;
