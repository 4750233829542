import React, { PropsWithChildren } from "react";
import { shallow } from "zustand/shallow";
import { useAppStore } from "@/features/app";

import Announcement from "../Announcement";
import ModuleLink from "./ModuleLink";
import Page from "../../Page";
import useModuleList, { ModuleGridGroup } from "./hooks/useModuleList";
import useThemeStyling from "@/utils/useThemeStyling";
import useBatchQueue from "./hooks/useBatchQueue";
import GroupRunProgress from "@/features/modules/rulon/groupRun/GroupRunProgress";

export interface IAddDataButtonProps {
  // These are set based on the toggles shown above the examples (not needed in real code)
  disabled?: boolean;
  checked?: boolean;
}

function ModuleGroup({ children, isSolo }: Readonly<PropsWithChildren<{ isSolo: boolean }>>) {
  const { palette } = useThemeStyling();
  return (
    <div style={{ position: "relative" }}>
      {!isSolo && (
        <div
          style={{
            position: "absolute",
            top: 60,
            left: 65,
            right: 65,
            height: 25,
            background: palette.customColor.neutralLight,
            opacity: 0.7,
          }}
        />
      )}

      <div
        style={{
          display: "flex",
          flexFlow: "row",
          width: "auto",
          height: "auto",
          boxSizing: "border-box",
        }}
      >
        {children}
      </div>
    </div>
  );
}

interface ModuleRowProps {
  offset?: boolean;
}

function ModuleRow({ children, offset }: PropsWithChildren<ModuleRowProps>) {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {offset ? <div style={{ inlineSize: 65 }}></div> : undefined}
      {children}
      {!offset ? <div style={{ inlineSize: 65 }}></div> : undefined}
    </div>
  );
}

function Modules() {
  const { setApiError, apiError, setIsLoading, setProgress, setPollStatus, project } = useAppStore(
    (state) => ({
      setApiError: state.setApiError,
      apiError: state.apiError,
      setIsLoading: state.setIsLoading,
      setProgress: state.setProgress,
      setPollStatus: state.setPollStatus,
      project: state.project,
    }),
    shallow
  );

  const { hasDataSet, palette, modules, onClickModule } = useModuleList();

  const { canCancel, groupRunFinished, dataTableCol, dataTableRows, onCancelPoll, onClickOk, showGroupRun } = useBatchQueue({
    setApiError,
    apiError,
    setIsLoading,
    setProgress,
    setPollStatus,
    project,
  });

  return (
    <Page title="Modules">
      <Announcement />
      {showGroupRun ? (
        <GroupRunProgress
          onClickOk={onClickOk}
          onCancelPoll={onCancelPoll}
          canCancel={canCancel}
          rows={dataTableRows}
          columns={dataTableCol}
          groupRunFinished={groupRunFinished}
        />
      ) : (
        <div
          style={{
            height: "85%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              fontSize: 18,
            }}
          >
            Welcome to
          </span>
          <h1
            style={{
              margin: 10,
            }}
          >
            <span style={{ color: palette.primary.main }}>predico</span> Advanced Flow Analytics
          </h1>
          {!hasDataSet && (
            <>
              <br />
              <span>Choose a well or group to get started.</span>
            </>
          )}

          <div
            style={{
              marginTop: 10,
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {modules.map((moduleRow, moduleRowIndex: number) => {
              return (
                <ModuleRow offset={moduleRowIndex >= 2} key={`row-${moduleRow[0].section}`}>
                  {moduleRow.map((moduleGroup: ModuleGridGroup) => {
                    const isSolo = moduleGroup.items.length === 1;

                    return (
                      <ModuleGroup isSolo={isSolo} key={moduleGroup.section}>
                        {moduleGroup.items.map((item) => {
                          return (
                            <ModuleLink
                              key={item.key}
                              onClickModule={() => onClickModule(item.key)}
                              text={moduleGroup.section}
                              secondaryText={item.label}
                              to={item.route}
                              disabled={item.disabled}
                            />
                          );
                        })}
                      </ModuleGroup>
                    );
                  })}
                </ModuleRow>
              );
            })}
          </div>
        </div>
      )}
    </Page>
  );
}

export default Modules;
