import { useCallback, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { DateTime } from "luxon";

import { FossilyticsChartLine, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { SpadDeclineCoordinate, SpadDeclineState } from "@/models/spad/decline";
import { ModuleId } from "@/model";
import { getFlowLineSeries, getRatesCumLineSeries } from "../../index.utils";
import { GenericChartResult } from "@/models/Generic";

type UseSpadDeclineChartsProps = {
  currentModule?: ModuleId;
  spadDeclineState?: SpadDeclineState;
  onChartDrag: (handleBar: SpadDeclineCoordinate) => void;
  setSpadDeclineState: React.Dispatch<React.SetStateAction<SpadDeclineState | undefined>>;
  compareAnalysis?: SpadDeclineState;
  compareName?: string;
  xAxesState: boolean;
};

// get maximum date or day
const getMaxDayDate = ({ firstChart, secondChart, isDate }: { firstChart: GenericChartResult; secondChart: GenericChartResult; isDate: boolean }) => {
  const opDay = firstChart.days[firstChart.days.length - 1];
  const profDay = secondChart.days[secondChart.days.length - 1];
  if (opDay > profDay) {
    return isDate ? firstChart.dates[firstChart.dates.length - 1] : opDay;
  }
  return isDate ? secondChart.dates[secondChart.dates.length - 1] : profDay;
};

const useSpadDeclineCharts = ({
  onChartDrag,
  currentModule,
  spadDeclineState,
  setSpadDeclineState,
  compareAnalysis,
  compareName,
  xAxesState,
}: UseSpadDeclineChartsProps) => {
  const { palette } = useTheme();
  const unit = currentModule === ModuleId.SPAD_DECLINE_GAS ? "MMscf" : "bbl";
  const dayZero = spadDeclineState?.day_zero_date;
  const chartKey = xAxesState ? "dates" : "days";
  const cartesianChart = spadDeclineState?.analysed_data?.cartesian_chart;
  const forecastAnalysis = spadDeclineState?.analysis_result;

  const dataStartDay = useMemo(() => {
    const startDay = Number(spadDeclineState?.analysis_option?.analysis_start_day);

    // Validate that startDay is a valid number
    if (!dayZero || !xAxesState || isNaN(startDay)) {
      return spadDeclineState?.analysis_option?.analysis_start_day;
    }

    return DateTime.fromJSDate(new Date(dayZero)).plus({ days: startDay }).toISODate();
  }, [dayZero, spadDeclineState?.analysis_option?.analysis_start_day, xAxesState]);

  const forecastStartDate = useMemo(() => {
    if (!dayZero || !xAxesState) return spadDeclineState?.forecast_start_day;
    return DateTime.fromJSDate(new Date(dayZero))
      .plus({ days: Number(spadDeclineState?.forecast_start_day) })
      .toISODate();
  }, [dayZero, spadDeclineState?.forecast_start_day, xAxesState]);

  const forecastEndDate = useMemo(() => {
    if (!dayZero || !xAxesState) return spadDeclineState?.forecast_end_day;
    return DateTime.fromJSDate(new Date(dayZero))
      .plus({ days: Number(spadDeclineState?.forecast_end_day) })
      .toISODate();
  }, [dayZero, spadDeclineState?.forecast_end_day, xAxesState]);

  const getScatterItemColor = useCallback(
    (params: any) => {
      let itemColor;
      const forecastStartDay = forecastStartDate;

      if ((forecastStartDay && params.data[0] > forecastStartDay) || (dataStartDay && params.data[0] < dataStartDay)) {
        itemColor = palette.grey[600];
      } else {
        itemColor = currentModule === ModuleId.SPAD_DECLINE_GAS ? palette.error.main : palette.success.main;
      }

      return itemColor;
    },
    [forecastStartDate, dataStartDay, palette.grey, palette.error.main, palette.success.main, currentModule]
  );

  const getSecondaryScatterItemColor = useCallback(
    (params: any) => {
      const forecastStartDay = spadDeclineState?.forecast_start_day ?? 0;

      const indexForInitOutputDays = forecastStartDay && cartesianChart?.days?.findIndex((value) => value > Number(forecastStartDay));

      const valueForLogCummProds = indexForInitOutputDays && cartesianChart?.cumulative_production?.[indexForInitOutputDays];

      let itemColor;

      if ((valueForLogCummProds && forecastStartDay && params.data[0] > valueForLogCummProds) || (dataStartDay && params.data[0] < dataStartDay)) {
        itemColor = palette.customColor.neutralSecondary;
      } else {
        itemColor = currentModule === ModuleId.SPAD_DECLINE_GAS ? palette.customColor.red : palette.customColor.green;
      }

      return itemColor;
    },
    [
      spadDeclineState?.forecast_start_day,
      cartesianChart?.days,
      cartesianChart?.cumulative_production,
      dataStartDay,
      palette.customColor.neutralSecondary,
      palette.customColor.red,
      palette.customColor.green,
      currentModule,
    ]
  );

  const maxDays = useMemo(() => {
    if (!spadDeclineState?.analysis_result) return 0;

    return getMaxDayDate({
      isDate: xAxesState,
      firstChart: spadDeclineState?.analysis_result.operational_result.high.chart_profiles,
      secondChart: spadDeclineState?.analysis_result.profile_result.high.chart_profiles,
    });
  }, [spadDeclineState?.analysis_result, xAxesState]);

  const flowSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!cartesianChart || !forecastAnalysis) return [];
    let flowRatesData: (string | number)[][] = cartesianChart[chartKey].map((d, i) => [d, cartesianChart.rates[i]]);

    const maxDayCompare = compareAnalysis?.analysis_result
      ? getMaxDayDate({
          isDate: xAxesState,
          firstChart: compareAnalysis?.analysis_result.operational_result.high.chart_profiles,
          secondChart: compareAnalysis?.analysis_result.profile_result.high.chart_profiles,
        })
      : null;
    return [
      {
        name: "Flow rates",
        type: "scatter",
        color: getScatterItemColor,
        z: 0,
        data: flowRatesData,
      },
      {
        ...getFlowLineSeries(chartKey, forecastAnalysis?.operational_result.low.chart_profiles),
        id: "lc",
        name: "OPS Low Case",
        color: palette.customColor.purpleLight,
        defaultDisabled: true,
      },
      {
        ...getFlowLineSeries(chartKey, forecastAnalysis.operational_result.mid.chart_profiles),
        id: "mc",
        name: "OPS Mid Case",
        color: palette.customColor.purple,
      },
      {
        ...getFlowLineSeries(chartKey, forecastAnalysis.operational_result.high.chart_profiles),
        id: "hc",
        name: "OPS High Case",
        color: palette.customColor.purpleDark,
        defaultDisabled: true,
      },
      {
        ...getFlowLineSeries(chartKey, forecastAnalysis.profile_result.low.chart_profiles),
        id: "lcl",
        name: "Profile Low Case",
        color: palette.primary.main,
        defaultDisabled: true,
      },
      {
        ...getFlowLineSeries(chartKey, forecastAnalysis.profile_result.mid.chart_profiles),
        id: "mcl",
        name: "Profile Mid Case",
        color: palette.customColor.blue,
      },
      {
        ...getFlowLineSeries(chartKey, forecastAnalysis.profile_result.high.chart_profiles),
        id: "hcl",
        name: "Profile High Case",
        color: palette.customColor.yellow,
        defaultDisabled: true,
      },
      {
        name: "Economic cutoff",
        type: "line",
        lineType: "dashed",
        hideSymbol: true,
        color: palette.customColor.red,
        data: [
          [0, spadDeclineState?.analysis_option?.economic_cutoff],
          [maxDays, spadDeclineState?.analysis_option?.economic_cutoff],
        ],
      },
      ...(compareName && compareAnalysis?.analysis_result
        ? [
            {
              ...getFlowLineSeries(chartKey, compareAnalysis.analysis_result.operational_result.low.chart_profiles),
              name: `${compareName} OPS Low Case`,
              lineWidth: 1,
              color: palette.customColor.neutralDark,
              defaultDisabled: true,
            },
            {
              ...getFlowLineSeries(chartKey, compareAnalysis.analysis_result.operational_result.mid.chart_profiles),
              name: `${compareName} OPS Mid Case`,
              lineWidth: 1,
              color: palette.customColor.neutralDark,
              defaultDisabled: true,
            },
            {
              ...getFlowLineSeries(chartKey, compareAnalysis.analysis_result.operational_result.high.chart_profiles),
              name: `${compareName} OPS High Case`,
              lineWidth: 1,
              color: palette.customColor.neutralDark,
              defaultDisabled: true,
            },
            {
              ...getFlowLineSeries(chartKey, compareAnalysis.analysis_result.profile_result.low.chart_profiles),
              name: `${compareName} Profile Low Case`,
              lineWidth: 1,
              color: palette.customColor.blueLight,
            },
            {
              ...getFlowLineSeries(chartKey, compareAnalysis.analysis_result.profile_result.mid.chart_profiles),
              name: `${compareName} Profile Mid Case`,
              lineWidth: 1,
              color: palette.customColor.blueLight,
            },
            {
              ...getFlowLineSeries(chartKey, compareAnalysis.analysis_result.profile_result.high.chart_profiles),
              name: `${compareName} Profile High Case`,
              lineWidth: 1,
              color: palette.customColor.blueLight,
            },
            {
              name: `${compareName} Economic cutoff`,
              type: "line",
              lineType: "dashed",
              hideSymbol: true,
              color: palette.customColor.neutralDark,
              data: maxDayCompare
                ? [
                    [0, compareAnalysis?.analysis_option?.economic_cutoff],
                    [maxDayCompare, compareAnalysis?.analysis_option?.economic_cutoff],
                  ]
                : [],
            },
          ]
        : []),
    ];
  }, [
    cartesianChart,
    forecastAnalysis,
    chartKey,
    compareAnalysis?.analysis_result,
    compareAnalysis?.analysis_option?.economic_cutoff,
    xAxesState,
    getScatterItemColor,
    palette.customColor.purpleLight,
    palette.customColor.purple,
    palette.customColor.purpleDark,
    palette.customColor.blue,
    palette.customColor.yellow,
    palette.customColor.red,
    palette.customColor.neutralDark,
    palette.customColor.blueLight,
    palette.primary.main,
    spadDeclineState?.analysis_option?.economic_cutoff,
    maxDays,
    compareName,
  ]);

  const logFlowSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (
      !spadDeclineState?.analysed_data?.log_chart?.days ||
      !spadDeclineState?.analysed_data?.log_chart?.rates ||
      !spadDeclineState?.analysed_data?.log_chart?.dates
    )
      return [];

    let data: (string | number)[][] = spadDeclineState.analysed_data.log_chart[chartKey].map((d, i) => [
      d,
      spadDeclineState.analysed_data!.log_chart.rates[i],
    ]);

    return [
      {
        name: "Flow rates",
        type: "scatter",
        color: getScatterItemColor,
        z: -1,
        data,
      },
      ...flowSeries.filter((s) => s.name !== "Flow rates"),
    ];
  }, [flowSeries, chartKey, getScatterItemColor, spadDeclineState?.analysed_data]);

  const ratesCumSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (!spadDeclineState?.analysis_result) return [];
    const profileHighForecast = spadDeclineState?.analysis_result?.profile_result.high.chart_profiles;
    const compareProfileHighForecast = compareAnalysis ? compareAnalysis?.analysis_result?.profile_result.high.chart_profiles : null;
    return [
      {
        name: "Flow rates",
        type: "scatter",
        color: getSecondaryScatterItemColor,
        data: cartesianChart ? cartesianChart[chartKey].map((_, i) => [cartesianChart.cumulative_production[i], cartesianChart.rates[i]]) : [],
        z: -1,
      },
      {
        ...getRatesCumLineSeries(chartKey, forecastAnalysis?.operational_result.low.chart_profiles),
        id: "lc",
        name: "OPS Low Case",
        color: palette.customColor.purpleLight,
        defaultDisabled: true,
      },
      {
        ...getRatesCumLineSeries(chartKey, forecastAnalysis?.operational_result.mid.chart_profiles),
        id: "mc",
        name: "OPS Mid Case",
        color: palette.customColor.purple,
        defaultDisabled: true,
      },
      {
        ...getRatesCumLineSeries(chartKey, forecastAnalysis?.operational_result.high.chart_profiles),
        id: "hc",
        name: "OPS High Case",
        color: palette.customColor.purpleDark,
        defaultDisabled: true,
      },
      {
        ...getRatesCumLineSeries(chartKey, forecastAnalysis?.profile_result.low.chart_profiles),
        id: "lcl",
        name: "Profile Low Case",
        color: palette.primary.main,
        z: 100,
      },
      {
        ...getRatesCumLineSeries(chartKey, forecastAnalysis?.profile_result.mid.chart_profiles),
        id: "mcl",
        name: "Profile Mid Case",
        color: palette.customColor.blue,
        z: 100,
      },
      {
        ...getRatesCumLineSeries(chartKey, forecastAnalysis?.profile_result.high.chart_profiles),
        id: "hcl",
        name: "Profile High Case",
        color: palette.customColor.yellow,
        z: 100,
      },
      {
        name: "Economic cutoff",
        type: "line",
        lineType: "dashed",
        hideSymbol: true,
        color: palette.customColor.red,
        z: 1,
        data: profileHighForecast
          ? [
              [xAxesState ? dayZero : 0, spadDeclineState?.analysis_option?.economic_cutoff],
              [profileHighForecast[chartKey][profileHighForecast[chartKey].length - 1], spadDeclineState?.analysis_option?.economic_cutoff],
            ]
          : [],
      },
      ...(compareName && compareAnalysis?.analysis_result
        ? [
            {
              ...getRatesCumLineSeries(chartKey, compareAnalysis.analysis_result.operational_result.low.chart_profiles),
              name: `${compareName} OPS Low Case`,
              lineWidth: 1,
              color: palette.customColor.neutralDark,
              defaultDisabled: true,
            },
            {
              ...getRatesCumLineSeries(chartKey, compareAnalysis.analysis_result.operational_result.mid.chart_profiles),
              name: `${compareName} OPS Mid Case`,
              lineWidth: 1,
              color: palette.customColor.neutralDark,
              defaultDisabled: true,
            },
            {
              ...getRatesCumLineSeries(chartKey, compareAnalysis.analysis_result.operational_result.high.chart_profiles),
              name: `${compareName} OPS High Case`,
              lineWidth: 1,
              color: palette.customColor.neutralDark,
              defaultDisabled: true,
            },
            {
              ...getRatesCumLineSeries(chartKey, compareAnalysis.analysis_result.profile_result.low.chart_profiles),
              name: `${compareName} Profile Low Case`,
              lineWidth: 1,
              color: palette.customColor.blueLight,
            },
            {
              ...getRatesCumLineSeries(chartKey, compareAnalysis.analysis_result.profile_result.mid.chart_profiles),
              name: `${compareName} Profile Mid Case`,
              lineWidth: 1,
              color: palette.customColor.blueLight,
            },
            {
              ...getRatesCumLineSeries(chartKey, compareAnalysis.analysis_result.profile_result.high.chart_profiles),
              name: `${compareName} Profile High Case`,
              lineWidth: 1,
              color: palette.customColor.blueLight,
            },
            {
              name: `${compareName} Economic cutoff`,
              type: "line",
              lineType: "dashed",
              hideSymbol: true,
              color: palette.customColor.neutralDark,
              z: 0,
              data: compareProfileHighForecast
                ? [
                    [xAxesState ? dayZero : 0, compareAnalysis?.analysis_option?.economic_cutoff],
                    [
                      compareProfileHighForecast[chartKey][compareProfileHighForecast[chartKey].length - 1],
                      compareAnalysis?.analysis_option?.economic_cutoff,
                    ],
                  ]
                : [],
            },
          ]
        : []),
    ];
  }, [
    spadDeclineState?.analysis_result,
    spadDeclineState?.analysis_option?.economic_cutoff,
    compareAnalysis,
    getSecondaryScatterItemColor,
    cartesianChart,
    chartKey,
    forecastAnalysis?.operational_result.low.chart_profiles,
    forecastAnalysis?.operational_result.mid.chart_profiles,
    forecastAnalysis?.operational_result.high.chart_profiles,
    forecastAnalysis?.profile_result.low.chart_profiles,
    forecastAnalysis?.profile_result.mid.chart_profiles,
    forecastAnalysis?.profile_result.high.chart_profiles,
    palette.customColor.purpleLight,
    palette.customColor.purple,
    palette.customColor.purpleDark,
    palette.customColor.blue,
    palette.customColor.yellow,
    palette.customColor.red,
    palette.customColor.neutralDark,
    palette.customColor.blueLight,
    palette.primary.main,
    xAxesState,
    dayZero,
    compareName,
  ]);

  const forecastStartLine = useMemo<FossilyticsChartLine | undefined>(() => {
    if (!forecastStartDate) return undefined;
    return {
      id: "forecastStartDay",
      name: "Forecast start",
      type: "vertical",
      xValue: forecastStartDate,
      lineType: "dashed",
      lineWidth: 1.5,
      color: palette.customColor.black,
      controllable: false,
    };
  }, [forecastStartDate, palette.customColor.black]);

  const forecastEndLine = useMemo<FossilyticsChartLine | undefined>(() => {
    if (!forecastEndDate) return undefined;
    return {
      id: "forecastEndDay",
      name: "Forecast end",
      type: "vertical",
      xValue: forecastEndDate,
      lineType: "dashed",
      lineWidth: 1.5,
      color: palette.customColor.black,
      controllable: false,
    };
  }, [forecastEndDate, palette.customColor.black]);

  const dataStartLine = useMemo<FossilyticsChartLine | undefined>(() => {
    if (!dataStartDay || !forecastStartDate) return undefined;
    const flowRates = logFlowSeries.find((f) => f.name === "Flow rates");
    const fifthFromEnd = flowRates?.data[flowRates?.data.length - 50];
    if (!fifthFromEnd) return undefined;

    const forecastStartDay = forecastStartDate;
    return {
      id: "dataStartDay",
      name: "Cluster start",
      type: "vertical",
      xValue: dataStartDay,
      lineType: "dashed",
      lineWidth: 1.5,
      color: palette.customColor.black,
      xConstraintValue: [0, forecastStartDay],
      controllable: true,
    };
  }, [dataStartDay, forecastStartDate, logFlowSeries, palette.customColor.black]);

  const lineBaseColor = useMemo(() => {
    return [
      {
        id: "operational,low",
        name: "",
        color: "#fff0",
        handleColor: palette.customColor.purpleLight,
        line: [],
        linked: "OPS Low Case",
      },
      {
        id: "operational,mid",
        name: "",
        color: "#fff0",
        handleColor: palette.customColor.purple,
        line: [],
        linked: "OPS Mid Case",
      },
      {
        id: "operational,high",
        name: "",
        color: "#fff0",
        handleColor: palette.customColor.purpleDark,
        line: [],
        linked: "OPS High Case",
      },
      {
        id: "profile,low",
        name: "",
        color: "#fff0",
        handleColor: palette.primary.main,
        line: [],
        linked: "Profile Low Case",
      },
      {
        id: "profile,mid",
        name: "",
        color: "#fff0",
        handleColor: palette.customColor.blue,
        line: [],
        linked: "Profile Mid Case",
      },
      {
        id: "profile,high",
        name: "",
        color: "#fff0",
        handleColor: palette.customColor.yellow,
        line: [],
        linked: "Profile High Case",
      },
    ];
  }, [
    palette.customColor.blue,
    palette.customColor.purple,
    palette.customColor.purpleDark,
    palette.customColor.purpleLight,
    palette.primary.main,
    palette.customColor.yellow,
  ]);

  const dateLineHandleBars = useMemo(() => {
    const lines = [];
    if (forecastStartLine) lines.push(forecastStartLine);
    if (forecastEndLine) lines.push(forecastEndLine);
    if (dataStartLine) lines.push(dataStartLine);

    return lines;
  }, [dataStartLine, forecastEndLine, forecastStartLine]);

  const parseHandleBarWithColor = useCallback(
    (isTime: boolean, handleBar?: SpadDeclineCoordinate[]) => {
      if (!handleBar || !dayZero) return [];
      return [
        ...handleBar.reduce((res, handle) => {
          const lineBaseId = handle.identity.split(",").slice(1).join(",");
          const baseColor = lineBaseColor.filter((base) => base.id === lineBaseId)[0];

          if (baseColor) {
            let xCoordinate1 = String(handle.coordinates[0].x);
            let xCoordinate2 = String(handle.coordinates[1].x);

            if (xAxesState && isTime) {
              xCoordinate1 = DateTime.fromJSDate(new Date(dayZero))
                .plus({ days: Number(xCoordinate1) })
                .toISODate();
              xCoordinate2 = DateTime.fromJSDate(new Date(dayZero))
                .plus({ days: Number(xCoordinate2) })
                .toISODate();
            }

            res.push({
              ...baseColor,
              line: [
                [xCoordinate1, handle.coordinates[0].y],
                [xCoordinate2, handle.coordinates[1].y],
              ],
              key: handle.identity,
            });
          }
          return res;
        }, [] as FossilyticsChartLine[]),
      ];
    },
    [dayZero, lineBaseColor, xAxesState]
  );

  const rateCumProdHandleBar = useMemo(() => {
    return parseHandleBarWithColor(false, spadDeclineState?.chart_handlebars?.rate_cumprod);
  }, [parseHandleBarWithColor, spadDeclineState?.chart_handlebars?.rate_cumprod]);

  const rateTimeHandleBar = useMemo(() => {
    return [...parseHandleBarWithColor(true, spadDeclineState?.chart_handlebars?.rate_time), ...dateLineHandleBars];
  }, [dateLineHandleBars, parseHandleBarWithColor, spadDeclineState?.chart_handlebars?.rate_time]);

  const updateLineFromChart = useCallback(
    (l: number[][] | [string, number][], _i: number, _isEnd?: boolean, line?: FossilyticsChartLine, isDate?: boolean) => {
      if (!dayZero || !l || l.length < 2) return;

      let startDay = Math.round(Number(l[0][0]));
      let coordinates: { x: number; y: number }[] = [];

      if (isDate) {
        const dayStart = DateTime.fromISO(dayZero);
        const dateX1 = DateTime.fromISO(String(l[0][0]));
        const dateX2 = DateTime.fromISO(String(l[1][0]));

        startDay = dateX1.isValid ? Math.round(dateX1.diff(dayStart, "days").days) : NaN;
        coordinates = [
          {
            x: dateX1.isValid ? dateX1.diff(dayStart, "days").days : NaN,
            y: Number(l[0][1]),
          },
          {
            x: dateX2.isValid ? dateX2.diff(dayStart, "days").days : NaN,
            y: Number(l[1][1]),
          },
        ];
      } else {
        coordinates = [
          { x: Number(l[0][0]), y: Number(l[0][1]) },
          { x: Number(l[1][0]), y: Number(l[1][1]) },
        ];
      }

      if (isNaN(startDay) || coordinates.some((coord) => isNaN(coord.x) || isNaN(coord.y))) {
        return;
      }

      if (line?.id === "dataStartDay") {
        setSpadDeclineState((prev) => {
          if (!prev?.analysis_option) return prev;
          return {
            ...prev,
            analysis_option: {
              ...prev.analysis_option,
              analysis_start_day: startDay,
              auto_update_cluster_start_day: false,
            },
          };
        });
      } else {
        onChartDrag({
          coordinates: coordinates,
          identity: line?.key ?? "",
        });
      }
    },
    [dayZero, onChartDrag, setSpadDeclineState]
  );

  const draggable = spadDeclineState?.analysis_option?.auto_update_cluster_start_day;

  return {
    logFlowSeries,
    ratesCumSeries,
    updateLineFromChart,
    unit,
    draggable,
    getSecondaryScatterItemColor,
    flowSeries,
    getScatterItemColor,
    forecastStartLine,
    rateCumProdHandleBar,
    rateTimeHandleBar,
    dateLineHandleBars,
  };
};

export default useSpadDeclineCharts;
