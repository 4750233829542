import { createTheme, PaletteMode } from "@mui/material";

type CustomColor = {
  black: string;
  blackTranslucent40: string;
  blue: string;
  blueDark: string;
  blueLight: string;
  blueMid: string;
  green: string;
  greenDark: string;
  greenLight: string;
  neutralDark: string;
  neutralLight: string;

  neutralSecondary: string;
  neutralTertiary: string;

  orangeLighter: string;
  purple: string;
  purpleDark: string;
  purpleLight: string;
  red: string;
  redDark: string;

  white: string;
  whiteTranslucent40: string;
  yellow: string;

  pinkLight: string;
};
declare module "@mui/material/styles" {
  interface Palette {
    customColor: CustomColor;
  }
  interface PaletteOptions {
    customColor: CustomColor;
  }
}

export const colorPalette = {
  primary: {
    main: "#ff8200",
    light: "#fbdcb9",
    dark: "#b86d16",
  },
  secondary: {
    main: "#931d2d",
  },
  success: {
    main: "#5FAD56",
  },

  customColor: {
    black: "#1d1d1d",
    blackTranslucent40: "rgba(0,0,0,.4)",
    blue: "#044B7F",
    blueDark: "#022F4F",
    blueLight: "#0676C6",
    blueMid: "#00188f",
    green: "#5FAD56",
    greenDark: "#386832",
    greenLight: "#87c180",
    neutralDark: "#272727",
    neutralLight: "#edebe9",
    neutralSecondary: "#858585",
    neutralTertiary: "#c2c2c2",
    orangeLighter: "#ff8c00",
    purple: "#5c2d91",
    purpleDark: "#32145a",
    purpleLight: "#b4a0ff",
    red: "#C5283D",
    redDark: "#931d2d",
    white: "#ffffff",
    whiteTranslucent40: "rgba(255,255,255,.4)",
    yellow: "#ffb900",
    pinkLight: "#FFB6C1",
  },
};

export const getMuiTheme = (mode: PaletteMode) =>
  createTheme({
    typography: {
      fontFamily: ['"Baloo 2"', "sans-serif"].join(","),
    },

    palette: {
      ...colorPalette,
      mode,
    },
  });
