import { useMemo } from "react";
import styled from "@emotion/styled";
import { shallow } from "zustand/shallow";

import { useAppStore } from "@/features/app";

import useThemeStyling from "@/utils/useThemeStyling";
import Tabs from "@/components/Tabs";
import dictionary from "@/constants/dictionary";

import useDataview from "./hooks/useDataPreview";
import CustomTable from "@/components/CustomTable";
import FossilyticsChart from "@/components/FossilyticsChart";
import LoadingIndicator from "@/components/LoadingIndicator";
import useTagList, { tagColumn } from "@/utils/useTagList";
import { useTreeViewState } from "@/components/TreeView/hooks/TreeViewContextV2";

const Container = styled.div`
  padding: 20px;
  overflow: auto;
  max-width: 94vw;

  display: flex;
  flex-direction: column;
  height: 94%;
`;

const DataPreviewPage = () => {
  const { elevatedCard } = useThemeStyling();

  const { treeViewWidth } = useTreeViewState();
  const { setApiError, apiError, dataSets } = useAppStore(
    (state) => ({
      setApiError: state.setApiError,
      apiError: state.apiError,
      dataSets: state.dataSets,
    }),
    shallow
  );

  const { loadingState, dataOptionColumn, dataOptionRows, onCellsChanged, yAxes, xAxes, series, dataColumns, dataRows } = useDataview({
    setApiError,
    apiError,
  });

  const { selectedWells, tagTableList } = useTagList({
    dataSets,
  });

  const getMaxWidthRightSide = window.innerWidth - treeViewWidth - 20;

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.dataView.dataChart}</span>,
        key: "Data chart",
        content: (
          <div>
            {series.length === 0 ? (
              <LoadingIndicator />
            ) : (
              <div
                style={{
                  height: 600,
                }}
              >
                <FossilyticsChart
                  id={"dataview_chart"}
                  key={"dataview_chart"}
                  isLoading={loadingState || series.length === 0}
                  xAxes={xAxes}
                  yAxes={yAxes}
                  series={series}
                />
              </div>
            )}
            <h3 style={{ marginTop: 10 }} className="primaryColor noMarginVer">
              {dictionary.dataView.chartOptions}
            </h3>
            <CustomTable
              style={{
                display: "flex",
                width: "100%",
                minHeight: 400,
                marginBottom: 50,
              }}
              columns={dataOptionColumn}
              rows={dataOptionRows}
              enableRangeSelection
              enableColumnSelection
              stickyTopRows={1}
              onCellsChanged={onCellsChanged}
              isLoading={false}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.dataView.dataTable}</span>,
        key: "Data table",
        content: (
          <div
            style={{
              marginTop: 20,
              height: 500,
              width: getMaxWidthRightSide,
              overflow: "auto",
            }}
          >
            <CustomTable
              style={{
                display: "flex",
                overflow: "auto",
                height: "100%",
                width: "fit-content",
              }}
              noScrollable
              columns={dataColumns}
              rows={dataRows}
              enableRangeSelection
              enableColumnSelection
              isLoading={loadingState}
              stickyTopRows={1}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.dataView.tags}</span>,
        key: "Tags",
        content: (
          <div>
            <CustomTable
              style={{
                display: "flex",
                overflow: "auto",
                height: "100%",
                width: 415,
              }}
              columns={tagColumn}
              rows={tagTableList?.[0].tagTableRow}
              enableRangeSelection={false}
              enableColumnSelection={false}
              isLoading={loadingState}
              stickyTopRows={1}
            />
          </div>
        ),
      },
    ];
  }, [
    series,
    loadingState,
    xAxes,
    yAxes,
    dataOptionColumn,
    dataOptionRows,
    onCellsChanged,
    getMaxWidthRightSide,
    dataColumns,
    dataRows,
    tagTableList,
  ]);

  return (
    <Container>
      <h2 className="primaryColor noMarginVer">{selectedWells?.[0]?.name}</h2>
      <div style={{ ...elevatedCard, marginTop: 10 }}>
        <Tabs tabList={tabList} />
      </div>
    </Container>
  );
};

export default DataPreviewPage;
