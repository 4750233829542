import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";
import _ from "lodash";

import { ModuleId, isDataSet } from "@/model";
import { RulonChangeItem, RulonGroupRunResponse, RulonItem } from "@/models/rulon";
import dictionary from "@/constants/dictionary";

import { tableCellStyle, tableHeaderStyle } from "@/components/CustomTable";
import { moduleDictionary } from "@/components/Modules/constants";
import { usePolling } from "@/utils/apiFetcher";
import { calculateRullon, initializeRullon, validateRullon } from "@/constants/apiUrl";
import { parseErrorThrown } from "@/utils/errorHandling";
import { GenericPollProps, GroupRunGenericProps } from "./type";

const dropdownOption = [
  {
    value: ModuleId.SPAD_DECLINE_GAS,
    label: moduleDictionary[ModuleId.SPAD_DECLINE_GAS],
  },
  {
    value: ModuleId.SPAD_DECLINE_OIL,
    label: moduleDictionary[ModuleId.SPAD_DECLINE_OIL],
  },
  {
    value: ModuleId.KOLDUN_MCSIM_CSG,
    label: dictionary.rulon[ModuleId.KOLDUN_MCSIM_CSG],
  },
  {
    value: ModuleId.TAHK_FORECAST_CSG,
    label: dictionary.rulon[ModuleId.TAHK_FORECAST_CSG],
  },
];

const useGroupRun = ({
  selectedDataSets,
  isLoading,
  project,
  setApiError,
  setIsLoading,
  setPollStatus,
  setProgress,
  apiError,
}: GroupRunGenericProps & GenericPollProps) => {
  const [rulonState, setRulonState] = useState<RulonGroupRunResponse | null>();
  const [activeDropdown, setActiveDropdown] = useState<number>(0);

  const haveInitialize = useRef(false);

  const { createPoll, canCancelPoll, onCancelPoll } = usePolling({
    setApiError,
    apiError,
    setLoadingState: setIsLoading,
    setProgressStatus: (val) => {
      setProgress(val.progress ?? null);
      setPollStatus(val.pollStatus);
    },
  });

  const dataSets = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets.id];
    return selectedDataSets?.map((dataSet) => dataSet.id) ?? [];
  }, [selectedDataSets]);

  const initializeRulonGroupRun = useCallback(async () => {
    try {
      if (!project || !dataSets || dataSets.length === 0) return;
      const res = await createPoll<RulonGroupRunResponse>({
        path: initializeRullon(project?.id ?? ""),
        body: {
          data_set_ids: dataSets,
        },
        type: "post",
      });

      if (res.task_result && !rulonState) {
        setRulonState(res.task_result);
      }
    } catch (error) {
      console.log(error);
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
      console.log(error);
    }
  }, [apiError, createPoll, dataSets, project, rulonState, setApiError]);

  useEffect(() => {
    if (haveInitialize.current) return;
    haveInitialize.current = true;
    initializeRulonGroupRun();
  }, [initializeRulonGroupRun]);

  const loading = isLoading;
  const rulonColumns = Object.keys(dictionary.rulonTableHeader).map((columnId, index) => {
    return { columnId: columnId, width: index === 2 ? 180 : 300 };
  });

  const rulonRows: Row<any>[] = useMemo(() => {
    const header = {
      rowId: "header",
      cells: Object.values(dictionary.rulonTableHeader).map((header, index) => {
        return {
          type: "custom",
          text: header,
          style: tableHeaderStyle,
        };
      }),
      height: 50,
    };
    if (!rulonState) return [header];
    const modules = _.cloneDeep(rulonState.input_validation);
    const headerKeys = Object.keys(dictionary.rulonTableHeader);
    return [
      header,
      ...modules.map((layer, rowIndex) => {
        return {
          rowId: rowIndex,
          height: 30,
          cells: [
            ...headerKeys.map((header, index) => {
              let val = layer[header as keyof RulonItem];
              if (index === 1) {
                return {
                  type: "dropdown",
                  selectedValue: val ?? undefined,
                  values: dropdownOption,
                  style: tableCellStyle,
                  isOpen: activeDropdown === rowIndex + 1,
                  nonEditable: loading,
                };
              }

              const textStyle = {
                ...tableCellStyle,
                color: "black",
              };

              // color
              if (header === "input_status") {
                if (val === "valid") textStyle.color = "darkseagreen";
                else textStyle.color = "red";
              }
              // value format
              if (header === "output_calculation_time") {
                // add z add the end of date to mark it as utc and javascript can convert it to user local correctly
                val = val ? `${new Date(val + "Z").toLocaleDateString()} ${new Date(val + "Z").toLocaleTimeString()}` : "-";
              }

              return {
                type: "text",
                text: val ?? "-",
                style: textStyle,
                nonEditable: loading || header !== "module",
              };
            }),
          ],
        };
      }),
    ];
  }, [activeDropdown, loading, rulonState]);

  const onChangeCell = useCallback(
    (changes: CellChange[]) => {
      if (!rulonState) return;
      const updatedRows = [...rulonState.input_validation];

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell, previousCell, type } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        rowId = rowId as number;
        columnId = columnId as string;

        if (type === "dropdown") {
          const newDropdown = dropDownNewCell.isOpen ? rowId + 1 : 0;
          if (prevCell.isOpen !== dropDownNewCell.isOpen && newDropdown !== activeDropdown) {
            setActiveDropdown(dropDownNewCell.isOpen ? rowId + 1 : 0);
          } else {
            setActiveDropdown(0);
          }
          if (dropDownNewCell.isOpen) return;
          if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
            updatedRows[rowId].module = dropDownNewCell.selectedValue ?? "";
          }
        }
      }
      setRulonState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          input_validation: updatedRows,
        };
      });
    },
    [activeDropdown, rulonState]
  );

  const onValidateRulon = useCallback(async () => {
    try {
      if (!project || !rulonState?.input_validation) return;

      const res = await createPoll<RulonGroupRunResponse, RulonChangeItem[]>({
        path: validateRullon(project?.id ?? ""),
        body: rulonState?.input_validation.map((item) => ({ data_set_id: item.data_set_id, module: item.module })),
        type: "post",
      });

      if (res.task_result) {
        setRulonState(res.task_result);
      }
    } catch (error) {
      console.log(error);
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
    }
  }, [project, rulonState, createPoll, setApiError, apiError]);

  const onCalculateRulon = useCallback(async () => {
    try {
      if (!project || !rulonState?.input_validation) return;

      const res = await createPoll<RulonGroupRunResponse, RulonChangeItem[]>({
        path: calculateRullon(project?.id),
        body: rulonState?.input_validation.map((item) => ({ data_set_id: item.data_set_id, module: item.module })),
        type: "post",
      });

      if (res.task_result) setRulonState(res.task_result);
    } catch (error) {
      console.log(error);
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
      console.log(error);
    }
  }, [project, rulonState?.input_validation, createPoll, setApiError, apiError]);

  return {
    loading,
    rulonRows,
    rulonColumns,
    onChangeCell,
    onValidateRulon,
    onCalculateRulon,
    rulonState,
    canCancelPoll,
    onCancelPoll,
  };
};

export default useGroupRun;
