import { useEffect, useCallback, useState, useMemo } from "react";
import { shallow } from "zustand/shallow";

import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import { useAppStore } from "./features/app";

import Layout from "./Layout";
import Modules from "./components/Modules";
import ModuleSpadWor from "./modules/spad/wor/ModuleSpadWor";
import ModuleGazFmb from "./modules/gaz/fmb/ModuleGazFmb";
import ModuleGazPz from "./modules/gaz/pz/ModuleGazPz";
import ModuleTahkForecastGas from "./modules/tahk/forecast/gasoil/ModuleTahkForecastGas";
import ModuleKoldunMcsimGas from "./modules/koldun/mcsim/gasoil/ModuleKoldunMcsimGasOilContent/ModuleKoldunMcsimGas";
import ModuleGildroHydrate from "./modules/gildro/hydrate/ModuleGildroHydrate";
import NotFound from "./NotFound";
import ModuleTahkForecastOil from "./modules/tahk/forecast/gasoil/ModuleTahkForecastOil";
import ModuleTahkCsg from "./pages/modules/tahk/forecast/csg";
import ModuleRulonGroupRun from "./pages/modules/rulon/groupRun";

import Header from "./components/Header";
import ModuleKoldunMcsimOil from "./modules/koldun/mcsim/gasoil/ModuleKoldunMcsimGasOilContent/ModuleKoldunMcsimOil";

import { SettingsPage } from "@/pages/settings";
import { ModuleSpadDeclineOilPage } from "@/pages/modules/spad/decline/oil";
import { ModuleSpadDeclineGasPage } from "@/pages/modules/spad/decline/gas";
import ModuleAutoRta from "./modules/autoRta/ModuleAutoRTA";
import ImportWellsPage from "@/features/import/wells";
import ImportProjectsPage from "@/features/import/projects";
import RulonRollupGas from "@/pages/modules/rulon/rollup/gas";
import RulonRollupOil from "@/pages/modules/rulon/rollup/oil";
import KoldunCsgV2 from "@/pages/modules/koldun/csg";
import MachineLearningPage from "./pages/modules/machineLearning";

// generic url
import ImportTools from "./pages/import/custom/[url]";

// components
import LoadingBlocker from "./components/LoadingBlocker/LoadingBlocker";
import ErrorBoundaryWrapper from "./components/ErrorBoundary";

// route url
import routeUrlMap from "./constants/routeUrl";
import { clearSessionFromLS, getTimeStampFromLS, isPastLimit, safeTimeStampToLS } from "./utils/session";
import { fetchApi } from "./utils/apiFetcher";
import { logoutApi } from "./constants/apiUrl";
import SessionModal from "./components/SessionModal";
import { FluidType } from "./models/Generic";
import { isDataSet } from "./model";
import dictionary from "./constants/dictionary";
import useThemeStyling from "./utils/useThemeStyling";
import DataPreviewPage from "./features/modules/dataPreview";

const Router = () => {
  const {
    selectedDataSets,
    isLoadingBlocker,
    progress,
    buildNumber,
    setBuildNumber,
    setCurrentModule,
    loadingBlockerText,
    setCanSaveAsImg,
    getBuildNumberText,
    setTenant,
    user,
    setUser,
    pollStatus,
    project,
    group,
    setIsLoading,
  } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoadingBlocker: state.isLoadingBlocker,
      progress: state.progress,
      getBuildNumberText: state.getBuildNumberText,

      buildNumber: state.buildNumber,
      setBuildNumber: state.setBuildNumber,
      setCurrentModule: state.setCurrentModule,
      loadingBlockerText: state.loadingBlockerText,
      setTenant: state.setTenant,
      setCanSaveAsImg: state.setCanSaveAsImg,
      user: state.user,
      setUser: state.setUser,
      pollStatus: state.pollStatus,
      project: state.project,
      group: state.group,

      setIsLoading: state.setIsLoading,
    }),
    shallow
  );
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);

  const { palette } = useThemeStyling();
  // Cancel requests on navigation
  const location = useLocation();

  const loadBuildNumber = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await fetchApi<{ build_number: string }>({
        path: "/build_details",
      });

      if (res.data) {
        setBuildNumber(res.data.build_number);
      }
    } catch (_) {
      // no -op
    } finally {
      setIsLoading(false);
    }
  }, [setBuildNumber, setIsLoading]);

  // Load Build Number
  useEffect(() => {
    if (!buildNumber) {
      loadBuildNumber();
    }
  }, [buildNumber, loadBuildNumber]);

  useEffect(() => {
    const splittedCurrLocation = location.pathname.split("/");
    // Navigate back to modules if selected data sets is not available
    // need to exclude data preview if it is outside of the project ( left pane )
    const key = window.location.search;
    const queryType = new URLSearchParams(key).get("type");

    if (
      !selectedDataSets &&
      splittedCurrLocation[1] === "modules" &&
      splittedCurrLocation.length > 2 &&
      !location.pathname.includes("V2") &&
      queryType !== "isOutsideList"
    ) {
      navigate("/modules", {});
      setCurrentModule();
    }

    if (splittedCurrLocation[1] === "settings") setCanSaveAsImg(false);
  }, [location.pathname, navigate, selectedDataSets, setCanSaveAsImg, setCurrentModule]);

  const signOut = useCallback(async () => {
    try {
      await fetchApi({ path: logoutApi, type: "del" });
      await Auth.signOut({ global: true });
    } catch (e) {
      console.error(e);
    } finally {
      setTenant(undefined);
      setUser(undefined);
      clearSessionFromLS();
      window.location.href = "/";
    }
  }, [setTenant, setUser]);

  const onClickStay = useCallback(() => {
    if (getTimeStampFromLS() && user) {
      setModalVisible(false);
      safeTimeStampToLS();
    } else {
      clearSessionFromLS();
      window.location.href = "/";
    }
  }, [user]);

  const onClickLogout = useCallback(() => {
    setModalVisible(false);
    signOut();
  }, [signOut]);

  useEffect(() => {
    // time out every 3 minutes to check latest timestamp
    setInterval(() => {
      const lastTimeStamp = getTimeStampFromLS();
      if (lastTimeStamp) {
        const isPast = isPastLimit(new Date(Number(lastTimeStamp)), 25);
        if (isPast) {
          setModalVisible(true);
        }
      } else {
        signOut();
      }
    }, 1000 * 60 * 3);
  });

  // force reload component
  const routerKey = useMemo(() => {
    const dataSets = isDataSet(selectedDataSets) ? [selectedDataSets] : selectedDataSets;
    return `${project?.id}-${group?.id}-${dataSets?.map((dataset) => dataset.id).join(";")}`;
  }, [group?.id, project?.id, selectedDataSets]);

  const buildNumberText = getBuildNumberText();

  return (
    <div style={{ height: "100vh", backgroundColor: palette.background.default, display: "flex", flexFlow: "column" }} id="inner-body">
      <LoadingBlocker status={pollStatus} isVisible={isLoadingBlocker} progress={progress} customText={loadingBlockerText} />
      {modalVisible && <SessionModal onClickLogout={onClickLogout} onClickStay={onClickStay} />}
      <Header />
      <ErrorBoundaryWrapper>
        <Routes>
          <Route path="/">
            <Route index element={<Navigate to="modules" replace />} />

            <Route path={routeUrlMap.settings} element={<SettingsPage buildNumberText={buildNumberText} />} />
            <Route path={routeUrlMap.importWells} element={<ImportWellsPage />} />
            <Route path={routeUrlMap.importProjects} element={<ImportProjectsPage />} />
            <Route path={routeUrlMap.importTools} element={<ImportTools />} />

            <Route path={routeUrlMap.modules} element={<Layout />}>
              <Route index element={<Modules />} />

              <Route path={routeUrlMap.dataPreview} element={<DataPreviewPage key={routerKey} />} />
              <Route path={routeUrlMap.gaz_fmb} element={<ModuleGazFmb key={routerKey} />} />
              <Route path={routeUrlMap.gaz_pz} element={<ModuleGazPz key={routerKey} />} />
              <Route path={routeUrlMap.gaz_autorta} element={<ModuleAutoRta title={dictionary.autoRta.gasTitle} key={routerKey} />} />
              <Route path={`${routeUrlMap.maslo_autorta}`} element={<ModuleAutoRta title={dictionary.autoRta.oilTitle} key={routerKey} />} />

              <Route path={`${routeUrlMap.spad_decline_gas}`} element={<ModuleSpadDeclineGasPage key={routerKey} />} />
              <Route path={`${routeUrlMap.spad_decline_oil}`} element={<ModuleSpadDeclineOilPage key={routerKey} />} />
              <Route path={routeUrlMap.spad_wor} element={<ModuleSpadWor key={routerKey} />} />

              <Route path={routeUrlMap.tahk_forecast_gas} element={<ModuleTahkForecastGas key={routerKey} />} />
              <Route path={routeUrlMap.tahk_forecast_oil} element={<ModuleTahkForecastOil key={routerKey} />} />
              <Route path={routeUrlMap.tahk_forecast_csg} element={<ModuleTahkCsg key={routerKey} />} />
              <Route path={routeUrlMap.koldun_mcsim_gas} element={<ModuleKoldunMcsimGas key={routerKey} />} />
              <Route path={routeUrlMap.koldun_mcsim_oil} element={<ModuleKoldunMcsimOil key={routerKey} />} />
              <Route path={routeUrlMap.gildro_hydrate} element={<ModuleGildroHydrate key={routerKey} />} />
              <Route path={routeUrlMap.rulon_group_run} element={<ModuleRulonGroupRun />} />
              <Route path={routeUrlMap.rulon_rollup_gas} element={<RulonRollupGas key={FluidType.gas} type={FluidType.gas} />} />
              <Route path={routeUrlMap.rulon_rollup_oil} element={<RulonRollupOil key={FluidType.oil} type={FluidType.oil} />} />
              <Route path={routeUrlMap.koldun_mcsim_csg} element={<KoldunCsgV2 key={routerKey} />} />
              <Route path={routeUrlMap.ml_forecast} element={<MachineLearningPage key={routerKey} />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </ErrorBoundaryWrapper>
    </div>
  );
};

export default Router;
