export const APIName = "afa";

export const logoutApi = "/auth/session";
export const getSpadWorAnalysis = "/modules/spad/wor/get_analysis";
export const loadCleanRawData = "/loaders/clean_raw_data";
export const loadRawData = "/loaders/load_raw_data";

export const navPermission = "/permissions";

export const batchTask = "/tasks/batch";

export const groupApi = (projectId: string, groupId: string) => `/projects/${projectId}/groups/${groupId}`;
export const projectApi = (projectId: string) => `/projects/${projectId}`;

export const deleteDataSetApi = (projectId: string, groupId: string) => `/projects/${projectId}/groups/${groupId}/data_sets`;
export const cancelTask = (taskId: string) => `/tasks/${taskId}`;

export const dataviewCalculation = (projectId: string, moduleId: string) => {
  return `/projects/${projectId}/modules/${moduleId}/dataview-calculate`;
};

export const dataviewInitialize = (projectId: string, moduleId: string) => {
  return `/projects/${projectId}/modules/${moduleId}/dataview-initialise`;
};

// datapreview
export const dataPreview = (dataSetId: string) => {
  return `/datasets/${dataSetId}`;
};

// auto rta version 2 -> work for oil and gas
export const autoRtaInitialize = (projectId: string, fluidType: string) => {
  return `/projects/${projectId}/modules/autorta/fluid/${fluidType}/initialise`;
};

export const autoRtaInput = (projectId: string, fluidType: string) => {
  return `/projects/${projectId}/modules/autorta/fluid/${fluidType}/input`;
};

export const autoRtaAnalyse = (projectId: string, fluidType: string) => {
  return `/projects/${projectId}/modules/autorta/fluid/${fluidType}/analyse`;
};
export const autoRtaForecast = (projectId: string, fluidType: string) => {
  return `/projects/${projectId}/modules/autorta/fluid/${fluidType}/forecast`;
};

export const calculateGazFmbPVT = "/modules/gaz/fmb/calculate_pvt";
export const calculateGazFmbInit = "/modules/gaz/fmb/calculate_fmb_init";
export const calculateGazFmbUser = "/modules/gaz/fmb/calculate_fmb_user";
export const calculateGazFmbParams = "/modules/gaz/fmb/calculate_fmb_params";
export const calculatePzInit = "/modules/gaz/pz/calculate_pz_init";

// projects settings
export const getAllWellsSettings = "/well_settings";
export const saveLegacyWellSettings = "/well_settings/legacy_modules";
export const saveProjectSetting = "/well_settings/project";

export const addWellToGroup = (projectId: string, groupId: string) => {
  return `/projects/${projectId}/groups/${groupId}/data_sets`;
};

// rollup
export const validateRollup = "/modules/rollup/validate";
export const calculateRollup = "/modules/rollup/calculate";

// data set for importing well
export const importWell = "/datasets";
export const importProject = "/datasets/structure";

// Tahk CSG
export const initializeTahkCsg = (projectId: string) => {
  return `/projects/${projectId}/modules/tahk-csg/initialize`;
};

// input
export const validateTahkCsgInput = (projectId: string) => {
  return `/projects/${projectId}/modules/tahk-csg/input/validate`;
};

// analysis
export const calculateTahkCsgAnalysis = (projectId: string) => {
  return `/projects/${projectId}/modules/tahk-csg/analysis/calculate`;
};
export const validateTahkCsgAnalysis = (projectId: string) => {
  return `/projects/${projectId}/modules/tahk-csg/analysis/validate`;
};

export const historyMatchTahkCsgAnalysis = (projectId: string) => {
  return `/projects/${projectId}/modules/tahk-csg/analysis/history_match`;
};

// forecast
export const validateTahkCsgForecast = (projectId: string) => {
  return `/projects/${projectId}/modules/tahk-csg/forecast/validate`;
};

export const calculateTahkCsgForecast = (projectId: string) => {
  return `/projects/${projectId}/modules/tahk-csg/forecast/calculate`;
};

export const exportTahkCsgForecast = (projectId: string) => {
  return `/projects/${projectId}/modules/tahk-csg/export`;
};

// spad decline new
export const spadDeclineAnalysisInitialize = (type: string, projectId: string) => {
  return `/projects/${projectId}/modules/spad-decline/fluid/${type}/initialise`;
};

export const spadDeclineAnalysisValidate = (type: string, projectId: string) => {
  return `/projects/${projectId}/modules/spad-decline/fluid/${type}/save`;
};

export const spadDeclineAnalysisCalculate = (type: string, projectId: string) => {
  return `/projects/${projectId}/modules/spad-decline/fluid/${type}/calculate`;
};

export const spadDeclineAnalysisExport = (type: string, projectId: string) => {
  return `/projects/${projectId}/modules/spad-decline/fluid/${type}/export`;
};

export const spadDeclineAnalysisChartDrag = (type: string, projectId: string) => {
  return `/projects/${projectId}/modules/spad-decline/fluid/${type}/chart`;
};

// move well api
export const moveWellBetweenGroup = (projectId: string, groupId: string) => {
  return `/projects/${projectId}/groups/${groupId}/move_wells`;
};

// move group api
export const moveGroup = (project_id: string, group_id: string) => {
  // old project and group id
  return `/projects/${project_id}/groups/${group_id}/move`;
};

// rullon

export const initializeRullon = (projectId: string) => {
  return `/projects/${projectId}/modules/rulon-group-run/initialise`;
};

export const validateRullon = (projectId: string) => {
  return `/projects/${projectId}/modules/rulon-group-run/validate`;
};

export const calculateRullon = (projectId: string) => {
  return `/projects/${projectId}/modules/rulon-group-run/calculate`;
};

export const initializeRulonRollup = (projectId: string, type: string) => {
  return `/projects/${projectId}/modules/rulon-rollup/fluid/${type}/initialise`;
};

export const validateRulonRollup = (projectId: string, type: string) => {
  return `/projects/${projectId}/modules/rulon-rollup/fluid/${type}/validate`;
};
export const calculateRulonRollup = (projectId: string, type: string) => {
  return `/projects/${projectId}/modules/rulon-rollup/fluid/${type}/calculate`;
};

export const exportRulonRollup = (projectId: string, type: string) => {
  return `/projects/${projectId}/modules/rulon-rollup/fluid/${type}/export`;
};

// Koldun CSG V2
export const initializeKoldunCsg = (projectId: string) => {
  return `/projects/${projectId}/modules/koldun-csg/initialise`;
};
export const validateKoldunCsg = (projectId: string) => {
  return `/projects/${projectId}/modules/koldun-csg/validate`;
};

export const pollForecastKoldunCsg = (projectId: string) => {
  return `/projects/${projectId}/modules/koldun-csg/calculate`;
};

export const exportKoldunCsgForecast = (projectId: string) => {
  return `/projects/${projectId}/modules/koldun-csg/export`;
};

// machine learning
export const pollMachineLearning = (projectId: string) => {
  return `/projects/${projectId}/modules/machine-learning/forecast`;
};

// group edit
export const postGroupEditInitializeApi = (projectId: string, moduleId: string) => {
  return `/projects/${projectId}/modules/${moduleId}/group-initialise`;
};

export const postGroupEditApi = (projectId: string, moduleId: string) => {
  return `/projects/${projectId}/modules/${moduleId}/group-update`;
};
