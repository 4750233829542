import { useCallback, useEffect, useRef, useState } from "react";
import { shallow } from "zustand/shallow";
import Button from "@mui/material/Button";

import { UserSettings, UserSettingSpadDeclineCurve } from "@/features/settings";
import { useAppStore } from "@/features/app";

import { DEFAULT_MAX_ARPS_EXPONENT, DEFAULT_MIN_ARPS_EXPONENT } from "./spad-preferences.configs";
import useThemeStyling from "@/utils/useThemeStyling";
import InputField from "@/components/fields/InputField";
import dictionary from "@/constants/dictionary";
import { fetchApi } from "@/utils/apiFetcher";
import { parseErrorThrown } from "@/utils/errorHandling";

type SpadPreferencesProps = {
  isTest?: boolean;
};
export const SpadPreferences = ({ isTest = false }: SpadPreferencesProps) => {
  const { setApiError, setIsLoading, apiError } = useAppStore(
    (state) => ({
      setIsLoading: state.setIsLoading,
      setApiError: state.setApiError,
      apiError: state.apiError,
    }),
    shallow
  );

  const [spadDeclineCurve, setSpadDeclineCurve] = useState<UserSettingSpadDeclineCurve>({
    min_arps_exponent: DEFAULT_MIN_ARPS_EXPONENT,
    max_arps_exponent: DEFAULT_MAX_ARPS_EXPONENT,
  });
  const [minErrorMessage, setMinErrorMessage] = useState<string | undefined>(undefined);
  const [maxErrorMessage, setMaxErrorMessage] = useState<string | undefined>(undefined);
  const { palette } = useThemeStyling();

  const initialize = useRef(false);

  useEffect(() => {
    if (spadDeclineCurve.min_arps_exponent >= spadDeclineCurve.max_arps_exponent) {
      setMinErrorMessage("Minimum value should be less than Maximum value");
      setMaxErrorMessage("Maximum value should be greater than Minimum value");
    } else {
      setMinErrorMessage("");
      setMaxErrorMessage("");
    }
  }, [spadDeclineCurve]);

  const handleMin = (numValue: number | undefined | string) => {
    if (numValue === undefined) {
      return;
    }
    setSpadDeclineCurve((prevState) => ({ ...prevState, min_arps_exponent: Number(numValue) }));
  };

  const handleMax = (numValue: number | undefined | string) => {
    if (numValue === undefined) {
      return;
    }
    setSpadDeclineCurve((prevState) => ({ ...prevState, max_arps_exponent: Number(numValue) }));
  };

  useEffect(() => {
    if (!initialize.current && !isTest) {
      fetchApi<UserSettings>({
        path: "/user_settings",
      })
        .then((response) => {
          if (response.data) {
            setSpadDeclineCurve(response.data.data.modules.spad.decline_curve);
            initialize.current = true;
          }
        })
        .catch((error) => {
          console.log(error);
          parseErrorThrown({
            error,
            setApiError,
            apiError,
          });
        });
    }
  }, [apiError, setApiError, isTest]);

  const saveData = useCallback(async () => {
    try {
      setIsLoading(true);

      const res = await fetchApi<any>({
        path: "/user_settings",
        type: "put",
        body: {
          modules: {
            spad: {
              decline_curve: {
                min_arps_exponent: spadDeclineCurve.min_arps_exponent,
                max_arps_exponent: spadDeclineCurve.max_arps_exponent,
              },
            },
          },
        },
      });
      if (res.data) setSpadDeclineCurve(res.data.modules.spad.decline_curve);
    } catch (error) {
      console.error(error);
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
    } finally {
      setIsLoading(false);
    }
  }, [apiError, setApiError, setIsLoading, spadDeclineCurve.max_arps_exponent, spadDeclineCurve.min_arps_exponent]);

  return (
    <>
      <InputField
        dataTestId="Minimum Arps Exponent"
        label="Minimum Arps Exponent"
        value={spadDeclineCurve.min_arps_exponent}
        min={0}
        max={1}
        onChange={handleMin}
        type="float"
      />
      <span role="alert" style={{ color: palette.customColor.red, fontSize: 12 }}>
        {minErrorMessage}
      </span>

      <InputField
        label="Maximum Arps Exponent"
        dataTestId="Maximum Arps Exponent"
        value={spadDeclineCurve.max_arps_exponent}
        min={0}
        max={1}
        onChange={handleMax}
        type="float"
      />
      <span role="alert" style={{ color: palette.customColor.red, fontSize: 12 }}>
        {maxErrorMessage}
      </span>

      <br />

      <Button
        style={{
          color: "white",
          width: 100,
          fontWeight: "bold",
          marginBottom: 15,
          marginTop: 20,
          marginRight: 20,
        }}
        variant="contained"
        onClick={saveData}
      >
        {dictionary.userPreference.save}
      </Button>
    </>
  );
};
