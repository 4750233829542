import { TahkCsgDataOption } from "./State";
import { TahkDataTableCurveScheme, TahkDataTableScheme } from "./generic";
import { TahkCsgInputState } from "./input";
import { z, number, object, boolean, string, ZodType, array } from "zod";

export type TahkCsgAnalysisCalculateRequestPayload = {
  options: TahkCsgInputState;
} & TahkCsgDataOption;

const TahkAnalysisChartDataScheme = object({
  dates: string().date().array(),
  gas_rate: number().array(),
  water_rate: number().array(),
  cumulative_gas_production: number().array(),
  cumulative_water_production: number().array(),
  // new  arr
  bottomhole_pressure: number().array().nullable(),
  casing_pressure: number().array().nullable().optional(),
  tubing_pressure: number().array().nullable().optional(),
});
export type TahkAnalysisChartData = z.infer<typeof TahkAnalysisChartDataScheme>;

const TahkCsgAnalysisParameterBaseScheme = <T extends ZodType>(type: T) =>
  z.object({
    initial_pressure: type.nullable(),
    langmuir_pressure: type.nullable(),

    porosity: type.nullable(),
    permeability_x_axis: type.nullable(),
    permeability_y_axis: type.nullable(),
    skin: type.nullable(),
    ng: type.nullable(),
    nw: type.nullable(),
    formation_compressibility: type.nullable(),
  });

const TahkAnalysisParameterAreaScheme = <T extends ZodType>(type: T) =>
  z
    .object({
      langmuir_volume: type.optional(),
      desorption_pressure: type.optional(),
      area: type.optional(),
    })
    .merge(TahkCsgAnalysisParameterBaseScheme(type));

export type TahkAnalysisParameterArea<T extends ZodType> = ReturnType<typeof TahkAnalysisParameterAreaScheme<T>>;

const TahkAnalysisParameterOgipScheme = <T extends ZodType>(type: T) =>
  z
    .object({
      undersaturated: type.optional(),
      ogip: type.optional(),
      initial_gas_content: type.optional(),
      shrinkage_factor: type.optional(),
    })
    .merge(TahkCsgAnalysisParameterBaseScheme(type));

export type TahkAnalysisParameterOgip<T extends ZodType> = ReturnType<typeof TahkAnalysisParameterAreaScheme<T>>;

export const TahkCsgAnalysisCalculateResponseScheme = object({
  tahk_analysis_curve_model: TahkAnalysisChartDataScheme,
  tahk_analysis_curve_data: TahkAnalysisChartDataScheme,
  table_of_final_parameters: array(
    TahkAnalysisParameterOgipScheme(number().nullable()).strict().or(TahkAnalysisParameterAreaScheme(number().nullable()).strict())
  ),
  data_table: TahkDataTableScheme.or(TahkDataTableCurveScheme).array(),
});

export type TahkCsgAnalysisCalculateResponse = z.infer<typeof TahkCsgAnalysisCalculateResponseScheme>;

// Validate
const layerItemScheme = object({
  selected_parameter: boolean(),
  lower_boundary: number(),
  upper_boundary: number(),
});

export type LayerItem = z.infer<typeof layerItemScheme>;

export type TahkCsgAnalysisValidationRequestPayload = {
  options: {
    input_options: TahkCsgInputState;
    analysis_options: TahkCsgAnalysisState;
  };
} & TahkCsgDataOption;

export const TahkCsgAnalysisStateScheme = object({
  gamma: number(),
  selected_default_optimization_options: boolean(),
  maximum_iteration_number: number(),
  population_size: number(),
  layers: array(TahkAnalysisParameterAreaScheme(layerItemScheme).or(TahkAnalysisParameterOgipScheme(layerItemScheme))),
});

export type TahkCsgAnalysisState = z.infer<typeof TahkCsgAnalysisStateScheme>;
