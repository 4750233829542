import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";

import { GroupEditPayload, postGroupEdit, postGroupEditInitialize } from "@/models/rulon/apiFetcher/groupEdit";
import { isDataSet, ModuleId } from "@/model";
import { GroupEditItem, groupEditItemScheme } from "@/models/rulon/groupEdit";
import { parseErrorThrown } from "@/utils/errorHandling";

import { GroupRunGenericProps } from "./type";
import dictionary from "@/constants/dictionary";

type GroupEditProps = {
  // although take all module id, currently only available for
  // spad_decline_gas
  // spad_decline_oil
  moduleId: ModuleId;
};

const useGroupEdit = ({
  isLoading,
  selectedDataSets,
  setApiError,
  setIsLoading,
  apiError,
  project,
  moduleId,
}: GroupRunGenericProps & GroupEditProps) => {
  const [groupEditState, setGroupEditState] = useState<GroupEditItem[]>();

  const lastPayload = useRef<GroupEditPayload>();

  const dataSets = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets.id];
    return selectedDataSets?.map((dataSet) => dataSet.id) ?? [];
  }, [selectedDataSets]);

  const currentPayload = useMemo(() => {
    if (!project?.id || dataSets.length === 0) return null;
    return { datasetIds: dataSets, moduleId, projectId: project?.id ?? "" };
  }, [dataSets, moduleId, project?.id]);

  useEffect(() => {
    if (!_.isEqual(lastPayload.current, currentPayload)) setGroupEditState(undefined);
  }, [currentPayload]);

  const { isLoading: isLoadingInitialize, isFetching } = useQuery({
    queryKey: ["initialize-group-edit", currentPayload, moduleId],
    queryFn: async () => {
      if (currentPayload) return postGroupEditInitialize(currentPayload);
    },
    select(data) {
      try {
        if (data?.data) {
          const parsed = groupEditItemScheme.array().parse(data.data);
          if (currentPayload && !_.isEqual(lastPayload.current, currentPayload)) {
            lastPayload.current = currentPayload;
            setGroupEditState(parsed);
          }
        }
      } catch (error: any) {
        console.log(error);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!currentPayload,
  });

  const onClickUpdate = useCallback(async () => {
    if (!groupEditState) return;
    try {
      setIsLoading(true);
      // this api doesn't send back anything
      await postGroupEdit({
        body: groupEditState,
        moduleId,
        projectId: project?.id ?? "",
      });
      setApiError({
        code: 0,
        severity: "success",
        message: dictionary.rulon.successGroupEdit,
      });
      setTimeout(() => {
        setApiError();
      }, 5000);
    } catch (error: any) {
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
    } finally {
      setIsLoading(false);
    }
  }, [apiError, groupEditState, moduleId, project?.id, setApiError, setIsLoading]);

  return {
    isLoading: isLoadingInitialize || isFetching || isLoading,
    groupEditState,
    setGroupEditState,
    onClickUpdate,
  };
};

export default useGroupEdit;
