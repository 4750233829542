import React, { useCallback, useState } from "react";
import DataSetRadioItem from "./DataSetRadioItem";
import { useTreeViewState } from "../hooks/TreeViewContextV2";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TagFilterMenu from "./TagFilterMenu";
import Tree from "rc-tree";
import { useTheme } from "@mui/material/styles";

const DataSetList = () => {
  const {
    commandControlHeld,
    dataSetItems,
    setFilter,
    filter,
    selectedDataSet,
    setSelectedDataSet,
    shiftHeld,
    setActiveDialog,
    onDropWell,
    hasModefiedDefaultSelection,
    onClickPreview,
  } = useTreeViewState();
  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openTagKeys, setOpenTagKeys] = useState<string[]>([]);

  const theme = useTheme();

  const handleFilterIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenTagKeys([]);
  };

  const onClickDataSetWellList = React.useCallback(
    (index: number, id: string, indexOnSelected: number, isChecked: boolean) => {
      // find index of the latest element
      const latestElIndex =
        selectedDataSet.length > 0
          ? dataSetItems.findIndex(
              (item) => item.name === selectedDataSet[selectedDataSet.length - 1] || item.data.id === selectedDataSet[selectedDataSet.length - 1]
            )
          : 0;
      const startIndex = index > latestElIndex ? latestElIndex : index;
      const elTotal = index > latestElIndex ? index - latestElIndex : latestElIndex - index;
      const newSelectedArray = dataSetItems.slice(startIndex, elTotal + startIndex + 1);

      let newSelectedDataSet = [...selectedDataSet];
      if (isChecked) {
        newSelectedDataSet.splice(indexOnSelected, 1);
      } else if (shiftHeld && newSelectedDataSet.length > 0 && selectedDataSet.length === 1) {
        newSelectedDataSet = newSelectedArray.map((items) => items.data.id);
      } else if (commandControlHeld) {
        newSelectedDataSet.push(id);
      } else {
        newSelectedDataSet = [id];
      }

      setSelectedDataSet(newSelectedDataSet);
    },
    [commandControlHeld, dataSetItems, selectedDataSet, setSelectedDataSet, shiftHeld]
  );

  const renderDatasetItem = useCallback(
    (item: any) => {
      const indexOnSelected = selectedDataSet.indexOf(item.id);
      const isChecked = indexOnSelected > -1;
      const isDataPreviewMode = window.location.href.includes("dataPreview");

      return (
        <DataSetRadioItem
          onClickPreview={onClickPreview}
          key={item.key + "-data-set-list"}
          onClick={() => {
            if (isDataPreviewMode) onClickPreview(item.id, true);
            onClickDataSetWellList(item.index, item.id, indexOnSelected, isChecked);
          }}
          dataSet={item.title}
          currDataSet={item.dataSet.data}
          setActiveDialog={setActiveDialog}
          checked={isChecked}
          className={"noselect"}
          disableMenu
        />
      );
    },
    [onClickDataSetWellList, selectedDataSet, setActiveDialog, onClickPreview]
  );

  return (
    <div className="well-container" style={{ marginTop: 10 }}>
      <TextField
        data-testid="well-filter-input"
        value={filter}
        onChange={(e) => setFilter(e.target.value ?? "")}
        style={{ width: "92%" }}
        size="small"
        label="Search..."
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleFilterIconClick} data-testid="well-filter-icon">
                {hasModefiedDefaultSelection ? (
                  <FilterAltIcon style={{ width: 25, height: 25, color: theme.palette.primary.main }} />
                ) : (
                  <FilterAltOutlinedIcon style={{ width: 25, height: 25 }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {/* TagFilterMenu */}
      <TagFilterMenu anchorEl={anchorEl} openTagKeys={openTagKeys} setOpenTagKeys={setOpenTagKeys} handleMenuClose={handleMenuClose} />
      <Tree
        onDragEnd={() => {
          onDropWell();
        }}
        onDragStart={(info) => {
          const item: any = info.node;
          const indexOnSelected = selectedDataSet.indexOf(item.id);
          const isChecked = indexOnSelected > -1;
          if (selectedDataSet.length === 0) onClickDataSetWellList(item.index, item.id, indexOnSelected, isChecked);
        }}
        draggable
        virtual
        height={height - 220}
        itemHeight={40}
        className="well-list noScrollbar"
        // @ts-ignore
        treeData={dataSetItems?.map((well, index) => {
          return {
            id: well.key,
            key: well.key,
            title: well.name,
            dataSet: well,
            source: "well-list",
            index,
          };
        })}
        titleRender={(item) => renderDatasetItem(item)}
      />
    </div>
  );
};

export default DataSetList;
