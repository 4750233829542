import { DataSetState } from "./models/wells/State";

export enum ModuleId {
  GAZ_FMB = "gaz_fmb",
  GAZ_PZ = "gaz_pz",
  GAZ_AUTORTA = "gaz_autorta",
  SPAD_DECLINE_OIL = "spad_decline_oil",
  SPAD_DECLINE_GAS = "spad_decline_gas",
  SPAD_WOR = "spad_wor",
  TAHK_FORECAST_GAS = "tahk_forecast_gas",
  TAHK_FORECAST_OIL = "tahk_forecast_oil",
  TAHK_FORECAST_CSG = "tahk_forecast_csg",
  KOLDUN_MCSIM_GAS = "koldun_mcsim_gas",
  KOLDUN_MCSIM_OIL = "koldun_mcsim_oil",
  KOLDUN_MCSIM_CSG = "koldun_mcsim_csg",
  GILDRO_PVT = "gildro_pvt",
  GILDRO_HYDRATE = "gildro_hydrate",

  MASLO_AUTORTA = "maslo_autorta",
  ROLLUP = "rollup",
  RULON = "rulon_group_run",

  // these 2 below are not fixed yet
  RULON_OIL = "rulon_rollup_oil",
  RULON_GAS = "rulon_rollup_gas",

  // there's no module id for machine learning yet, this is FE placeholder only
  MACHINE_LEARNING = "ml_forecast",

  // just extra id as fallback so it wont error when be throw in new module id
  extra = "extra",
}

export type buildNumber = {
  buildNumber?: string;
};
export type Group = {
  id: string;
  name: string;
  groups: Group[];
  isSelected?: boolean;
  selectedDataSets?: string[];
  data_set_ids?: string[];
};

export type NewGroups = {
  parent_id?: string;
  names: string[];
};

export type Project = {
  id: string;
  name: string;
  groups: Group[];
  isSelected?: boolean;
};

export type NewProject = {
  name: string;
  copy_from?: string;
};

export type EditProject = {
  name: string;
};

export enum DataSetType {
  OIL_WELL = "oil",
  GAS_WELL = "gas",
}

export type DataSet = DataSetState;

export type AddDataSets = {
  project_id: string;
  group_id: string;
  data_set_ids: string[];
};

export type DataResponse = {
  fields: Field[];
  data: any[][];
};

export type DataLoadOptions = {
  atmospheric_correction: number;
  sampling_interval: string;
  start_date?: Date;
  end_date?: Date;
};

export type DataCleanOptions = {
  smoothing_type: string;
  interpolation_order: number;
  moving_avg_window: number;
};

export type AnnouncementResponse = {
  id: string;
  content: string;
  start_time: Date;
  end_time: Date;
  maintenance_mode: boolean;
};

export enum FormationCompressibility {
  NEWMAN = "N",
  HALL = "H",
}

export enum RockType {
  SANDSTONE = "sandstone",
  LIMESTONE = "limestone",
}

export enum WaterCompressibility {
  BEGGS_BRILL = "BB",
  MCCAIN = "MCCAIN",
  LOUISIANA_STATE_UNI = "LOUISIANA_STATE_UNI",
}

export enum ZMethod {
  DPR = "DPR",
  BEGGS_BRILL = "BB",
  PAPAY = "P",
  DRANCHUCK_ABU_KASSEM = "DAK",
}

// export enum TankType {
//   VERT_GAS = "VerticalWell_Gas",
//   VERT_OIL = "VerticalWell_Oil",
//   VERT_WATER = "VerticalWell_Water",
// }

export enum GeomechanicsType {
  Geo_None = "none",
  Geo_Burgoyne = "burgoyne",
  Geo_Yilmaz = "yilmaz-nur",
  Geo_Palmer = "palmer-mansoori",
}

export enum LayeringType {
  Single = "Single Layer",
  Multi = "Multi Layer",
}

export enum FlowPressureType {
  BH = "BH",
  TH = "TH",
}

export enum ParamInputType {
  OG = "ogip",
  VL = "volumetric",
}

export enum TankType {
  Horizontal = "Horizontal",
  Vertical = "Vertical",
}
export enum SkinCorrelationType {
  Constant = "const",
  Santos = "table",
}

export enum OilDensityMethod {
  EOS = "EOS",
}

export enum SolGasMethod {
  VASQUEZ_BEGGS = "VB",
  STANDING = "S",
  GLASO = "G",
  LASATER = "L",
  PETROSKY = "P",
}

export enum WaterViscosityMethod {
  MCCAIN = "M",
  LOUISIANA_STATE_UNI = "LSU",
  BRILL_BEGGS = "BB",
}

export enum GasViscosityMethod {
  GONZALES_EAKIN = "GE",
  LONDONO = "L",
  STANDING = "S",
}

// Includes DataSetFields and CalculatedFields from back-end
export enum Field {
  // date time
  DATE_TIME = "dt",

  // nearest integer
  DAYS = "days",

  // 1 decimal place
  OIL_CUM = "Qo",
  GAS_CUM = "Qg",
  WATER_CUM = "Qw",
  BOTTOMHOLE_PRESSURE = "bhp",
  TOTAL_LIQUID = "total_liquid",
  WATER_CUT = "water_cut",
  OIL_CUT = "oil_cut",
  TEMPERATURE = "temp",

  // 4 decimal place
  WATER_RATE = "qw",
  GAS_RATE = "qg",
  OIL_RATE = "qo",
  CASING = "csg",
  TUBING = "tbg",
}

const fourDecimalPlace = [Field.WATER_RATE, Field.GAS_RATE, Field.OIL_RATE, Field.CASING, Field.TUBING];

export const getFormattedNumberByField = (val: number | string, field: Field) => {
  if (!val) return "";

  if (field === Field.DATE_TIME) return new Date(val);
  if (field === Field.DAYS) return String(Math.round(Number(val)));
  const fourDecimal = fourDecimalPlace.indexOf(field);

  return String(parseFloat(Number(val).toFixed(fourDecimal > -1 ? 4 : 1)));
};

export function getFieldName(f: Field) {
  switch (f) {
    case Field.DATE_TIME:
      return "Date";
    case Field.GAS_RATE:
      return "Gas rate";
    case Field.WATER_RATE:
      return "Water rate";
    case Field.OIL_RATE:
      return "Oil rate";
    case Field.CASING:
      return "Casing pressure";
    case Field.TUBING:
      return "Tubing pressure";
    case Field.DAYS:
      return "Day";
    case Field.GAS_CUM:
      return "Gas prod.";
    case Field.WATER_CUM:
      return "Water prod.";
    case Field.OIL_CUM:
      return "Oil prod.";
    case Field.TOTAL_LIQUID:
      return "Total liquid";
    case Field.WATER_CUT:
      return "Water cut";
    case Field.OIL_CUT:
      return "Oil cut";
  }
}

export enum Unit {
  PRESSURE = "psia",
  TEMPERATURE = "°F",
  GAS_RATE = "MMscf/d",
  FLUID_RATE = "bbl/d",
  GAS_CUM = "MMscf",
  FLUID_CUM = "bbl",
}

export function getFieldUnit(f: Field): Unit | undefined {
  switch (f) {
    case Field.DATE_TIME:
    case Field.DAYS:
      return;
    case Field.GAS_RATE:
      return Unit.GAS_RATE;
    case Field.WATER_RATE:
    case Field.OIL_RATE:
    case Field.TOTAL_LIQUID:
    case Field.WATER_CUT:
    case Field.OIL_CUT:
      return Unit.FLUID_RATE;
    case Field.CASING:
    case Field.TUBING:
      return Unit.PRESSURE;
    case Field.GAS_CUM:
      return Unit.GAS_CUM;
    case Field.WATER_CUM:
    case Field.OIL_CUM:
      return Unit.FLUID_CUM;
  }
}

export function isDataSet(dataSet: any): dataSet is DataSet {
  return dataSet && (dataSet as DataSet).id !== undefined;
}

export function isDataSets(dataSets: any): dataSets is DataSet[] {
  return dataSets && Array.isArray(dataSets) && dataSets.every((ds: any) => isDataSet(ds));
}

export function getDataSetIdsInProject(project: Project): string[] {
  const getDataSetIdsInGroup = (group: Group): string[] => {
    if (group.groups) {
      return group.groups.flatMap((g) => getDataSetIdsInGroup(g));
    } else {
      if (group.data_set_ids) {
        return group.data_set_ids;
      } else {
        return [];
      }
    }
  };
  return project.groups.flatMap((g) => getDataSetIdsInGroup(g));
}

export type PollRequestMethod = "get" | "post";
