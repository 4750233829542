import { useMemo } from "react";

import useThemeStyling from "@/utils/useThemeStyling";
import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { AutoRtaInputGrid, InputGridCalculation } from "@/models/gaz/autoRta";

type useAutoRtaLangmuirIsothermChartsProps = {
  value: AutoRtaInputGrid;
  calculateValue: InputGridCalculation;
};
const useAutoRtaLangmuirIsothermCharts = ({ value, calculateValue }: useAutoRtaLangmuirIsothermChartsProps) => {
  const { palette } = useThemeStyling();

  // Gas Content
  const langmuirIsothermGasContentXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: palette.customColor.black,
      },
    ],
    [palette]
  );

  const langmuirIsothermGasContentYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "gas content (scf/ton)",
        type: "value",
        color: palette.customColor.black,
        show: true,
      },
    ],
    [palette]
  );

  const langmuirIsothermGasContentSeries = useMemo<FossilyticsChartSeries[]>(() => {
    if (calculateValue) {
      const gasContent = calculateValue.langmuir_charts.desorption_gas_content;
      const desorptionPressure = value?.langmuir_isotherm?.desorption_pressure ?? 0;
      return [
        {
          name: `langmuirIsotherm gc`,
          type: "line",
          hideSymbol: true,
          color: palette.customColor.orangeLighter,
          lineWidth: 2,
          z: 2,
          data: calculateValue.langmuir_charts.pressure.map((p, j) => [p, calculateValue.langmuir_charts.gas_content[j]]),
        },
        {
          name: `langmuirIsotherm desorption gc`,
          type: "line",
          hideSymbol: true,
          color: palette.customColor.black,
          lineWidth: 2,
          lineType: "dashed",
          z: 2,
          data: [
            [0, gasContent],
            [desorptionPressure, gasContent],
            [desorptionPressure, 0],
          ],
        },
        {
          name: `langmuirIsotherm desorption gc dot`,
          type: "scatter",
          color: palette.customColor.black,
          z: 2,
          symbolSize: 15,
          data: [[desorptionPressure, gasContent]],
        },
      ];
    }
    return [];
  }, [calculateValue, palette.customColor.black, palette.customColor.orangeLighter, value?.langmuir_isotherm?.desorption_pressure]);

  //cd
  const langmuirIsothermcdXAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "Pressure (psia)",
        type: "value",
        color: palette.customColor.black,
      },
    ],
    [palette]
  );

  const langmuirIsothermcdYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "gas content (scf/ton)",
        type: "value",
        color: palette.customColor.black,
        show: true,
      },
    ],
    [palette]
  );

  const langmuirIsothermcdSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      calculateValue
        ? [
            {
              name: `langmuirIsotherm cd`,
              type: "line",
              hideSymbol: true,
              color: palette.customColor.orangeLighter,
              lineWidth: 2,
              z: 2,
              data: calculateValue.langmuir_charts.pressure.map((p, j) => [p, calculateValue.langmuir_charts.desorption_compressibility[j]]),
            },
          ]
        : [],
    [calculateValue, palette]
  );

  return {
    langmuirIsothermGasContent: {
      xAxes: langmuirIsothermGasContentXAxes,
      yAxes: langmuirIsothermGasContentYAxes,
      series: langmuirIsothermGasContentSeries,
    },
    langmuirIsothermcd: {
      xAxes: langmuirIsothermcdXAxes,
      yAxes: langmuirIsothermcdYAxes,
      series: langmuirIsothermcdSeries,
    },
  };
};
export default useAutoRtaLangmuirIsothermCharts;
